import proptypes from "proptypes";
import React, { useState } from "react"
import { apiLogin, apiPasswordRecoverMail, apiPasswordReset } from "../../api";
import logo from '../../images/lanner_logo_2.png'
import './style.scss'
import { CheckOwner } from "../../components/CheckOwner";
import { apiGetUserMe } from "../../api"

let loginInfo = {
    user_account: "",
    user_password: "",
    user_confirm_password: undefined
}

const LoginPage = ({setToken}, {getMe}) => {
    LoginPage.prototype = {
        setToken: proptypes.string.isRequired
    }
    const [ forgotPassword, setForgotPassword ] = useState(false)
    const [ loginMsg, setLoginMsg ] = useState('')

    const query = new URLSearchParams(window.location.search);
    const resetPasswordToken = query.get('token')
    // console.log(window.location.href);
    window.history.pushState({}, null, '/')

    const handleLoginInputOnChange = e => {
        loginInfo = {
            ...loginInfo,
            [e.target.name]: e.target.value
        }
    }

    const initForgotPassword = () => {
        loginInfo.user_account = undefined
        setLoginMsg('')
        setForgotPassword(!forgotPassword)
    }

    const handleSendRecoverMail = () => {
        apiPasswordRecoverMail(
            loginInfo.user_account,
        {})
        .then(res => {
            setLoginMsg('Recovery e-mail is sending.')
        })
        .catch(err => {
            setLoginMsg(<span style={{'color':'red'}}>This e-mail does not exist</span>)
        })
    }

    const handleLoginOnClick = () => {
        if (loginInfo.user_account === "" || loginInfo.user_password === "") {
            setLoginMsg(<span style={{'color':'red'}}>E-mail or password cannot be empty</span>)
            return
        }
        setLoginMsg(<div className="loader_login"></div> )
        const loginTimer = setTimeout(() => {
            document.getElementById("user_password").value = ""
            loginInfo.user_password = ""
            setLoginMsg(<span style={{'color':'red'}}>Connection timeout</span>);
        }, 25000)
        
        apiLogin({
            username: loginInfo.user_account,
            password: loginInfo.user_password
        },{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        .then(res => {
            setLoginMsg('')
            
            setToken(res.data.access_token, res.data.refresh_token)
            apiGetUserMe({
                headers: {
                    'Authorization': `bearer ${res.data.access_token}`
                }
            }
            ).then(res => {
                if (res.data.is_superuser) {
                    localStorage.setItem('me', JSON.stringify(res.data))
                    localStorage.setItem('super', true);
                    window.location.assign("/admin/dashboard")
                } else {
                    localStorage.setItem('me', JSON.stringify(res.data))
                    localStorage.setItem('super', false);
                    window.location.assign("/dashboard")
                }
            })
            // window.location.assign("/")
            clearTimeout(loginTimer)
        })
        .catch(err => {
            if (err.response.status === 403) {
                setLoginMsg(<span style={{'color':'red'}}>Inactive user</span>);
            } else {
                setLoginMsg(<span style={{'color':'red'}}>Incorrect e-mail or password</span>);
            }
            clearTimeout(loginTimer)
        })
    }

    const handlePasswordReset = () => {
        if ( loginInfo.user_password !== loginInfo.user_confirm_password ) {
            setLoginMsg(<span style={{'color':'red'}}>Passwords do not match.</span>)
        } else {
            setLoginMsg(<div className="loader_login"></div> )
            apiPasswordReset({
                token: resetPasswordToken,
                new_password: loginInfo.user_password
            })
            .then(res => {
                setLoginMsg(<span>Reset Password Successfully! Click <a href='/'>here</a> to login.</span>)
            })
            .catch(err => {
                setLoginMsg(<span style={{'color':'red'}}>Passwords must have at least 6-20 characters</span>);
            })
        }
    }

    return (
        <div className='login__page'>
            <div className="login__service__name">
                <span>Lanner DMS OOB</span>
            </div>
            <div className="login__copyright">
                <span>Copyright © 2023 Lanner. All Rights Reserved.</span>
            </div>
            <svg className="login__logo" data-name="lanner-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 720">
            <linearGradient id="logo_grad" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" style={{stopColor:'rgb(72, 110, 74)', stopOpacity:'1'}} />
                <stop offset="100%" style={{stopColor:'rgb(255, 255, 255)', stopOpacity:'1'}} />
            </linearGradient>
                <path fill="url(#logo_grad)" d="M418.26,465.87c-1.62.08-2.94.19-4.26.19-43.47,0-86.95.08-130.42,0-15.13,0-29.14-4.06-40.43-14.53-13.55-12.57-18.55-28.27-14.09-46.3,4.51-18.21,17-29.05,34.62-33.83a83.86,83.86,0,0,1,20.72-2.54c25.82-.28,51.64-.11,77.46-.11h6.22c0-7.05.61-13.72-.31-20.18-.35-2.47-3.84-5.26-6.54-6.41-3.06-1.31-6.84-1.23-10.3-1.24-24-.09-48-.22-72,.05-7.9.08-13.35-2.78-17.06-9.71s-7.74-13.51-12.41-21.6c7.13,0,13,0,18.87,0,36.64.11,73.28.17,109.92.45a58.11,58.11,0,0,1,13.78,2c16.86,4.27,26.33,15.09,26.52,32.43.45,39.8.15,79.61.13,119.42A10.37,10.37,0,0,1,418.26,465.87Zm-50.42-31V400.68a16.6,16.6,0,0,0-2.62-.48c-22.83-.07-45.66-.4-68.47,0a37.59,37.59,0,0,0-16.61,4.46c-10.62,5.78-10.81,18.38-.95,25.39,5.51,3.92,11.86,5,18.49,5q32.49,0,65,0C364.28,435.1,365.9,435,367.84,434.84Z"/>
                <path fill="url(#logo_grad)" d="M508.14,466.06H454.47V310.52a10.56,10.56,0,0,1,2.56-.61c43.47.06,86.94-.06,130.41.38a68.24,68.24,0,0,1,34.78,9.62c11.74,7,18.47,17.5,18.58,31.17.31,37.48.11,75,.11,112.44,0,.65-.13,1.29-.24,2.36H587.08v-5.65c0-31.65-.39-63.31.16-95,.28-15.7-10.15-23.85-21.63-25.35-7.38-1-14.93-.8-22.42-.88-11.47-.13-23,0-35.05,0Z"/>
                <path fill="url(#logo_grad)" d="M732.08,466H678.55V310.42a10.13,10.13,0,0,1,2.26-.5c42.16,0,84.32-.17,126.48.13a72.81,72.81,0,0,1,36.76,10c13.87,8.14,21.26,19.88,21.12,36.45-.28,35-.08,70-.09,105,0,1.31-.13,2.63-.22,4.4H811.17v-5.6q0-46.5,0-93c0-17.79-8.48-27.29-26.49-28-16.3-.68-32.66-.12-49-.28-4,0-3.59,2.45-3.59,5q0,28.5,0,57Z"/>
                <path fill="url(#logo_grad)" d="M975.08,403.93l53.4-65.38a36.51,36.51,0,0,0-4.5-.46c-15.29.58-30.83-.34-45.79,2.2-19.59,3.32-33,15.56-37.38,35.65-3.91,17.86,4.53,36.07,20.93,46.73,13.31,8.65,28.17,11.74,43.77,11.8,23.67.08,47.34,0,71,0,3,0,5.91.26,9.07.42v30.65a31,31,0,0,1-3.84.48c-30.83-.09-61.67.34-92.49-.49-24.69-.67-48.26-6.58-68.46-21.75-19.88-14.92-31.92-34.54-31.59-59.95.35-26.38,13.72-45.74,35.46-59.58,16-10.18,33.9-14.85,52.67-15.26,27.15-.59,54.32-.59,81.47-.45a54.59,54.59,0,0,1,16.62,2.91c8.33,2.75,12.07,9.33,9.52,17.71a59.48,59.48,0,0,1-9,18.13c-12.24,16.23-25.22,31.88-37.81,47.84-4.89,6.21-10.82,9.16-19,8.9C1004.89,403.6,990.59,403.93,975.08,403.93Z"/>
                <path fill="url(#logo_grad)" d="M211.94,430.37v35.51a13.11,13.11,0,0,1-2.82.61c-30.65-.21-61.32-.05-91.95-.95-9-.27-18.14-2.71-26.71-5.65C75.09,454.6,66.54,443,64.14,427a78.44,78.44,0,0,1-.91-11.45q-.09-77.24,0-154.48c0-1.45.18-2.91.29-4.66h54.61v6q0,68.25,0,136.48c0,23.75,7.72,31.46,31.41,31.46h62.43Z"/>
                <path fill="url(#logo_grad)" d="M1237.42,309.61c-5.76,9.16-10.5,17.16-15.73,24.82-2.72,4-7.19,4.95-12,4.91-7.49-.06-15-.3-22.46.46-13.39,1.38-21.33,10.8-21.33,24.21q0,48,0,96v5.73h-54.31v-6.66c0-33.16.56-66.34-.17-99.49-.58-26.1,16.43-41.1,34.91-46.38a71.59,71.59,0,0,1,17.65-3.08c20.32-.49,40.65-.39,61-.5C1228.65,309.59,1232.3,309.61,1237.42,309.61Z"/>
            </svg>
            <svg className="login__waves" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g className="login__parallax">
                    <use href="#gentle-wave" x="48" y="0" fill="rgba(72, 110, 74,0.7)"/>
                    <use href="#gentle-wave" x="48" y="3" fill="rgba(72, 110, 74,0.5)"/>
                    <use href="#gentle-wave" x="48" y="5" fill="rgba(72, 110, 74,0.3)"/>
                    <use href="#gentle-wave" x="48" y="7" fill="rgba(72, 110, 74,0.9)"/>
                </g>
            </svg>
            {resetPasswordToken 
            ? 
            <form className='login__box'>
                <h1 style={{'fontSize':'2.2vw'}}>Reset Passowrd</h1>
                <div className='login__item'>
                    <svg className='login__icon' viewBox="0 2 24 24">
                        <path fill="currentColor" d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
                    </svg>
                    <input 
                    name="user_password"
                    className='login__input'
                    type='password'
                    maxLength='20'
                    placeholder='New Password'
                    onChange={handleLoginInputOnChange}/>
                </div>
                <div className='login__item'>
                    <svg className='login__icon' viewBox="0 2 24 24">
                        <path fill="currentColor" d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
                    </svg>
                    <input 
                    name="user_confirm_password"
                    className='login__input'
                    type='password'
                    maxLength='20'
                    placeholder='Confirm New Password'
                    onChange={handleLoginInputOnChange}/>
                </div>
                    <div className='login__message'>
                        <span>{loginMsg ? loginMsg : null}</span>
                    </div>
                <div className='login__reset__btn' onClick={() => handlePasswordReset()}>
                    <span>RESET</span>
                </div>
            </form>
            : 
                forgotPassword
                ?
                <div className='login__forgot__box'>
                    <h2>Forgot Password</h2>
                    <div className='login__item'>
                        <svg className='login__icon' viewBox="0 0 24 24">
                            <path fill="currentColor" d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" />
                        </svg>
                        <input
                        name="user_account"
                        className="login__input"
                        type="email"
                        placeholder="E-mail" 
                        onChange={handleLoginInputOnChange}/>
                    </div>
                    <div className="login__message" >
                        <span>{loginMsg ? loginMsg : null}</span>
                    </div>
                    <div className="login__btn__group">
                        <div className="login__cancel__btn" onClick={() => initForgotPassword()}>
                            <span>Cancel</span>
                        </div>
                        <div className="login__send__btn" onClick={() => handleSendRecoverMail()}>
                            <span>Send</span>
                        </div>
                        
                    </div>
                </div>
                :
                <form className="login__box" onSubmit={e => {e.preventDefault(); handleLoginOnClick()}}>
                    <h1>WELCOME</h1>
                    <div className="login__item">
                        <svg className="login__icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" />
                        </svg>
                        <input
                            className="login__input"
                            name="user_account"
                            type="email"
                            id="email"
                            placeholder="E-mail"
                            onChange={handleLoginInputOnChange}
                        />
                    </div>
                    <div className="login__item">
                        <svg className="login__icon" viewBox="0 2 24 24">
                            <path fill="currentColor" d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
                        </svg>
                        <input 
                            className="login__input"
                            id="user_password"
                            name="user_password"
                            type="password"
                            maxLength="20"
                            placeholder="Password"
                            autoComplete="no"
                            onChange={handleLoginInputOnChange}
                        />
                    </div>
                    <div className="login__forgot__btn" >
                        <div onClick={() => initForgotPassword()}>
                            <span>Forgot Password</span>
                        </div>
                    </div>
                    <div className="login__message">
                        <span>{loginMsg ? loginMsg : null}</span>
                    </div>
                    <button type="submit" className="login__login__btn" >LOG IN</button>
                </form>
            }
        </div>
    )
}
export default LoginPage
