import { useEffect, useState, useRef, createRef } from "react";
import { 
  apiAdminGetUsers,
  apiGetUsers,
  apiAdminModifyUser, 
  apiAdminCreateUser, 
  apiAdminDeleteUser, 
  apiAdminGetCompanies,
  apiAdminSetUserPermission,
  apiAdminGetUserPermission,
  apiAdminEnableUser,
  apiAdminDisableUser,
  apiAdminChangeUserPassword,
  apiAdminGetUserSessions,
  apiAdminClearUserSession } from "../../../api";
import { SearchBar } from "../../../components/SearchBar";
import InfiniteScroll from "react-infinite-scroll-component";
import { 
  CalendarIcon,
  DeleteButton,
  ExportHtmlButton,
  ExportCsvButton,
  ModifyButton, 
  NoButton, 
  PasswordButton, 
  PermissionButton, 
  SessionButton,
  UserActiveButton,
  UserLogButton,
  YesButton } from '../../../components/ActionButton';
import { Link } from "react-router-dom";
import { PageLoader } from "../../../components/Loader";
import styles from './AdminUser.module.scss';
import { UserLogsPage } from "../../../components/UserLogsPage";
// import { UserDetailPage } from "../../../components/UserDetailPage";
import { Form, Switch, Modal, notification } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
const { confirm } = Modal;

let activeUser = undefined,
    hasMoreScroll = true,
    companyId = undefined,
    setPermit = [],
    delayTime = 0

const UserPage = ({me}) => {

    const accessToken = JSON.parse(localStorage.getItem('access_token'))
    const [ usersData, setUsersData ] = useState([])
    const [ isOnClick, setIsOnClick ] = useState({click: "", index: ""})
    const [ companiesData, setCompaniesData ] = useState()
    const [ actionMsg, setActionMsg ] = useState("")
    const [ num, setNum ] = useState(10)
    const [ total, setTotal ] = useState()
    const [ newUserData, setNewUserData ] = useState({
        full_name: "",
        description: "",
        email: "",
        password: "",
        phone_number: null,
        send_welcome_email: false,
        company_id: ""
    }) 
    const [ searchWords, setSearchWords ] = useState()

    const [openChangeUserPWD, setOpenChangeUserPWD] = useState(false);
    const [targetUserID, setTargetUserID] = useState();

    const switch_ref = useRef([]);
    switch_ref.current = usersData.map((u, i) => switch_ref.current[i] ?? createRef());
    
    const showNotification = (type, title, content) => {
      notification.open({
          type: `${type}`,
          message: `${title}`,
          description: `${content}`,
          className: 'bmc__notification'
      });
    };

    const getUsers = async (keywords) => {
      await apiAdminGetUsers({'Authorization': `Bearer ${accessToken}`}, 0, 10, keywords)
      .then(res => {
        setUsersData(res.data.data)
        setTotal(res.data.metadata.total)
        if (10 >= total) {
          hasMoreScroll = false
        } else {
          hasMoreScroll = true
        }
      })
      .catch(err => {
        // console.log('Get users data failed.');
      })
    }

    const normalUSerGetUsers = async(keywords) => {
      await apiGetUsers({'Authorization': `Bearer ${accessToken}`}, 0, 10, keywords)
      .then(res => {
        setUsersData(res.data.data)
        setTotal(res.data.metadata.total)
        if (10 >= total) {
          hasMoreScroll = false
        } else {
          hasMoreScroll = true
        }
      })
      .catch(err => {
        // console.log('Get users data failed.');
      })
    }

    useEffect(() => {
      if(me && me.is_superuser) {
        getUsers();
      } else {
        normalUSerGetUsers();
      };
      
      return () => {
        activeUser = undefined
        hasMoreScroll = true
        companyId = undefined
        setPermit = []
        delayTime = 0
      }
      
    },[accessToken, total])

    useEffect(() => {

      if(me && me.is_superuser) apiAdminGetCompanies({'Authorization': `Bearer ${accessToken}`}, 0, 1000)
      .then(res => {
        setCompaniesData(res.data.data);
      })
    },[accessToken])

    const nextScrollData = () => {
      setNum(num + 10)
      if (num >= total) {
        hasMoreScroll = false
        return
      } else {
        hasMoreScroll = true
      }
      const scrollGetMoreUsers = async () => {
        apiAdminGetUsers({
              'Authorization': `Bearer ${accessToken}`
          },
          num,
          10,
          searchWords
        )
        .then(res => {
          setUsersData(usersData.concat(res.data.data))
          if (num >= res.data.metadata.total) {
            hasMoreScroll = false
          } else {
            hasMoreScroll = true
          }
        })
        .catch(err => {
          // console.log('Get users data failed.');
        })
      }
      scrollGetMoreUsers()
    }

    const [ userSessions, setUserSessions ] = useState()
    const getUserSessions = async (user_id) => {
      setActionMsg(<div className={styles.action_note}>Clear Sessions</div>)
      setUserSessions(null)
      await apiAdminGetUserSessions({
        headers:{
          'Authorization': `Bearer ${accessToken}`
        }},
        user_id
      ).then(res => {
        setUserSessions(res.data.data)
      }).catch(err => {
        setActionMsg(<div className={styles.action_note_err} >{err.response.data.detail}</div>)
        setUserSessions([])
      })
    } 

    const clearUserSession = async (user_id, index) => {
      await apiAdminClearUserSession({
        headers:{
          'Authorization': `Bearer ${accessToken}`
        }},
        user_id
      ).then(res => {
        setIsOnClick({click: "", index: ""})
      }).catch(err => {
        setActionMsg(<div className={styles.action_note_err} >{err.response.data.detail}</div>)
      })
    }

    const initAddUser = () => {
      setActionMsg("")
      setPermit = []
      setNewUserData({
        full_name: "",
        description: "",
        phone_number: null,
        company_id: "",
        send_welcome_email: false,
        password: "",
        company_id: ""
      })
      setIsOnClick({click: "add", index: ""})
    }

    const initModifyUser = (data, index) => {
      setActionMsg(<div className={styles.action_note}>Modify</div>)
      setNewUserData({
        full_name: data.full_name,
        description: "",
        email: data.email,
        phone_number: data.phone_number === "" ? null : data.phone_number,
        company_id: data.company_id,
      })
      setIsOnClick({click: "edit", index: index})
    }

    const handleEditOnChange = (e) => {
      const value = e.target.name === 'phone_number' && e.target.value === "" ? null : e.target.value
      setNewUserData({
        ...newUserData,
        [e.target.name]: value
      })
    }    
    
    const deleteUser = (user_id, index) => {
      const DeleteUser = async () => {
        await apiAdminDeleteUser(
          {
            headers:{
              'Authorization': `Bearer ${accessToken}`
            }
          },
          user_id
        )
        .then(res => {
          usersData.splice(index, 1)
          setIsOnClick({click: "", index: ""})
          showNotification('success', 'Success!', 'Selected user has been deleted.')
        })
        .catch(err => {
          showNotification('error', 'Error!', `${err.response.data.detail}`)
          // console.log('Delete user failed.');
        })
      }
      DeleteUser()
    }
    
    const addUser = async () => {
      await apiAdminCreateUser({
        headers:{
          'Authorization': `Bearer ${accessToken}`
        }},
        newUserData
      )
      .then(res => {
        setUsersData(usersData.concat(res.data))
        setIsOnClick({click: "", index: ""})
        if (setPermit.length !== 0) {
          setUserPermissions(res.data.id, setPermit)
        }
        showNotification('success', 'Success!', 'Successfully add a new user.')
      })
      .catch(err => {
        // console.log('Add user failed, check data is valid or existing.')
        // setActionMsg("Add user failed, check data is valid or existing")
        showNotification('error', 'Error!', `${err.response.data.detail}`)
      })
    }


    const setUserPermissions = async (id, data) => {
      await apiAdminSetUserPermission({
        headers:{
          'Authorization': `Bearer ${accessToken}`
        }},
        id,
        data
        ).then(res => {
          setIsOnClick({click: "", index: ""})
        }).catch(err => {
          setActionMsg(<div className={styles.action_note_err} >{err.response.data.detail}</div>)
        })
    }

    const submitEditUserData = (user_id) => {
      const UpdateUser = async () => {
        await apiAdminModifyUser({
          headers:{
            'Authorization': `Bearer ${accessToken}`
          }},
          newUserData,
          user_id
        )
        .then(res => {
          usersData[isOnClick.index] = {...usersData[isOnClick.index],
            full_name: res.data.full_name,
            email: res.data.email,
            company_id: res.data.company_id,
            company_name: res.data.company_name,
            is_enabled: res.data.is_enabled
          }
          
          setIsOnClick({click: "", index: ""})
          showNotification('success', 'Success!', 'Edition has been successfully done.')
        })
        .catch(err => {
          // console.log('Edit user failed, check data is valid or existing.')
          // setActionMsg(<div className={styles.action_note_err} >{err.response.data.detail}</div>)
          showNotification('error', 'Error!', `${err.response.data.detail}`)
        })
      }
      UpdateUser()
    }
    
    // const [ backupData, setBackupData ] = useState("")
    const getAllUsers = async (keywords) => {
      await apiAdminGetUsers({
        'Authorization': `Bearer ${accessToken}`
        },
        0,
        1000,
        keywords,
        activeUser,
        companyId
      ).then(res => {
        setUsersData(res.data.data)
        hasMoreScroll = false
      }).catch(err => {
        setUsersData()
      })
      delayTime = 0
    }
    
    const handleAllUsersOnClick = () => {
      activeUser = undefined
      getAllUsers()
      document.getElementById("search_input").value = ""
      setIsOnClick({click: "", index: ""})
    }
    
    const handleFilterActiveUsersOnClick = (bool) => {
      activeUser = bool
      getAllUsers()
      setIsOnClick({click: "", index: ""})  
      document.getElementById("search_input").value = ""
    }
    
    const handleFilterCompanyOnChange = (e) => {
      companyId = e.target.value
      if (companyId === "") {
        companyId = undefined
      }
      getAllUsers()
      setIsOnClick({click: "", index: ""})
      hasMoreScroll = false
    }

    useEffect(() => {
      if (searchWords) {
        const searchTimeout = setTimeout(() => {
            getAllUsers(searchWords)
        }, delayTime);
        return () => clearTimeout(searchTimeout)
      }
    }, [searchWords])

    const handleSearchUsersOnChange = (e) => {
      delayTime = 500
      const value = e.target.value
      setSearchWords(value)
      if (!value) {
        getAllUsers()
      }
      setIsOnClick({click: "", index: ""})
    }


    let newPassword;
    let confirmPassword
    const handleNewPasswordOnChange = (e) => {
      newPassword = e.target.value
    }
    const handleConfirmPasswordOnChange = (e) => {
      confirmPassword = e.target.value
    }

    const submitChangePassword = () => {
    
      let id = parseInt(localStorage.getItem('change-pwd'));

      if(newPassword == undefined) {
        showNotification('error', 'Error!', 'Password cannot be empty');
        return;
      }
      if(confirmPassword == undefined) {
        showNotification('error', 'Error!', 'Please confirm your password again');
        return;
      }
      if(confirmPassword !== newPassword) {
        showNotification('error', 'Error!', 'Confirm password should be the same with the new one you input.');
        return;
      } 
      apiAdminChangeUserPassword(
        {headers: {'Authorization': `Bearer ${accessToken}`}}, {new_password: newPassword}, id
      ).then(res => {
        setOpenChangeUserPWD(false);
        localStorage.removeItem('change-pwd')
        showNotification('success', 'Success!', 'Successfully changed password.')
      }).catch(err => {
        showNotification('error', 'Error!', `${err.response.data.detail}`)
      })
    }

    let scroll_height = "77vh"
    const setHeight = (e) => {
      scroll_height = e
    }

    const handlePermissionOnChange = (e) => {
      const value = parseInt(e.target.value)
      if (e.target.checked) {
        setPermit.push(value)
      } else {
        setPermit.splice(setPermit.indexOf(value), 1)
      }
    }

    const [ userPermission, setUserPermission ] = useState()
    const getUserPermission = async (id) => {
      setPermit = []
      setUserPermission(null)
      await apiAdminGetUserPermission({
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      },
      id
      ).then(res => {
        setUserPermission(res.data.data)
        res.data.data.map(data => {
          setPermit.push(data.id) 
        })
      }).catch(err => {
        setUserPermission([])
      })
    }

    const formatDate = (date) => {
        return new Date(date).toLocaleString('sv')
    }

    const showUserDeleteConfirm = (data, id, index) => {
      confirm({
          title: 'Warming!',
          icon: <ExclamationCircleFilled />,
          content: `Are you sure delete this user, ${data.full_name}?`,
          centered: true,
          okText: 'Delete',
          okType: 'danger',
          cancelText: 'Cancel',
          onOk() {
            deleteUser(id, index);
          }
      });
    };

    const handleEnableUserOnClick = (user_id, index) => {
      apiAdminEnableUser({headers:{'Authorization': `Bearer ${accessToken}`}}, user_id)
      .then(res => {
        usersData[index] = {...usersData[index], is_enabled: true}
        setUsersData([...usersData]);
        showNotification('success', 'Success!', 'User enabled successfully.')
      }).catch(err => {
        showNotification('error', 'Error!', `${err.response.data.detail}`)
        switch_ref.current[index].current.resetFields();
      })
    }

    const handleDisableUserOnClick = (user_id, index) => {
      apiAdminDisableUser({headers:{'Authorization': `Bearer ${accessToken}`}}, user_id)
      .then(res => {
        usersData[index] = {...usersData[index], is_enabled: false}
        setUsersData([...usersData])
        showNotification('success', 'Success!', 'User disabled successfully.')
      }).catch(err => {
        showNotification('error', 'Error!', `${err.response.data.detail}`)
        switch_ref.current[index].current.resetFields();
      })
    }

    const handleSwitchOnClick = async (e, index, id) => {
      let myself = JSON.parse(localStorage.getItem('me'));
      if(myself.id == id) {
        switch_ref.current[index].current.resetFields();
        showNotification('error', 'Error!', `You cannot enable/disable yourself.`)
      } else {
        //如果進來的值是true 表示 user 想 enable
        if(e) {
          handleEnableUserOnClick(id, index);
        } else {
          handleDisableUserOnClick(id, index);
        };
      };
    };

    const handleChangeUserPWD = (id) => {
      localStorage.setItem('change-pwd', id);
    };

    
    return (
        <>
        {/* { isOnClick.click === "detail" &&
        <UserDetailPage user_data={usersData[isOnClick.index]} back={setIsOnClick} superuser sessionAPI={apiAdminGetUserSessions}/>
        } */}
        {/* { isOnClick.click === "log" &&
        <UserLogsPage user_data={usersData[isOnClick.index]} back={setIsOnClick} superuser />
        } */}
        <div className={styles.user_page} style={{display: isOnClick.click === "log" && 'none'}}>
          {openChangeUserPWD && 
            <div className={styles.user_filter} >
              <div className={styles.user_change_pwd_block}>
                <label htmlFor="new_password">New Password</label>
                <input id="new_password" name="new_password" placeholder="Input new password" onChange={handleNewPasswordOnChange}/>
                <label htmlFor="confirm_password">Confirm Password</label>
                <input autoComplete="off" id="confirm_password" name="confirm_password" placeholder="Confirm again" onChange={handleConfirmPasswordOnChange}/>
                <div className={styles.user_change_pwd_actions}>
                  <div className={styles.user_change_pwd_actions_cancel_btn} onClick={() => setOpenChangeUserPWD(false)}>
                      <span>Cancel</span>
                  </div>
                  <div className={styles.user_change_pwd_actions_confirm_btn} onClick={() => submitChangePassword(targetUserID)}>
                      <span>Confirm</span>
                  </div>
                </div>
              </div>
            </div>
          }

          <SearchBar 
            name="Users"
            search={handleSearchUsersOnChange}
            all={handleAllUsersOnClick}
            companyList={companiesData}
            companyOnChange={handleFilterCompanyOnChange}
            filter={handleFilterActiveUsersOnClick}
            add={initAddUser}
            superuser = {me.is_superuser}
          />
          <div className={styles.user_header}>
            <div className={styles.user_header_item}>
              <span>User</span>
            </div>
            <div className={styles.user_header_item_15}>
              <span>E-mail</span>
            </div>
            <div className={styles.user_header_item_15}>
            { me && me.is_superuser &&
              <span>Company</span>
            }
            </div>
            <div className={styles.user_header_item_15}>
              <span>Enable / Disable</span>
            </div>
            <div className={styles.user_header_item_action}>
              <span>Action</span>
            </div>
          </div>
          { isOnClick.click === "add" ? 
          <div className={styles.user__data__add__wrap} >
            <div className={styles.user__data__input__block}>
              <label htmlFor="add_user_full_name">User Name</label>
              <input id="add_user_full_name" name="full_name" placeholder="User Name" onChange={handleEditOnChange}/>
              <label htmlFor="add_user_email">E-Mail</label>
              <input autoComplete="off" id="add_user_email" name="email" type="email" placeholder="E-Mail" onChange={handleEditOnChange}/>
            </div>
            <div className={styles.user__data__input__block}>
              <label htmlFor="add_user_company_id">Company</label>
              {me && me.is_superuser && 
                <select id="add_user_company_id" name="company_id" defaultValue="" onChange={handleEditOnChange}>
                    <option disabled value="">Select Company</option>
                  { companiesData && companiesData.map((data, index) => {
                    return (
                      <option value={data.id} key={index}>{data.name}</option>
                    )
                  })}
                </select>
              }
              {me && !me.is_superuser && 
                <input id="add_user_company_id" name="company_id" value={me.company_name} disabled/>
              }
              
              <label htmlFor="add_user_password">Password</label>
              <input id="add_user_password" name="password" placeholder="At least 8 characters" onChange={handleEditOnChange}/>
            </div>
            <div className={styles.user__data__input__block}>
              <label htmlFor="add_user_phone_number">Phone Number</label>
              <input id="add_user_phone_number" name="phone_number" placeholder="Please + country code" onChange={handleEditOnChange}/>
            </div>
            <div className={styles.user__data__checkbox__block}>
              <span>Permission</span>
              <div className={styles.user__data__checkbox__group}>
                <label title="User Read" className={styles.checkbox_label} htmlFor="user_read">
                    <input title="User Read" value="4" className={styles.edit_checkbox} id="user_read" type="checkbox" onChange={handlePermissionOnChange} />
                    User Read
                  </label>
                <label title="Create/Update/Delete/Enable/Disable User" className={styles.checkbox_label} htmlFor="user_write">
                  <input title="Create/Update/Delete/Enable/Disable User" value="5" className={styles.edit_checkbox} id="user_write" type="checkbox" onChange={handlePermissionOnChange} />
                    User Write
                </label>
                <label title="Read BMC-Lite" className={styles.checkbox_label} htmlFor="device_read">
                    <input title="Read BMC-Lite" value="6" className={styles.edit_checkbox} id="device_read" type="checkbox" onChange={handlePermissionOnChange} />
                    Device Read
                  </label>
                  <label title="Create/Update/Delete BMC-Lite" className={styles.checkbox_label} htmlFor="device_write">
                    <input title="Create/Update/Delete BMC-Lite" value="7" className={styles.edit_checkbox} id="device_write" type="checkbox" onChange={handlePermissionOnChange} />
                    Device Write
                  </label>
                  <label title="Execute BMC-Lite Command" className={styles.checkbox_label} htmlFor="device_execute">
                    <input title="Execute BMC-Lite Command" value="8" className={styles.edit_checkbox} id="device_execute" type="checkbox" onChange={handlePermissionOnChange} />
                    Device Execute
                  </label>
              </div>
            </div>
            <div className={styles.user__data__add__block}>
                <div className={styles.user__add__cancel__btn} onClick={() => setIsOnClick({click: "", index: ""})}>
                    <span>Cancel</span>
                </div>
                <div className={styles.user__add__create__btn} onClick={() => addUser()}>
                    <span>Create</span>
                </div>
            </div>
          </div>
          // <div className={styles.user_data} style={{marginBottom: '0'}}>
          //   {setHeight("54vh")}
          //   <div className={styles.edit_box}>
          //     <div className={styles.edit_box_2}>
          //       <label htmlFor="add_user_full_name">User Name</label>
          //       <input id="add_user_full_name" name="full_name" placeholder="User Name" onChange={handleEditOnChange}/>
          //     </div>
          //     <div className={styles.edit_box_2}>
                // <label htmlFor="add_user_email">E-Mail</label>
                // <input autoComplete="off" id="add_user_email" name="email" type="email" placeholder="E-Mail" onChange={handleEditOnChange}/>
          //     </div>
          //   </div>
          //   <div className={styles.edit_box}>
          //     <div className={styles.edit_box_2}>
          //       <label htmlFor="add_user_company_id">Company</label>
          //       <select id="add_user_company_id" name="company_id" defaultValue="" onChange={handleEditOnChange}>
          //         <option disabled value="">Select Company</option>
          //         { companiesData && companiesData.map((data, index) => {
          //           return (
          //             <option value={data.id} key={index}>{data.name}</option>
          //           )
          //         })}
          //       </select>
          //     </div>
          //     <div className={styles.edit_box_2}>
          //       <label htmlFor="add_user_password">Password</label>
          //       <input id="add_user_password" name="password" placeholder="At least 8 chracters" onChange={handleEditOnChange}/>
          //     </div>
          //   </div>
          //   <div className={styles.edit_box}>
          //     <div className={styles.edit_box_2}>
          //       <label htmlFor="add_user_phone_number">Phone Number</label>
          //       <input id="add_user_phone_number" name="phone_number" placeholder="+Country code is required" onChange={handleEditOnChange}/>
          //     </div>
          //     <div className={styles.edit_box_2}>
          //       <label htmlFor="add_user_welcome_email">Welcome Mail</label>
          //       <select id="add_user_welcome_email" name="send_welcome_email" defaultValue="false" onChange={handleEditOnChange}>
          //         <option value="true">Yes</option> 
          //         <option value="false">No</option>
          //       </select>
          //     </div>
          //   </div>
          //   <div className={styles.edit_box} style={{padding: '0.8% 0'}}>
          //         <span>Permission</span>
          //           <label title="User Read" className={styles.checkbox_label} htmlFor="user_read">
          //             <input title="User Read" value="4" className={styles.edit_checkbox} id="user_read" type="checkbox" onChange={handlePermissionOnChange} />
          //             User Read
          //           </label>
          //           <label title="Create/Update/Delete/Enable/Disable User" className={styles.checkbox_label} htmlFor="user_write">
          //             <input title="Create/Update/Delete/Enable/Disable User" value="5" className={styles.edit_checkbox} id="user_write" type="checkbox" onChange={handlePermissionOnChange} />
          //             User Write
          //           </label>
          //           <label title="Read BMC-Lite" className={styles.checkbox_label} htmlFor="device_read">
          //             <input title="Read BMC-Lite" value="6" className={styles.edit_checkbox} id="device_read" type="checkbox" onChange={handlePermissionOnChange} />
          //             Device Read
          //           </label>
          //           <label title="Create/Update/Delete BMC-Lite" className={styles.checkbox_label} htmlFor="device_write">
          //             <input title="Create/Update/Delete BMC-Lite" value="7" className={styles.edit_checkbox} id="device_write" type="checkbox" onChange={handlePermissionOnChange} />
          //             Device Write
          //           </label>
          //           <label title="Execute BMC-Lite Command" className={styles.checkbox_label} htmlFor="device_execute">
          //             <input title="Execute BMC-Lite Command" value="8" className={styles.edit_checkbox} id="device_execute" type="checkbox" onChange={handlePermissionOnChange} />
          //             Device Execute
          //           </label>
          //   </div>
          //   <div className={styles.edit_box} style={{marginLeft: '0'}} >
          //     <div className={styles.edit_box_2}>
          //       <div className={styles.edit_box_btn} onClick={() => addUser()}>ADD</div>
          //     </div>
          //     <div className={styles.edit_box_2}>
          //       <div className={styles.edit_box_btn} onClick={() => setIsOnClick({click: "", index: ""})}>CANCEL</div>
          //     </div>
          //   </div>
          //   <div className={styles.user_err_msg}>{actionMsg}</div>
          // </div>
          : 
          setHeight("77vh")
          }

          <div className={styles.user_content}>
            { usersData && 
            <InfiniteScroll
                style={{"overflowY":"overlay", "overflowX":"hidden"}}
                dataLength={usersData.length} 
                next={nextScrollData}
                hasMore={hasMoreScroll}
                loader={<PageLoader />}
                height={scroll_height}
            >
              <div className={styles.users__group}>
                { usersData.map((data, index) => {
                return (
                  <div className={styles.user_data} key={data.id}>
                    <div className={styles.user_content_item}><span>{data.full_name}</span></div>
                    <div className={styles.user_content_item}><span>{data.email}</span></div>
                    <div className={styles.user_content_item}>
                      {me && me.is_superuser && <span>{data.company_name}</span>}
                    </div>
                    <div className={styles.user_content_item_switch}>
                      <Form ref={switch_ref.current[index]} initialValues={{user_enable: data.is_enabled}}>
                        <Form.Item  name="user_enable" valuePropName="checked">
                          <Switch id={`user_switch_${data.id}`} defaultChecked={data.is_enabled} onChange={(e, event) => {handleSwitchOnClick(e, index, data.id)}}/>
                        </Form.Item>
                      </Form>
                      {/* <Switch defaultChecked={data.is_enabled} onClick={handleSwitchOnClick} /> */}
                    </div>
                    <div className={styles.user__action__btn__group}>
                      <div className={styles.user__change__btn}
                            onClick={(e) => {e.stopPropagation(); setOpenChangeUserPWD(true); handleChangeUserPWD(data.id);}}
                        >
                            <span>Change PWD</span>
                        </div>
                        <div className={styles.user__detail__btn}
                            onClick={(e) => {e.stopPropagation(); localStorage.setItem('ud', data.id); localStorage.setItem('u-info', JSON.stringify(data))}}
                        > 
                          <Link to={`/admin/users/${data.full_name}`}>
                            <span>Detail</span>
                          </Link>
                        </div>
                        <div className={styles.user__delete__btn}
                            onClick={(e) => {e.stopPropagation(); showUserDeleteConfirm(data, data.id, index);}}
                        >
                            <span>Delete</span>
                        </div>
                    </div>
                  </div>
                )
                })}
              </div>
            </InfiniteScroll>}
          </div>
        </div>
        </>
      )
}
export default UserPage