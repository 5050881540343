import { apiGetMeSessions, apiClearMeSessions, apiDeleteMeSession } from '../../api';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";


import './style.scss';

const  PersonalPage = (props) => {
    
    const accessToken = JSON.parse(localStorage.getItem('access_token'))
    const [ sessions, setSessions ] = useState()
    const getMeSessions = async () => {
        // document.getElementById('profile_option').style.display = 'none';
        // setIsOnClick("session");
        await apiGetMeSessions( {
        'Authorization': `bearer ${accessToken}`
        }).then(res => {
            setSessions(res.data.data)
        }).catch(err => {

        })
    }

    let strInfo = localStorage.getItem('me');
    let myInfo = JSON.parse(localStorage.getItem('me'));
    let userID = myInfo.id;
    localStorage.setItem('ud', userID);
    localStorage.setItem('u-info', strInfo);

    const clearMeSession = async () => {
        await apiClearMeSessions({
            headers:{
                'Authorization': `Bearer ${accessToken}`
            }}
        ).then(res => {
            getMeSessions()
        }).catch(err => {
        
        })
    }

    const deleteMeSession = async (session_id, index) => {
        await apiDeleteMeSession({
            headers:{
                'Authorization': `Bearer ${accessToken}`
            }},
            session_id
        ).then(res => {
            sessions.splice(index, 1)
            setSessions([...sessions])
        }).catch(err => {

        })
    }


    useEffect(() => {
        getMeSessions();
    }, []);

    useEffect(() => {
        if(props.setActiveItem) props.setActiveItem('personal')
    }, [props.setActiveItem]);

    

    const formatDate = (date) => {
        return new Date(date).toLocaleString('sv')
    }

    // let sessions = props.meSessions
    return (
        <div className='profile_page'>
            <div className="profile_page_title_wrap">
                <div className='profile_title_active'><span>Personal Settings</span></div>
                <div className="profile_log_btn">
                    <Link to="/profile/my-logs">
                        <span>Check out my logs</span>
                    </Link>
                </div>
            </div>
            <div className="profile_page_content">
                <div className="profile_page_personal_setting">
                    <div className="profile_personal_settings_block">
                        <div className="profile_personal_items">
                            <div className="profile_personal_items_title">
                                <span>Timezone :</span>
                            </div>
                            <div className="profile_personal_items_content">
                                <span>{props.me.timezone}</span>
                            </div>
                        </div>
                        <div className="profile_personal_items">
                            <div className="profile_personal_items_title">
                                <span>Company :</span>
                            </div>
                            <div className="profile_personal_items_content">
                                <span>{props.me.company_name}</span>
                            </div>
                        </div>
                        <div className="profile_personal_items">
                            <div className="profile_personal_items_title">
                                <span>Name :</span>
                            </div>
                            <div className="profile_personal_items_content">
                                <input type="text" placeholder='Name' value={props.me.full_name} />
                            </div>
                        </div>
                        <div className="profile_personal_items">
                            <div className="profile_personal_items_title">
                                <span>E-mail :</span>
                            </div>
                            <div className="profile_personal_items_content">
                                <input type="email" placeholder='E-mail' value={props.me.email}/>
                            </div>
                        </div>
                        <div className="profile_personal_items">
                            <div className="profile_personal_items_title">
                                <span>Phone :</span>
                            </div>
                            <div className="profile_personal_items_content">
                                <input type="text" placeholder='Phone number' value={props.me.phone_number}/>
                            </div>
                        </div>
                    </div>
                    <div className="profile_personal_settings_block">
                        <div className="profile_personal_items"></div>
                        <div className="profile_personal_items"></div>
                        <div className="profile_personal_items">
                            <div className="profile_personal_items_title">
                                <span>Current Password :</span>
                            </div>
                            <div className="profile_personal_items_content">
                                <input type="text" placeholder='Current password' />
                            </div>
                        </div>
                        <div className="profile_personal_items">
                            <div className="profile_personal_items_title">
                                <span>New Password :</span>
                            </div>
                            <div className="profile_personal_items_content">
                                <input type="email" placeholder='At least 8 characters' />
                            </div>
                        </div>
                        <div className="profile_personal_items">
                            <div className="profile_personal_items_title">
                                <span>Confirm Password :</span>
                            </div>
                            <div className="profile_personal_items_content">
                                <input type="text" placeholder='Confirm new password' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile_personal_setting_confirm_btn_group">
                    <div className="profile_personal_setting_confirm_btn">
                        <span>Save changes</span>
                    </div>
                    <div className="profile_personal_setting_confirm_btn">
                        <span>Change Password</span>
                    </div>
                </div>
                {/* <div className="profile_page_session_management"></div>
                <div className="profile_page_personal_log"></div> */}
            </div>
            <div className="profile_page_title_wrap_session">
                <div className='profile_title_active'><span>Session Management</span></div>
                <div className="profile_log_btn" onClick={(e) => {e.stopPropagation(); props.clearMeSession();}}>
                    <span>Revoke All Sessions</span>
                </div>
            </div>
            <div className="profile_page_content">
                {sessions && sessions != undefined && 
                    <div className="profile_session_management">
                        {sessions.map((s, i) => {
                            return(
                            <div className='session_box' key={i}>
                                { !s.is_current_session &&
                                <div className='session_revoke_btn' onClick={() => deleteMeSession(s.id, i)}>
                                    <span>Revoke</span>
                                </div>
                                }
                                { s.is_current_session &&
                                <div className='session_profile_item' style={{fontWeight: 'bolder'}}>
                                    <span>Current Session</span>
                                </div>
                                }
                                <div className='session_profile_item'>
                                    <span>Browser</span>
                                    <span>{s.browser}</span>
                                </div>
                                <div className='session_profile_item'>
                                    <span>OS</span>
                                    <span>{s.os}</span>
                                </div>
                                <div className='session_profile_item'>
                                    <span>Device</span>
                                    <span>{s.device}</span>
                                </div>
                                <div className='session_profile_item'>
                                    <span>IP</span>
                                    <span>{s.ip}</span>
                                </div>
                                <div className='session_profile_item'>
                                    <span>First Login</span>
                                    <span>{formatDate(s.created_at)}</span>
                                </div>
                                <div className='session_profile_item'>
                                    <span>Last Access</span>
                                    <span>{formatDate(s.last_use_at)}</span>
                                </div>
                            </div>
                            );
                        })}
                    </div>
                }
            </div>
        </div>
    );
};

export default PersonalPage;