import { useEffect, useState } from "react"
import { apiAdminGetUserLogs, apiGetUserLogs, apiAdminDownloadUserLogs } from "../../api"
import styles from './UserLogsPage.module.scss'
import { ExportHtmlButton, ExportCsvButton, CalendarIcon, LogLevelIcon } from "../ActionButton"
import ReactPaginate from 'react-paginate'
import DatePicker from "react-datepicker"

let logsParams = {
  levels: [ 'debug', 'info', 'warning', 'error', 'critical' ],
  start_dt: new Date().toLocaleDateString('sv') + " 00:00:00",
  end_dt: new Date().toLocaleDateString('sv') + " 23:59:59",
  sort_by: "created_at",
  skip: 0,
  limit: 100
}

let delayTime = 0,
    pageNum = -1

    // {user_data, back, superuser}
export const UserLogsPage = (props) => {

  // console.log(window.location.pathname)
  let path = window.location.pathname;
  let pathArr = path.split('/')
  
  useEffect(() => {
    console.log(pathArr)
    if(props.setActiveItem) {
      if (pathArr.includes('profile')) {
        props.setActiveItem('personal');
      }
      if (pathArr.includes('users')) {
        props.setActiveItem('users');
      }
    }
}, [props.setActiveItem]);

  let userID = localStorage.getItem('ud');
  let userInfo = JSON.parse(localStorage.getItem('u-info'));

  const accessToken = JSON.parse(localStorage.getItem('access_token'))

  const [ userLogsData, setUserLogsData ] = useState()
  const [ pageCount, setPageCount ] = useState(0)
  const [ dateString, setDateString ] = useState('Today')
  const [ dateRange, setDateRange ] = useState([null, null]);
  const [ startDate, endDate ] = dateRange;
  const [ actionMsg, setActionMsg ] = useState("")
  const [ isLogsFilterActive, setIsLogsFilterActive ] = useState(null)
  const [ logLevelString, setLogLevelString ] = useState('All Levels')
  const [ showLogLevelOption, setShowLogLevelOption ] = useState(false)
  const [ searchWords, setSearchWords ] = useState()

  console.log('user log')

  const handlePageClick = (e) => {
    pageNum = e.selected
    logsParams.skip = logsParams.limit * pageNum
  }

  const getUserLogs = async () => {
    if (props.me.is_superuser) {await apiAdminGetUserLogs({'Authorization': `Bearer ${accessToken}`}, userID, logsParams)
      .then(res => {
        setActionMsg()
        setUserLogsData(res.data.data)
        setPageCount(Math.ceil(res.data.metadata.total / logsParams.limit))
      }).catch(err => {
        setPageCount(1)
        setUserLogsData()
        setActionMsg(<div style={{display: 'flex', marginTop: '10px', marginLeft: '6px'}} className={styles.action_note_err} ><span>{err.response.data.detail}</span></div>)
      })
    } else {
      console.log('user', userID)
      await apiGetUserLogs({'Authorization': `Bearer ${accessToken}`}, userID, logsParams)
      .then(res => {
        setActionMsg()
        setUserLogsData(res.data.data)
        setPageCount(Math.ceil(res.data.metadata.total / logsParams.limit))
      }).catch(err => {
        setPageCount(1)
        setUserLogsData()
        setActionMsg(<div style={{display: 'flex', marginTop: '10px', marginLeft: '6px'}} className={styles.action_note_err}><span>{err.response.data.detail}</span></div>)
      })
    }
      
    if (logsParams.skip === 0) {
      pageNum = 0
    }
  }

  useEffect(() => {
    getUserLogs()

    return () => {
      pageNum = -1
      logsParams = {
        levels: [ 'debug', 'info', 'warning', 'error', 'critical' ],
        start_dt: new Date().toLocaleDateString('sv') + " 00:00:00",
        end_dt: new Date().toLocaleDateString('sv') + " 23:59:59",
        sort_by: "created_at",
        skip: 0,
        limit: 100
      }
    }
  }, [accessToken])
  

  const handleLogsFilterOnClink = (catogory) => {
    setIsLogsFilterActive(catogory)
    logsParams.skip = 0
    logsParams.category = catogory
    delete logsParams.keyword
    getUserLogs();
    document.getElementById("search_input").value = ""
  }

  useEffect(() => {
    if (searchWords) {
      const searchTimeout = setTimeout(() => {
          getUserLogs()
      }, delayTime);
      return () => clearTimeout(searchTimeout)
    }
  }, [searchWords])

  const handleSearchLogsOnChange = (e) => {
    delayTime = 500
    const value = e.target.value
    logsParams.keyword = value
    logsParams.skip = 0
    setSearchWords(value)
    if (!value) {
      delete logsParams.keyword
      getUserLogs()
    }
  }

  const downloadUserLogs = async (id, format) => {
    logsParams.format = format
    await apiAdminDownloadUserLogs(
      {
        'Authorization': `Bearer ${accessToken}`
      },
      id,
      logsParams,
    ).then(res => {
      const href = window.URL.createObjectURL(res.data);
      const anchorElement = document.createElement('a');

      anchorElement.href = href;
      anchorElement.download = res.headers['content-disposition'].replace(/attachment; filename=|["]/g, '')
      document.body.appendChild(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    }).catch(err => {

    })
    delete logsParams.format
  }

  const handleLogsLevelOnChange = (e) => {
    if (e.target.checked) {
      logsParams.levels.push(e.target.value)
    } else {
      logsParams.levels.splice(logsParams.levels.indexOf(e.target.value), 1)
    }
  }

  
  // Click outside of div to close div
  let container_date = document.getElementById('log_date_option'),
      container_level = document.getElementById('log_level_option')
  document.addEventListener('mouseup', function eventHandler(e) {
    if (container_date && !container_date.contains(e.target)) {
      container_date.style.display = 'none';
    }
  })

  if (showLogLevelOption) {
    document.addEventListener('mouseup', function eventHandler(e) {
      if (container_level && !container_level.contains(e.target)) {
        logsParams.skip = 0
        delete logsParams.keyword
        document.getElementById("search_input").value = "";
        setShowLogLevelOption(false)
        getUserLogs()
        if (logsParams.levels.length === 5 || logsParams.levels.length === 0) {
          setLogLevelString('All Levels')  
        } else {
          setLogLevelString(logsParams.levels.toString().replaceAll(',', ', '))
        }
        document.removeEventListener('mouseup', eventHandler)
      }
    })
  }

  return (
    <div className={styles.user_log_page}>
          {/* <svg className={styles.log_close} viewBox="2 2 20 20"
            onClick={() => {back({click: "", index: ""}); setUserLogsData(); }} >
            <title>Back</title>
            <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
          </svg> */}
          <div className={styles.user_log_filter_btn} >
            <div className={isLogsFilterActive === null ? styles.filter_btn_active : styles.filter_btn} onClick={() => handleLogsFilterOnClink(null)}>
                <span>All Logs</span>
            </div>
            <div className={isLogsFilterActive === "system" ? styles.filter_btn_active : styles.filter_btn} onClick={() => handleLogsFilterOnClink('system')}  >
                <span>System</span>
            </div>
            <div className={isLogsFilterActive === "connection" ? styles.filter_btn_active : styles.filter_btn} onClick={() => handleLogsFilterOnClink('connection')}  >
                <span>Connection</span>
            </div>
            <div className="search_bar" style={{width: '72%'}} >
                <svg viewBox="1 2 21 21" height="1.2vw" width="1.2vw" >
                    <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                </svg>
                <input id="search_input" name="search" placeholder="Search log messages" onChange={handleSearchLogsOnChange}/>
            </div>
          </div>
          <div className={styles.user_log_page_header}>
            <div className={styles.user_log_page_header_item}>
              <span>User</span>
            </div>
            <div className={styles.user_log_page_header_item}>
              <span>E-mail</span>
            </div>
            <div className={styles.user_log_page_header_item}>
              <span>Company</span>
            </div>
            <div className={styles.user_log_page_header_item}>
              <span>Level</span>
            </div>
            <div className={styles.user_log_page_header_item}>
              <span>Date</span>
            </div>
            <div className={styles.user_log_page_header_item}>
              <span>Action</span>
            </div>
          </div>
          <div className={styles.user_data} style={{position: "relative", zIndex: '1' }}>
            <div className={styles.user_content_item}>
              <span>{userInfo.full_name}</span>
            </div>
            <div className={styles.user_content_item}>
              <span>{userInfo.email}</span>
            </div>
            <div className={styles.user_content_item}>
              <span>{userInfo.company_name}</span>
            </div>
            <div className={styles.user_content_item}>
              <div className={styles.log__level__icon}>
                <LogLevelIcon />
                <div title={logLevelString.toString().replaceAll(',', ', ')} className={styles.level_string} onClick={() => setShowLogLevelOption(true)}><span>{logLevelString}</span></div>
              </div>
              <div id="log_level_option" user_id={userID} style={{display: showLogLevelOption ? 'block' : 'none'}} className={styles.log_level_option}>
                { [ 'debug', 'info', 'warning', 'error', 'critical' ].map((level, index) => {
                  return (
                    <label key={index} htmlFor={level} className={styles.level_option} >
                      <input id={level} value={level} type="checkbox" defaultChecked={ logsParams.levels.includes(level) ? true : false} onChange={handleLogsLevelOnChange} />
                      {level}
                    </label>
                  )
                })
                }
              </div>
            </div>
            <div className={styles.user_content_item}>
            <>
              <div className={styles.log__data__icon}>
                <CalendarIcon />
                <div onClick={() => document.getElementById("log_date_option").style.display = 'block'}>
                  <span>{dateString}</span>
                </div>
              </div>
              <div id="log_date_option" style={{display: 'none'}} className={styles.log_date_option}>
                <div className={styles.date_option} style={{borderRadius: '6px 6px 0 0'}}
                  onClick={() => {
                    delete logsParams.keyword
                    document.getElementById("search_input").value = ""
                    setDateRange([null, null]); 
                    setDateString('Today');
                    document.getElementById("log_date_option").style.display = 'none';
                    logsParams = {
                      ...logsParams,
                      skip: 0,
                      start_dt: new Date().toLocaleDateString('sv') + " 00:00:00",
                      end_dt: new Date().toLocaleDateString('sv') + " 23:59:59"
                    }
                    getUserLogs()
                  }} 
                >
                <span >Today</span>
                </div>
                <div className={styles.date_option} style={{borderRadius: '0 0 6px 6px'}} >
                  <DatePicker 
                    id="user_log_date_picker"
                    placeholderText=" Select a date"
                    // showIcon
                    dateFormat="yyyy/MM/dd"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      setDateRange(update);
                      if (update[1]) {
                        delete logsParams.keyword
                        document.getElementById("search_input").value = ""
                        document.getElementById("log_date_option").style.display = 'none'
                        logsParams = {
                          ...logsParams,
                          skip: 0,
                          start_dt: new Date(update[0]).toLocaleDateString('sv') + " 00:00:00",
                          end_dt: new Date(update[1]).toLocaleDateString('sv') + " 23:59:59"
                        }
                        getUserLogs()
                        setDateString(new Date(update[0]).toLocaleDateString('sv') + " - " + new Date(update[1]).toLocaleDateString('sv'))
                      }
                    }}
                    isClearable={true}
                  />
                </div>
              </div>
            </>
            </div>
            <div className={styles.user_content_item}>
              <div className={styles.user_log_download_btn_group}>
                <div className={styles.user_log_download_html} onClick={() => userLogsData && downloadUserLogs(userID, 'html')}>
                  <span>HTML</span>
                </div>
                <div className={styles.user_log_download_html} onClick={() => userLogsData && downloadUserLogs(userID, 'csv')}>
                  <span>CSV</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.user_log_container}>
            <div className={styles.user_log_header}>
              <span>Category</span>
              <span>Level</span>
              <span>
                Time
                { logsParams.sort_by === "created_at" && 
                <svg className={styles.log_sort_btn} viewBox="6 6 11 11" onClick={() => {logsParams.sort_by = "created_at_desc"; getUserLogs()}} >
                  <path d="M8 15H16L12 8" />
                </svg>}
                { logsParams.sort_by === "created_at_desc" && 
                <svg className={styles.log_sort_btn} viewBox="6 6 11 11" onClick={() => {logsParams.sort_by = "created_at"; getUserLogs() }} >
                  <path d="M8 9H16L12 16" />
                </svg>
                }
              </span>
              <span>Messages</span>
            </div>
            <div className={styles.user_log_content}>
              {actionMsg}
              { userLogsData && userLogsData.map((data, index) => {
                return (
                  <div className={styles.user_log_data} key={index} >
                    <div className={styles.user_log_data_content}>
                      <span>{data.category}</span>
                    </div>
                    <div className={styles.user_log_data_content}>
                      <span>{data.level_name}</span>
                    </div>
                    <div className={styles.user_log_data_content}>
                      <span>{new Date(data.created_at).toLocaleDateString('sv') + " " + new Date(data.created_at).toLocaleTimeString('sv')}</span>
                    </div>
                    <div className={styles.user_log_data_content}>
                      <span>{data.message}</span>
                    </div>
                  </div>
                  
                )
              })
              }
            </div>
          </div>
          <ReactPaginate 
            breakLabel="..."
            pageRangeDisplayed={3}
            previousLabel="<"
            nextLabel=">"
            containerClassName={styles.pagination}
            pageLinkClassName={styles.page_num}
            previousLinkClassName={styles.page_num}
            nextLinkClassName={styles.page_num}
            activeLinkClassName={styles.page_active}
            pageCount={pageCount}
            forcePage={pageNum}
            onPageChange={(e) => {pageNum = e.selected; handlePageClick(e); getUserLogs(); }}
          />
        </div>
  )
}
