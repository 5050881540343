import { useEffect, useState } from "react"

import styles from './UserDetailPage.module.scss'

import {
        apiAdminGetUser, 
        apiAdminGetCompanies,
        apiAdminModifyUser,
        apiAdminDeleteUser,
        apiAdminGetUserPermission,
        apiAdminSetUserPermission,
        apiAdminGetUserSessions,
        apiAdminClearUserSession,
        apiDeleteMeSession
        
        } from '../../api';
import { Link } from "react-router-dom";
import { Modal, notification } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
const { confirm } = Modal;

const UserDetailPage = (props) => {

    let userID = localStorage.getItem('ud');
    let myID = JSON.parse(localStorage.getItem('me')).id;

    const accessToken = JSON.parse(localStorage.getItem('access_token'));
    const [userData, setUserData] = useState({});
    const [editData, setEditData] = useState({});
    const [permissions, setPermissions] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [isEditOpen, setIsEditOpen] = useState(false);

    const [putPermissionData, setPutPermissionData] = useState([]);

    const showNotification = (type, title, content) => {
        notification.open({
            type: `${type}`,
            message: `${title}`,
            description: `${content}`,
            className: 'bmc__notification'
        });
    };

    

    const getUser = async () => {
        await apiAdminGetUser({'Authorization': `Bearer ${accessToken}`}, userID)
        .then(res => {
            console.log(res.data)
            setUserData(res.data);
            setEditData(res.data);
        })
        .catch(err => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
        })
    };

    const getPermissions = async () => {
        await apiAdminGetUserPermission({'Authorization': `Bearer ${accessToken}`}, userID)
        .then(res => {
            setPermissions(res.data.data);
            let PermitList = [];
            res.data.data.map(data => {
                PermitList.push(data.id) 
            })
            setPutPermissionData(PermitList);
            console.log(res.data.data)
        })
        .catch(err => {
            if(parseInt(userID) !== 1 ) {
                showNotification('error', 'Error!', `${err.response.data.detail}`)
            } 
        })
    };

    const getSessions = async () => {
        await apiAdminGetUserSessions({'Authorization': `Bearer ${accessToken}`}, userID)
        .then(res => {
            setSessions(res.data.data);
        })
        .catch(err => {
            setSessions([])
        })
    };

    const getCompanyList = async () => {
        await apiAdminGetCompanies({'Authorization': `Bearer ${accessToken}`})
        .then(res => {
            setCompanyList(res.data.data);
        })
        .catch(err => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
        })
    };

    const clearAllUserSessions = async () => {
        await apiAdminClearUserSession({'Authorization': `Bearer ${accessToken}`}, userID)
        .then((res) => {
            getUser();
            showNotification('success', 'Success!', 'All sessions have been deleted.')
        })
        .catch((err) => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
        });
    };

    const revokeSession = async (id) => {
        await apiDeleteMeSession({'Authorization': `Bearer ${accessToken}`}, id)
        .then((res) => {
            getUser();
            getSessions();
            showNotification('success', 'Success!', 'All sessions have been deleted.')
        })
        .catch((err) => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
        });
    };

    const deleteUser = (id) => {
        const DeleteUser = async () => {
            await apiAdminDeleteUser({headers:{'Authorization': `Bearer ${accessToken}`}}, id)
            .then(res => {
                showNotification('success', 'Success!', 'User has been deleted.')
                window.location.assign("/admin/users");
            })
            .catch(err => {
                showNotification('error', 'Error!', `${err.response.data.detail}`)
            })
        }
        DeleteUser();
    };

    const editUserDetail = async (uuid, body) => {
        await apiAdminModifyUser({'Authorization': `Bearer ${accessToken}`}, body, uuid)
        .then(res => {
            getUser();
            setIsEditOpen(!isEditOpen);
            showNotification('success', 'Success!', 'Edition has been successfully done.')
        })
        .catch(err => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
            setIsEditOpen(!isEditOpen);
        })
    };

    const handleUserEditOnChange = (e) => {
        let newUserData = {...editData, [e.target.name]: e.target.value};
        setEditData(newUserData);
    };

    const submitEdition = () => {
        if(isEditOpen) {
            let postBody = {
                full_name: editData.full_name,
                description: editData.description,
                email: editData.email,
                phone_number: `${editData.phone_number}`,
                company_id: editData.company_id
            };
            editUserDetail(userID, postBody);
        } else {
            setIsEditOpen(!isEditOpen);
        };
    };

    const revokeAllSessions = (id) => {
        clearAllUserSessions(id);
    };

    const revokeEachSession = (id) => {
        revokeSession(id);
    };

    let user_read, user_write, device_read, device_write, device_exc;
    if(Array.isArray(permissions) && !permissions.length) {
        user_read = false;
        user_write = false;
        device_read = false;
        device_write = false;
        device_exc = false;
    } else {
        permissions.filter((p) => {
            if(p.name == 'user:r') user_read = true;
            if(p.name == 'user:w') user_write = true;
            if(p.name == 'bml:r') device_read = true;
            if(p.name == 'bml:w') device_write = true;
            if(p.name == 'bml:x') device_exc = true;
        })
    };

    useEffect(() => {
        getUser();
        getPermissions();
        getSessions();
        getCompanyList();
        props.setActiveItem('users');
    }, []);

    let temp = [...putPermissionData];
    const handlePermissionOnChange = (e) => {
        const value = parseInt(e.target.value)
        if (e.target.checked) {
            temp.push(value)
        } else {
            temp.splice(temp.indexOf(value), 1)
        }
        setPutPermissionData(temp);
    };

    const setUserPermissions = async (id, data) => {
        await apiAdminSetUserPermission({headers:{'Authorization': `Bearer ${accessToken}`}}, id, data)
            .then(res => {
                showNotification('success', 'Success!', 'Permission setting has been successfully done.')
            }).catch(err => {
                showNotification('error', 'Error!', `${err.response.data.detail}`)
            })
    }


    const submitPermissionChange = () => {
        // console.log(userData.id)
        setUserPermissions(userData.id, putPermissionData)
    } 

    const formatDate = (date) => {
        return new Date(date).toLocaleString('sv')
    }


    const showUserDeleteConfirm = (data) => {
        confirm({
            title: 'Warming!',
            icon: <ExclamationCircleFilled />,
            content: `Are you sure delete this user, ${data.full_name}?`,
            centered: true,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
                deleteUser(data.id)
            }
        });
    };

    const showRevokeAllConfirm = (id) => {
        confirm({
            title: 'Warming!',
            icon: <ExclamationCircleFilled />,
            content: `Are you sure to revoke all sessions?`,
            centered: true,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
                revokeAllSessions(id);
            }
        });
    };

    const showRevokeEachConfirm = (id) => {
        
        confirm({
            title: 'Warming!',
            icon: <ExclamationCircleFilled />,
            content: `Are you sure to revoke all sessions?`,
            centered: true,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
                revokeEachSession(id);
            }
        });
    };

    return (
        <div className={styles.user_detail_page}>
            
            <div className={styles.user__detail__header__title__wrap}>
                <div className={styles.user__detail__title__active}><span>User</span></div>
                <div className={styles.user__detail__title}><span>{userData.full_name}</span></div>
            </div>
            <div className={styles.user__detail__content}>
                <div className={styles.user__detail__row}>
                    <div className={styles.user__detail__content__item}>
                        <div className={styles.user__detail__content__item__title}>
                            <span>Name</span>
                        </div>
                        <div className={styles.user__detail__content__item__body}>
                            {isEditOpen ? 
                                <input type="text" name="full_name" value={editData.full_name ? editData.full_name : ""} onChange={handleUserEditOnChange}/> 
                                : 
                                <span>{userData.full_name}</span>
                            }
                        </div>
                    </div>
                    <div className={styles.user__detail__content__item}>
                        <div className={styles.user__detail__content__item__title}>
                            <span>E-mail</span>
                        </div>
                        <div className={styles.user__detail__content__item__body}>
                            {isEditOpen ? 
                                <input type="text" name="email" value={editData.email ? editData.email : ""} onChange={handleUserEditOnChange}/> 
                                : 
                                <span>{userData.email ? userData.email : 'N/A'}</span>
                            }
                        </div>
                    </div>
                    <div className={styles.user__detail__content__item}>
                        <div className={styles.user__detail__content__item__title}>
                            <span>Phone</span>
                        </div>
                        <div className={styles.user__detail__content__item__body}>
                            {isEditOpen ? 
                                <input type="text" name="phone_number" value={editData.phone_number ? editData.phone_number : ""} onChange={handleUserEditOnChange} /> 
                                : 
                                <span>{userData.phone_number ? userData.phone_number : 'N/A'}</span>
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.user__detail__row}>
                    <div className={styles.user__detail__content__item}>
                        <div className={styles.user__detail__content__item__title}>
                            <span>Description</span>
                        </div>
                        <div className={styles.user__detail__content__item__body}>
                            {isEditOpen ? 
                                <input type="text" name="description" value={editData.description ? editData.description : ""} onChange={handleUserEditOnChange}/> 
                                : 
                                <span>{userData.description ? userData.description : 'N/A'}</span>
                            }
                        </div>
                    </div>
                    <div className={styles.user__detail__content__item}>
                        <div className={styles.user__detail__content__item__title}>
                            <span>Company</span>
                        </div>
                        <div className={styles.user__detail__content__item__body}>
                            {isEditOpen ? 
                                // <input type="text" value={userData.company_name ? userData.company_name : ""} /> 
                                <select name="company_id" onChange={handleUserEditOnChange}>
                                    <option value={editData.company_id}>{editData.company_name}</option>
                                    { companyList && companyList.map((c, i) => {
                                    return <option value={c.id} key={i}>{c.name}</option>
                                })}
                                </select>
                                : 
                                <span>{userData.company_name ? userData.company_name : 'N/A'}</span>
                            }
                        </div>
                    </div>
                    <div className={styles.user__detail__content__btn__group}>
                        <div className={styles.user__detail__content__btns}>
                            {isEditOpen ? 
                                <>
                                    <div className={styles.user__detail__content__cancel_btn} onClick={(e) => {e.stopPropagation(); setIsEditOpen(false)}}>
                                        <span>Cancel</span>
                                    </div>
                                    <div className={styles.user__detail__content__save_btn} onClick={(e) => {e.stopPropagation(); submitEdition()}}>
                                        <span>Save</span>
                                    </div>
                                </>
                                :
                                <>
                                    <div className={styles.user__detail__content__delete_btn} onClick={(e) => {e.stopPropagation(); showUserDeleteConfirm(userData);}}>
                                        <span>Delete User</span>
                                    </div>
                                    <div className={styles.user__detail__content__edit_btn} onClick={(e) => {e.stopPropagation(); submitEdition()}}>
                                        <span>Edit User</span>
                                    </div>
                                </>
                            }
                        </div>
                        <div className={styles.user__detail__content__btns}>
                            <div className={styles.user__detail__content__log_btn} onClick={(e) => {e.stopPropagation();}}>
                                <Link to={`/admin/users/${userData.full_name}/logs`}>
                                    <span>View User Logs</span>
                                </Link>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.user__detail__row}>
                    <div className={styles.user__detail__permission}>
                        <div className={styles.user__detail__content__item__title}>
                            <span>Permission</span>
                        </div>
                        <div className={styles.user__detail__content__item__body}>
                            <div className={styles.user__detail__content__permissions}>
                                <label title="User Read" className={styles.checkbox_label} htmlFor="user_read">
                                    <input title="User Read" value='4' className={styles.edit_checkbox} id="user_read" type="checkbox" onChange={handlePermissionOnChange} checked={putPermissionData.includes(4) ? true : false}/>
                                    User Read
                                </label>
                                <label title="Create/Update/Delete/Enable/Disable User" className={styles.checkbox_label} htmlFor="user_write">
                                <input title="Create/Update/Delete/Enable/Disable User" value="5" className={styles.edit_checkbox} id="user_write" type="checkbox" onChange={handlePermissionOnChange} checked={putPermissionData.includes(5) ? true : false} />
                                    User Write
                                </label>
                                <label title="Read BMC-Lite" className={styles.checkbox_label} htmlFor="device_read">
                                    <input title="Read BMC-Lite" value="6" className={styles.edit_checkbox} id="device_read" type="checkbox" onChange={handlePermissionOnChange} checked={putPermissionData.includes(6) ? true : false}/>
                                    Device Read
                                </label>
                                <label title="Create/Update/Delete BMC-Lite" className={styles.checkbox_label} htmlFor="device_write">
                                    <input title="Create/Update/Delete BMC-Lite" value="7" className={styles.edit_checkbox} id="device_write" type="checkbox" onChange={handlePermissionOnChange} checked={putPermissionData.includes(7) ? true : false}/>
                                    Device Write
                                </label>
                                <label title="Execute BMC-Lite Command" className={styles.checkbox_label} htmlFor="device_execute">
                                    <input title="Execute BMC-Lite Command" value="8" className={styles.edit_checkbox} id="device_execute" type="checkbox" onChange={handlePermissionOnChange} checked={putPermissionData.includes(8) ? true : false}/>
                                    Device Execute
                                </label>
                            </div>
                            <div className={styles.user__detail__content__permissions}>
                                <div className={styles.user__detail__permission__save_btn} onClick={(e) => {e.stopPropagation(); submitPermissionChange();}}>
                                    <span>Save</span>
                                </div>
                                <div className={styles.user__detail__permission__cancel_btn} onClick={(e) => {e.stopPropagation(); getPermissions();}}>
                                    <span>Cancel</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.user__detail__session}>
                        <div className={styles.user__detail__content__session__title}>
                            <span>Session</span>
                            {sessions && Array.isArray(sessions) && sessions.length > 0 && 
                                <div className={styles.user__detail__session__clear__btn} onClick={(e) => {e.stopPropagation(); showRevokeAllConfirm(userID)}}>
                                    <span>Revoke All Sessions</span>
                                </div>
                            }
                            {sessions && Array.isArray(sessions) && !sessions.length && 
                                <div></div>
                            }
                        </div>
                        <div className={styles.user__detail__content__session__wrap}>
                            <div className={styles.user__detail__content__session__group}>
                                {sessions && (
                                    sessions.map((s, i) => {
                                        return (
                                            <div className={styles.user__detail__session__block} key={i+1}>
                                                <div className={styles.user__detail__session__name__block}>
                                                    <span>Browser:</span>
                                                    <span>Device:</span>
                                                    <span>IP:</span>
                                                    <span>OS:</span>
                                                    <span>Created at:</span>
                                                    <span>Last activity:</span>
                                                </div>
                                                <div className={styles.user__detail__session__value__block}>
                                                    <span>{s.browser}</span>
                                                    <span>{s.device}</span>
                                                    <span>{s.ip}</span>
                                                    <span>{s.os}</span>
                                                    <span>{formatDate(s.created_at)}</span>
                                                    <span>{formatDate(s.last_use_at)}</span>
                                                </div>
                                                {userID ==  myID && 
                                                    <div className={styles.user__detail__session__delete__btn} onClick={(e) => { e.stopPropagation(); showRevokeEachConfirm(s.id)}}>
                                                        <span>Revoke</span>
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserDetailPage;