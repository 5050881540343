import { apiAdminGetCredentials, 
        apiGetTimezone, 
        apiAdminCreateCredential, 
        apiAdminEnableCredential,
        apiAdminDisableCredential,
        apiAdminDownloadCredential,
        apiAdminDeleteCredential,
        apiAdminModifyCredential,
        apiAdminBindDevice } from "../../../api"
import { useEffect, useState, useRef, createRef } from "react"
import { SearchBar } from "../../../components/SearchBar"
import { YesButton, NoButton, ModifyButton, DeleteButton, DownloadButton, CredentialEnableButton, BindButton, BindStatusIcon } from "../../../components/ActionButton"
import { PageLoader } from "../../../components/Loader"
import InfiniteScroll from "react-infinite-scroll-component"
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./AdminCredential.module.scss";
import dayjs from 'dayjs';
import { Form, Switch, Modal, DatePicker, Space, notification } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
const { confirm } = Modal;

let hasMoreScroll = true,
    newCredentialData = {},
    newBindingUuid = null

const CredentialPage = () => {
    const accessToken = JSON.parse(localStorage.getItem('access_token'))
    const [ credentialsData, setCredentialsData ] = useState([])
    const [ isOnClick, setIsOnClick ] = useState({click: "", index: ""})
    const [ actionMsg, setActionMsg ] = useState("")
    const [ startDate, setStartDate ] = useState(new Date());
    const [ isCredentialEditorOpen, setIsCredentialEditorOpen ] = useState(false);
    
    const [ targetCredential, setTargetCredential ] = useState('');
    const [ editData, setEditData ] = useState({});

    const cre_ref = useRef([]);
    cre_ref.current = credentialsData.map((c, i) => cre_ref.current[i] ?? createRef());

    const showNotification = (type, title, content) => {
        notification.open({
            type: `${type}`,
            message: `${title}`,
            description: `${content}`,
            className: 'bmc__notification'
        });
    };

    const getCredentials = async (keywords) => {
        await apiAdminGetCredentials(
            {
                'Authorization': `Bearer ${accessToken}`
            },
            keywords
        ).then(res => {
            setCredentialsData(res.data.data)
            hasMoreScroll = false
            setActionMsg()
        }).catch(err => {
            setCredentialsData([])
            if (err.response.status === 404) {
                hasMoreScroll = false
            }
        })
    }

    const [ allTimezone, setAllTimezone ] = useState([])
    const getTimezone = async () => {
        await apiGetTimezone(
            {
                'Authorization': `Bearer ${accessToken}`
            }
        ).then(res => {
            setAllTimezone(res.data)
        })
    }

    useEffect(() => {
        getCredentials()
        getTimezone()

        return () => {
            hasMoreScroll = true
        }
    }, [accessToken])

    let scroll_height = "77vh"
    const setHeight = (e) => {
        scroll_height = e
    }

    const [ searchWords, setSearchWords ] = useState()
    useEffect(() => {
        if (searchWords) {
            const searchTimeout = setTimeout(() => {
                getCredentials(searchWords)
            }, 500);
            return () => clearTimeout(searchTimeout)
        }
    }, [searchWords])
    
    const handleSearchCredentialsOnChange = (e) => {
        const value = e.target.value
        setSearchWords(value)
        if (!value) {
            getCredentials()
        }
        setIsOnClick({click: "", index: ""})
    }

    const initAddCredential = () => {
        setStartDate(new Date())
        setActionMsg("")
        newCredentialData = {
            bmc_lite_uuid: null,
            client_name: "",
            client_secret: "",
            expired_date: "",
            timezone: "UTC"
        }
        setIsOnClick({click: "add", index: ""})
    }

    const initModifyCredential = (date) => {
        // setStartDate(new Date(new Date(date).toISOString().slice(0, 10)+"T00:00:00+08:00"))
        setStartDate(new Date(date))
        newCredentialData = {
            client_secret: undefined
        }
    }

    const openCredentialEditor = (data) => {
        setTargetCredential(data.client_name);
        setIsCredentialEditorOpen(true);
        setEditData({...data});
        // console.log(editData)
    }

    const handleEditOnChange = (e) => {
        let value = e.target.value
        let temp = {
            ...editData,
            [e.target.name]: value
        }
        if (temp.bmc_lite_uuid === "") {
            temp.bmc_lite_uuid = null
        }
        setEditData(temp);
    }

    const handleDateOnChange = (date, e) => {
        console.log(date)
        console.log(e)
        let temp = {...editData, expired_date: `${e}`}
        setEditData(temp);
    }


    const modifyCredential = async (name) => {
        console.log('post:', editData)
        await apiAdminModifyCredential({headers:{'Authorization': `Bearer ${accessToken}`}}, editData, name)
        .then(res => {
            getCredentials();
            setIsCredentialEditorOpen(false);
            showNotification('success', 'Success!', 'Edition has been successfully done.')
        }).catch(err => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
        })
    };

    const handleCreateOnChange = (e) => {
        let value = e.target.value
        newCredentialData = {
            ...newCredentialData,
            [e.target.name]: value
        }
        if (newCredentialData.bmc_lite_uuid === "") {
            newCredentialData.bmc_lite_uuid = null
        }
        // setEditData(temp);
    }

    const addCredential = async () => {
        console.log(newCredentialData)
        await apiAdminCreateCredential({headers:{'Authorization': `Bearer ${accessToken}`}}, newCredentialData)
        .then(res => {
            setIsOnClick({click: "", index: ""})
            setCredentialsData(credentialsData.concat(res.data))
            showNotification('success', 'Success!', 'Successfully add a new credential.')
        }).catch(err => {
            console.log(err.response)
            setActionMsg(err.response.data.detail)
            showNotification('error', 'Error!', `${err.response.data.detail}`)
        })
    }

    const enableCredential = async (client_name, index) => {
        await apiAdminEnableCredential({headers:{'Authorization': `Bearer ${accessToken}`}}, client_name)
        .then(res => {
            credentialsData[index].disabled = false
            setCredentialsData([...credentialsData])
            showNotification('success', 'Success!', 'Credential enabled successfully.')
        }).catch((err) => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
            cre_ref.current[index].current.resetFields();
        })
    }

    const disableCredential = async (client_name, index) => {
        await apiAdminDisableCredential({headers:{'Authorization': `Bearer ${accessToken}`}}, client_name)
        .then(res => {
            credentialsData[index].disabled = true
            setCredentialsData([...credentialsData])
            showNotification('success', 'Success!', 'Credential disabled successfully.')
        })
        .catch((err) => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
            cre_ref.current[index].current.resetFields();
        })
    }

    const handleSwitchChange = (e, client_name, index) => {
        if(e) {
            // e is true means user want to enable this credential
            enableCredential(client_name, index);
        } else {
            disableCredential(client_name, index);
        }
    }

    const downloadCredential = async (name) => {
        await apiAdminDownloadCredential(
            {
                headers:{
                    'Authorization': `Bearer ${accessToken}`
                },
                responseType: 'blob'
            },
            name
        ).then(res => {
            const href = window.URL.createObjectURL(res.data);
            const anchorElement = document.createElement('a');

            anchorElement.href = href;
            anchorElement.download = "credentials.json";
            document.body.appendChild(anchorElement);
            anchorElement.click();
            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        })
    }

    const deleteCredential = async (id, index) => {
        await apiAdminDeleteCredential({headers:{'Authorization': `Bearer ${accessToken}`}}, id)
        .then(res => {
            credentialsData.splice(index, 1)
            setIsOnClick({click: "", index: ""})
            showNotification('success', 'Success!', 'Selected credential has been deleted.')
        }).catch(err => {
            console.log(err.response)
            showNotification('error', 'Error!', `${err.response.data.detail}`)
            // setActionMsg(<div className={styles.action_note_err} >{err.response.data.detail}</div>)
        })
    }

    const bindCredential = (credential_name) => {
        apiAdminBindDevice(
        {
            headers:{
                'Authorization': `Bearer ${accessToken}`
            }
        }
        ,{
            credential_name: credential_name
        },
        newBindingUuid
        ).then(res => {
            getCredentials()
            setIsOnClick({click: "", index: ""})
        }).catch(err => {
            setActionMsg(<div style={{color: 'red'}}>{err.response.data.detail}</div>)
        })
    }

    const showCredentialDeleteConfirm = (data, id, index) => {
        confirm({
            title: 'Warming!',
            icon: <ExclamationCircleFilled />,
            content: `Are you sure delete this credential, ${data.client_name}?`,
            centered: true,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
                deleteCredential(data.client_name, index);
            }
        });
    };

    const handleAllCredentialsOnClick = () => {
        document.getElementById("search_input").value = ""
        setIsOnClick({click: "", index: ""})
        getCredentials()
    }
    console.log(credentialsData)

    return (
        <div className={styles.credential_page}>
            {isCredentialEditorOpen && 
                <div className={styles.credential_filter}>
                    <div className={styles.credential_editor}>
                        <div className={styles.credential__data__input__block}>
                            <label htmlFor="client_name">Name</label>
                            <input id="client_name" name="client_name" placeholder="Name" value={editData.client_name} disabled />
                            <label htmlFor="client_secret">Password</label>
                            <input autoComplete="off" id="client_secret" name="client_secret" placeholder="Password" value={editData.client_secret} onChange={handleEditOnChange} />
                        </div>
                        <div className={styles.credential__data__input__block}>
                            <label htmlFor="timezone">Timezone</label>
                            <select defaultValue="UTC" id="timezone" onChange={e => newCredentialData.timezone = e.target.value}>
                                <option value="" disabled>Select Timezone</option>
                                { allTimezone.map((data) => {
                                    return <option value={data} key={data}>{data}</option>
                                })}
                            </select>
                            <label htmlFor="add_credential_date_picker">Expired Date</label>
                            <DatePicker 
                                id="add_credential_date_picker"
                                className={styles.date_picker}
                                dateFormat="yyyy-MM-dd"
                                defaultValue={dayjs(editData.expired_at)}
                                showYearDropdown
                                onChange={(date, e) => {
                                    handleDateOnChange(date, e)
                                }}
                            />
                        </div>
                        <div className={styles.credential__data__input__block}>
                            <label htmlFor="bmc_lite_uuid">Bind to</label>
                            <input id="bmc_lite_uuid" name="bmc_lite_uuid" placeholder="UUID of BMC Lite" value={editData.bmc_lite_uuid} onChange={handleEditOnChange} />
                        </div>
                        <div className={styles.credential__data__add__block}>
                            <div className={styles.credential__add__cancel__btn} onClick={() => setIsCredentialEditorOpen(false)}>
                                <span>Cancel</span>
                            </div>
                            <div className={styles.credential__add__create__btn} onClick={() => modifyCredential(targetCredential)}>
                                <span>Save</span>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <SearchBar 
                name="Credentials"
                add={initAddCredential}
                all={handleAllCredentialsOnClick}
                search={handleSearchCredentialsOnChange} />
            <div className={styles.credential_header}>
                <div className={styles.credential_header_item__15}>
                    <span>Name</span>
                </div>
                <div className={styles.credential_header_item__25}>
                    <span>Bound UUID</span>
                </div>
                <div className={styles.credential_header_item__5}>
                    <span>Enable/Disable</span>
                </div>
                <div className={styles.credential_header_item__25__center}>
                    <span>Expired Date</span>
                </div>
                <div className={styles.credential_header_item__center}>
                    <span>Action</span>
                </div>
            </div>
            { isOnClick.click === "add" ? 
            <div className={styles.credential__data__add__wrap} >
                <div className={styles.credential__data__input__block}>
                    <label htmlFor="client_name">Name</label>
                    <input id="client_name" name="client_name" placeholder="Name" onChange={handleCreateOnChange} />
                    <label htmlFor="client_secret">Credential Password</label>
                    <input autoComplete="off" id="client_secret" name="client_secret" placeholder="Password" onChange={handleCreateOnChange} />
                </div>
                <div className={styles.credential__data__input__block}>
                    <label htmlFor="timezone">Timezone</label>
                    <select defaultValue="UTC" id="timezone" onChange={e => newCredentialData.timezone = e.target.value}>
                        <option value="" disabled>Select Timezone</option>
                        { allTimezone.map((data) => {
                            return <option value={data} key={data}>{data}</option>
                        })}
                    </select>
                    <label htmlFor="add_credential_date_picker">Expired Date</label>
                    <Space direction="vertical">
                        <DatePicker 
                            id="add_credential_date_picker"
                            className={styles.date_picker}
                            selected={startDate}
                            onChange={(date, e) => {
                            setStartDate(new Date(date))
                            newCredentialData.expired_date = date.toISOString().slice(0, 10);
                        }} />
                    </Space>
                    {/* <DatePicker 
                        id="add_credential_date_picker"
                        className={styles.date_picker}
                        dateFormat="yyyy-MM-dd"
                        selected={startDate}
                        showYearDropdown
                        onChange={(date, e) => {
                            setStartDate(new Date(date))
                            newCredentialData.expired_date = date.toISOString().slice(0, 10);
                        }}
                    /> */}





                </div>
                <div className={styles.credential__data__input__block}>
                    <label htmlFor="bmc_lite_uuid">Bind to</label>
                    <input id="bmc_lite_uuid" name="bmc_lite_uuid" placeholder="UUID of BMC Lite" onChange={handleEditOnChange} />
                </div>
                <div className={styles.credential__data__add__block}>
                    <div className={styles.credential__add__cancel__btn} onClick={() => setIsOnClick({click: "", index: ""})}>
                        <span>Cancel</span>
                    </div>
                    <div className={styles.credential__add__create__btn} onClick={() => addCredential()}>
                        <span>Create</span>
                    </div>
                </div>
            </div>
            // <div className={styles.credential_data} style={{marginBottom: '0', zIndex: '2'}}>
            //     {setHeight("54vh")}
            //     <div className={styles.edit_box}>
            //         <div className={styles.edit_box_2}>
            //             <label htmlFor="client_name">Client Name</label>
            //             <input id="client_name" name="client_name" placeholder="Client Name" onChange={handleEditOnChange} />
            //         </div>
            //         <div className={styles.edit_box_2}>
            //             <label htmlFor="bmc_lite_uuid">UUID</label>
            //             <input id="bmc_lite_uuid" name="bmc_lite_uuid" placeholder="Client ID" onChange={handleEditOnChange} />
            //         </div>
            //     </div>
            //     <div className={styles.edit_box}>
            //         <div className={styles.edit_box_2}>
            //             <label htmlFor="timezone">Timezone</label>
            //             <select defaultValue="UTC" id="timezone" onChange={e => newCredentialData.timezone = e.target.value}>
            //                 <option value="" disabled>Select Timezone</option>
            //                 { allTimezone.map((data) => {
            //                     return <option value={data} key={data}>{data}</option>
            //                 })}
            //             </select>
            //         </div>
            //         <div className={styles.edit_box_2}>
            //             <label htmlFor="add_credential_date_picker">Expired Date</label>
            //             <DatePicker 
            //                 id="add_credential_date_picker"
            //                 className={styles.date_picker}
            //                 dateFormat="yyyy-MM-dd"
            //                 selected={startDate}
            //                 showYearDropdown
            //                 onChange={(date, e) => {
            //                     setStartDate(new Date(date))
            //                     newCredentialData.expired_date = date.toISOString().slice(0, 10);
            //                 }}
            //             />
            //             {/* <input name="expired_date" defaultValue="" placeholder="Date Format: 2000-01-01" onChange={handleEditOnChange} /> */}
            //             {/* <input name="expired_date" defaultValue={new Date().toLocaleDateString('sv')} placeholder="Date Format: 2000-01-01" onChange={handleEditOnChange} /> */}
            //         </div>
            //     </div>
            //     <div className={styles.edit_box}>
            //         <div className={styles.edit_box_2}>
            //             <label htmlFor="client_secret">Credential Password</label>
            //             <input id="client_secret" name="client_secret" placeholder="Credential Password" onChange={handleEditOnChange} />
            //         </div>
            //     </div>
            //     <div className={styles.edit_box} >
            //         <div className={styles.edit_box_2}>
            //             <div className={styles.edit_box_btn} onClick={() => addCredential()}>ADD</div>
            //         </div>
            //         <div className={styles.edit_box_2}>
            //             <div className={styles.edit_box_btn} onClick={() => setIsOnClick({click: "", index: ""})}>CANCEL</div>
            //         </div>
            //     </div>
            //     <div className={styles.err_msg}>{actionMsg}</div>
            // </div>
            : <></>
            }
            
            { !hasMoreScroll && credentialsData.length === 0 && 
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '20%'
            }}>
                <h2>Credential not found.</h2>
            </div>
            }


            <div className={styles.credential_content}>
            { credentialsData && 
                <InfiniteScroll
                    style={{"overflowY":"overlay", "overflowX":"hidden"}}
                    dataLength={credentialsData.length} 
                    hasMore={hasMoreScroll}
                    next={undefined}
                    loader={
                        <PageLoader />
                    }
                    height={scroll_height}
                >
                <div className={styles.credentials__group}>
                { credentialsData.map((data, index) => {
                return (
                    <div className={styles.credential_data} key={data.client_name} style={{ zIndex: isOnClick.click === "edit" && isOnClick.index === index && '2'}}>
                        <div className={styles.credential_content_item__15}>
                            <span>{data.client_name}</span>
                        </div>
                        <div className={styles.credential_content_item__25__center}>
                            { isOnClick.click === "bind" && isOnClick.index === index ?
                            <span>
                                <input id="device_uuid" placeholder="Device UUID" onChange={e => newBindingUuid = e.target.value}/>
                            </span>
                            :
                            <span>{data.bmc_lite_uuid}</span>
                            }
                        </div>
                        <div className={styles.credential_content_item__5}>
                            {/* { data.disabled ? */}
                            <Form ref={cre_ref.current[index]} initialValues={{credential_enable: !data.disabled}}>
                                <Form.Item  name="credential_enable" valuePropName="checked">
                                    <Switch defaultChecked={!data.disabled} onChange={(e) => {handleSwitchChange(e, data.client_name, index)}}/>
                                </Form.Item>
                            </Form>
                            {/* <Switch defaultValue={!data.disabled} onChange={(e) => handleSwitchChange(e, data.client_name, data.disabled, index)}/> */}
                                {/* <div className={styles.credential_status} style={{background:"#f5acac", color:"red"}}>
                                    Disabled
                                </div> 
                                :
                                <div className={styles.credential_status} style={{background:"#78e178", color:"#4e8f4e"}}>
                                    Enabled
                                </div> 
                            } */}
                        </div>
                        <div className={styles.credential_content_item__25__center}>
                            <span>{new Date(data.expired_at).toLocaleDateString('sv') + " " + new Date(data.expired_at).toLocaleTimeString('sv')} </span>
                        </div>
                        <div className={styles.credential__action__btn__group}>
                            <div className={styles.credential__download__btn}
                                onClick={(e) => {e.stopPropagation(); setIsOnClick({click: "", index: ""})}}
                            >
                                <span>Download</span>
                            </div>
                            <div className={styles.credential__edit__btn}
                                onClick={(e) => {e.stopPropagation(); initModifyCredential(data.expired_at); openCredentialEditor(data, index)}}
                            >
                                <span>Edit</span>
                            </div>
                            <div className={styles.credential__delete__btn}
                                onClick={(e) => {e.stopPropagation(); showCredentialDeleteConfirm(data, data.id, index);}}
                            >
                                <span>Delete</span>
                            </div>
                        </div>
                        
                        {/* { isOnClick.click === "bind" && isOnClick.index === index ?
                        <span>
                            <input id="device_uuid" style={{width: '18.5vw'}} placeholder="Device UUID" onChange={e => newBindingUuid = e.target.value}/>
                        </span>
                        :
                        <span>{data.bmc_lite_uuid}</span>
                        } */}
                        {/* <span>
                            <BindStatusIcon status={data.is_bound} />
                        </span> */}
                        {/* <span>
                            { data.disabled ?
                            <div className={styles.credential_status} style={{background:"#f5acac", color:"red"}}>
                                Disabled
                            </div> 
                            :
                            <div className={styles.credential_status} style={{background:"#78e178", color:"#4e8f4e"}}>
                                Enabled
                            </div> 
                            }
                        </span> */}
                        { isOnClick.click === "edit" && isOnClick.index === index &&
                        <>
                        <span style={{display: 'flex'}}>
                            <div style={{width: '31%', marginRight: '4%'}}>
                                <DatePicker 
                                    id="edit_credential_date_picker"
                                    className={styles.date_picker}
                                    dateFormat="yyyy-MM-dd"
                                    selected={startDate}
                                    onChange={(date) => {
                                        setStartDate(new Date(date))
                                        newCredentialData.expired_date = date.toLocaleDateString('sv');
                                    }}
                                />
                            </div>
                            <select id="edit_credential_timezone" style={{width: '65%', fontSize: '0.9vw'}} defaultValue="" name="timezone" onChange={e => newCredentialData.timezone = e.target.value}>
                            <option value="" disabled>Select Timezone</option>
                            { allTimezone.map((data) => {
                                return <option value={data} key={data}>{data}</option>
                            })}
                            </select>
                        </span>
                        <span>
                            <YesButton onClick={() => modifyCredential(data.client_name)}/>
                            <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                            {actionMsg}
                        </span>
                        <div className={styles.credential_data_buttom_box}>
                            <div style={{fontWeight: 'bold', fontSize: '1vw', marginTop: '1%'}}>New Password</div>
                            <input id="change_credential_password" style={{width: '20%', margin: '1% 0'}} placeholder="Credential Password" onChange={e => newCredentialData.client_secret = e.target.value}/>
                        </div>
                        
                        </>
                        }
                        { isOnClick.click === "delete" && isOnClick.index === index &&
                        <>
                        <span>{new Date(data.expired_at).toLocaleDateString('sv') + " " + new Date(data.expired_at).toLocaleTimeString('sv')} </span>
                        
                        <span>
                            <YesButton onClick={() => deleteCredential(data.client_name, index)} />
                            <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                            {actionMsg}
                        </span>
                        </>
                        }
                        { isOnClick.click === "bind" && isOnClick.index === index && 
                        <>
                        <span>{new Date(data.expired_at).toLocaleDateString('sv') + " " + new Date(data.expired_at).toLocaleTimeString('sv')} </span>
                        <span>
                            <YesButton onClick={() => bindCredential(data.client_name)} />
                            <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                            {actionMsg}
                        </span>
                        </>
                        }
                        { isOnClick.index !== index &&
                        <>
                        {/* <span>{new Date(data.expired_at).toLocaleDateString('sv') + " " + new Date(data.expired_at).toLocaleTimeString('sv')} </span> */}
                        
                        {/* <span> */}
                            {/* <label title="Enable/Disable" className={styles.switch} onClick={() => data.disabled ? enableCredential(data.client_name, index) : disableCredential(data.client_name, index)}>
                                <input type="checkbox" readOnly checked={data.disabled ? false : true }/>
                                <div className={styles.slider}></div>
                            </label> */}
                            {/* <CredentialEnableButton status={data.disabled} onClick={() => data.disabled ? enableCredential(data.client_name, index) : disableCredential(data.client_name, index)} /> */}
                            {/* <DownloadButton onClick={() => downloadCredential(data.client_name)}/>
                            <BindButton onClick={() => {setIsOnClick({click: "bind", index: index}); setActionMsg(<div>Bind Device</div>); newBindingUuid = null}}/>
                            <ModifyButton onClick={() => {initModifyCredential(data.expired_at); setIsOnClick({click: "edit", index: index}); setActionMsg(<div>Modify</div>) }} />
                            <DeleteButton onClick={() => {setIsOnClick({click: "delete", index: index}); setActionMsg(<div className={styles.action_note}>Delete</div>)}}/> */}
                        {/* </span> */}
                        </>
                        }        
                    </div>
                )
                })}
                </div>
                </InfiniteScroll>
            }
            </div>

        </div>
    )
}

export default CredentialPage