import axios from "axios";

const hostname = window.location.hostname;
const port = window.location.port;
const hostname_regex = /^localhost+/;
const url = "https://iot.lannerinc.com"
// const url = hostname_regex.test(hostname) ? "http://192.168.5.123:8000" : `//${hostname}:${port}`;
export const apiUrl = url


const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

let authTokenRequest;

function getAuthToken() {
    if (!authTokenRequest) {
        authTokenRequest = apiLoginRefresh({
            refresh_token: JSON.parse(localStorage.getItem('refresh_token'))
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
        }})
        authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest)
    }
    return authTokenRequest;
}

function resetAuthTokenRequest() {
    authTokenRequest = null;
}

const handleErrorResponse = async (error) => {
    const originalRequest = error.config;
    if (error.config.url === "/refresh" && error.response.status === 401) {
        console.log('Refresh token get 401. User will be logged out');
        localStorage.clear()
        window.location.assign("/")
    }
    if (error.response.status === 401 && !originalRequest._retry) {
        return await getAuthToken()
        .then(res => {
            localStorage.setItem('access_token', JSON.stringify(res.data.access_token))
            localStorage.setItem('refresh_token', JSON.stringify(res.data.refresh_token))
            error.config.headers.Authorization = "bearer " + res.data.access_token
            originalRequest._retry = true
            return userRequest(error.config)
        }).catch(err => {
            if (err.response.status === 401) {
                console.log('Refresh token get 401. User will be logged out');
                localStorage.clear()
                window.location.assign("/")
            }
            return Promise.reject(err);
        })
    }
    throw error;
}


// Auth
const auth = axios.create({
    baseURL: `${url}/api/auth`,
    headers: {'the-timezone-iana': timezone}
})
auth.interceptors.response.use(null,
    async (error) => {
        return await handleErrorResponse(error)
    }
)

// Auth api
// Login
export const apiPasswordRecoverMail = (mail, data) => auth.post(`/password-recovery/${mail}`, data)
export const apiPasswordReset = (data) => auth.post('/reset-password', data)
export const apiLogin = (data, headers) => auth.post('/login', data, headers)
export const apiLogout = (data, headers) => auth.post('/logout', data, headers)
export const apiLoginRefresh = (data, headers) => auth.post('/refresh', data, headers)


// Admin api
const adminRequest = axios.create({
    baseURL: `${url}/api/admin`,
    headers: {'the-timezone-iana': timezone}
})
adminRequest.interceptors.response.use(null,
    async (error) => {
        console.log(error)
        return await handleErrorResponse(error)
    }
)

// Admin api
// Dashboard

// Users
export const apiAdminGetUsers = (headers, start_num, end_num, search_keyword, enable_user, company_id) => adminRequest.get('/users',
    {
        headers: headers,
        params: {
            keyword: search_keyword,
            is_enabled: enable_user,
            sort_by: "created_at",
            company_id: company_id,
            skip: start_num,
            limit: end_num
        }
    } )
export const apiAdminGetUserLogs = (headers, user_id, logsParams) => 
{
    const params = new URLSearchParams({
        start_dt: logsParams.start_dt,
        end_dt: logsParams.end_dt,
        sort_by: logsParams.sort_by,
        skip: logsParams.skip,
        limit: logsParams.limit
    })
    logsParams.category && params.append('category', logsParams.category)
    logsParams.keyword && params.append('keyword', logsParams.keyword)
    Array(logsParams.levels)[0].forEach(value => params.append('levels', value))

    return adminRequest.get(`/users/${user_id}/logs`, 
        {
            headers: headers,
            params: params
        } 
    )
}
export const apiAdminDownloadUserLogs = (headers, user_id, logsParams) => {
    const params = new URLSearchParams({
        format: logsParams.format,
        start_dt: logsParams.start_dt,
        end_dt: logsParams.end_dt,
        sort_by: logsParams.sort_by,
        skip: logsParams.skip,
        limit: logsParams.limit
    })
    logsParams.category && params.append('category', logsParams.category)
    logsParams.keyword && params.append('keyword', logsParams.keyword)
    Array(logsParams.levels)[0].forEach(value => params.append('levels', value))
    return adminRequest.get(`/users/${user_id}/logs/export`,
        {
            headers: headers,
            responseType: 'blob',
            params: params
        }
    )
}
export const apiAdminGetUser = (headers, user_id) => adminRequest.get(`/users/${user_id}`, headers)
export const apiAdminCreateUser = (headers, data) => adminRequest.post('/users', data, headers)
export const apiAdminModifyUser = (headers, data, user_id) => adminRequest.patch(`/users/${user_id}`, data, headers)
export const apiAdminDeleteUser = (headers, user_id) => adminRequest.delete(`/users/${user_id}`, headers)
export const apiAdminDisableUser = (headers, user_id) => adminRequest.put(`/users/${user_id}/disable`, '', headers)
export const apiAdminEnableUser = (headers, user_id) => adminRequest.put(`/users/${user_id}/enable`, '', headers)
export const apiAdminChangeUserPassword = (headers, data, user_id) => adminRequest.put(`/users/${user_id}/password`, data, headers)
export const apiAdminGetUserPermission = (headers, user_id) => adminRequest.get(`/users/${user_id}/permissions`, headers)
export const apiAdminSetUserPermission = (headers, user_id, data) => adminRequest.put(`/users/${user_id}/permissions`, data, headers)
export const apiAdminGetUserSessions = (headers, user_id) => adminRequest.get(`/users/${user_id}/sessions?sort_by=last_activity_desc`, headers)
export const apiAdminClearUserSession = (headers, user_id) => adminRequest.delete(`/users/${user_id}/sessions`, headers)
// Companies
export const apiAdminGetCompanies = (headers, start_num, end_num, search_keyword) => adminRequest.get('/companies',
    { 
        headers: headers,
        params: {
            keyword: search_keyword,
            sort_by: "name",
            skip: start_num,
            limit: end_num
        }
    } )
export const apiAdminCreateCompany = (headers, data) => adminRequest.post('/companies', data, headers)
export const apiAdminModifyCompany = (headers, data, company_id) => adminRequest.patch(`/companies/${company_id}`, data, headers)
export const apiAdminDeleteCompany = (headers, company_id) => adminRequest.delete(`/companies/${company_id}`, headers)
// Devices (BMC lites)
// export const apiAdminGetDevices = (headers, start_num, end_num, search_keyword, company_id, model_id, connected) => adminRequest.get('/bmc-lites', 
//     {
//         headers: headers,
//         params: {
//             keyword: search_keyword,
//             is_broker_connected: connected,
//             company_id: company_id,
//             bmc_lite_model_id: model_id,
//             sort_by: "created_at_desc",
//             skip: start_num,
//             limit: end_num
//         }
//     })
export const apiAdminGetDevices = (headers, params) => adminRequest.get('/bmc-lites', 
    {
        headers: headers,
        params: params
    })
export const apiAdminGetDevice = (headers, uuid) => adminRequest.get(`/bmc-lites/${uuid}`, headers)
export const apiAdminCreateDevice = (headers, data) => adminRequest.post('/bmc-lites', data, headers)
export const apiAdminModifyDevice = (headers, data, uuid) => adminRequest.patch(`/bmc-lites/${uuid}`, data, headers)
export const apiAdminDeleteDevice = (headers, uuid) => adminRequest.delete(`/bmc-lites/${uuid}`, headers)
export const apiAdminGetDeviceModels = (headers, params) => adminRequest.get('/bmc-lites/models', 
    {   
        headers: headers,
        params: params
    } )
// export const apiAdminGetModelVersions = (headers, uuid) => adminRequest.get(`/bmc-lites/version/${}`, headers)
export const apiAdminCreateDeviceModels = (headers, data) => adminRequest.post(`/bmc-lites/models`, data, headers)
export const apiAdminDeleteDeviceModel = (headers, model_id) => adminRequest.delete(`/bmc-lites/models/${model_id}`, headers)
export const apiAdminModifyDeviceModel = (headers, data, model_id) => adminRequest.patch(`/bmc-lites/models/${model_id}`, data, headers)
export const apiAdminGetDeviceFiles = (headers, params) => adminRequest.get('/bmc-lites/versions', 
    {
        headers: headers,
        params: params
    })
export const apiAdminUploadDeviceFiles = (headers, data) => adminRequest.post(`/bmc-lites/versions`, data, headers)
export const apiAdminGetVersionDetail = (headers, file_id) => adminRequest.get(`/bmc-lites/versions/${file_id}`, headers)
export const apiAdminDeleteDeviceFiles = (headers, file_id) => adminRequest.delete(`/bmc-lites/versions/${file_id}`, headers)
export const apiAdminModifyDeviceFiles = (headers, data, file_id) => adminRequest.patch(`/bmc-lites/versions/${file_id}`, data, headers)
export const apiAdminBindDevice = (headers, data, uuid) => adminRequest.put(`/bmc-lites/${uuid}/credential`, data, headers)
// Credentials
export const apiAdminGetCredentials = (headers, search_keyword, is_bound, expired) => adminRequest.get('/credentials', 
    {
        headers: headers,
        params: {
            keyword: search_keyword,
            is_bound: is_bound,
            expired: expired,
            sort_by: 'name',
            skip: 0,
            limit: 100
        }
    })
export const apiAdminCreateCredential = (headers, data) => adminRequest.post('/credentials', data, headers)
export const apiAdminModifyCredential = (headers, data, cred_name) => adminRequest.patch(`/credentials/${cred_name}`, data, headers)
export const apiAdminEnableCredential = (headers, cred_name) => adminRequest.put(`/credentials/${cred_name}/enable`, '', headers)
export const apiAdminDisableCredential = (headers, cred_name) => adminRequest.put(`/credentials/${cred_name}/disable`, '', headers)
export const apiAdminDownloadCredential = (headers, cred_name) => adminRequest.get(`/credentials/${cred_name}/download`, headers)
export const apiAdminDeleteCredential = (headers, cred_name) => adminRequest.delete(`/credentials/${cred_name}`, headers)
// Statistics
export const apiAdminGetStatistics = (headers) => adminRequest.get(`/statistics`, headers)
export const apiAdminGetMQTTBroker = (headers) => adminRequest.get(`/mqtt/broker/info`, headers)

// Normal User api
const userRequest = axios.create({
    baseURL: `${url}/api`,
    headers: {'the-timezone-iana': timezone}
})
userRequest.interceptors.response.use(null,
    async (error) => {
        return await handleErrorResponse(error)
    }
)

// Normal User api

// Users
export const apiGetUserMe = (headers) => userRequest.get('/users/me', headers)
export const apiGetMeSessions = (headers) => userRequest.get('/users/me/sessions', 
    {
        headers: headers,
        params: {
            sort_by: 'last_activity_desc'
        }
    })
export const apiGetUserMeLogs = (headers, logsParams) => {
    const params = new URLSearchParams({
        start_dt: logsParams.start_dt,
        end_dt: logsParams.end_dt,
        sort_by: logsParams.sort_by,
        skip: logsParams.skip,
        limit: logsParams.limit
    })
    logsParams.category && params.append('category', logsParams.category)
    logsParams.keyword && params.append('keyword', logsParams.keyword)
    Array(logsParams.levels)[0].forEach(value => params.append('levels', value))

    return userRequest.get('/users/me/logs', 
        {
            headers: headers,
            params: params
        } 
    )
}
export const apiAdminDownloadUserMeLogs = (headers, logsParams) => {
    const params = new URLSearchParams({
        format: logsParams.format,
        start_dt: logsParams.start_dt,
        end_dt: logsParams.end_dt,
        sort_by: logsParams.sort_by,
        skip: logsParams.skip,
        limit: logsParams.limit
    })
    logsParams.category && params.append('category', logsParams.category)
    logsParams.keyword && params.append('keyword', logsParams.keyword)
    Array(logsParams.levels)[0].forEach(value => params.append('levels', value))
    return userRequest.get('/users/me/logs/export',
        {
            headers: headers,
            responseType: 'blob',
            params: params
        }
    )
}
export const apiClearMeSessions = (headers) => userRequest.delete('/users/me/sessions', headers)
export const apiDeleteMeSession = (headers, session_id) => userRequest.delete(`/users/me/sessions/${session_id}`, headers)
export const apiModifyUserMe = (headers, data) => userRequest.patch('/users/me', data, headers)
export const apiChangeUserMePassword = (headers, data) => userRequest.put('/users/me/password', data, headers)
export const apiGetUsers = (headers, start_num, end_num, search_keyword, enable_user) => userRequest.get('/users',
    {
        headers: headers,
        params: {
            keyword: search_keyword,
            is_enabled: enable_user,
            sort_by: "created_at",
            skip: start_num,
            limit: end_num
        }
    })

export const apiModifyUser = (headers, data, user_id) => userRequest.patch(`/users/${user_id}`, data, headers)
export const apiCreateUser = (headers, data) => userRequest.post('/users', data, headers)
export const apiDeleteUser = (headers, user_id) => userRequest.delete(`/users/${user_id}`, headers)
export const apiEnableUser = (headers, user_id) => userRequest.put(`/users/${user_id}/enable`, "", headers)
export const apiDisableUser = (headers, user_id) => userRequest.put(`/users/${user_id}/disable`, "", headers)
export const apiGetUserPermission = (headers, user_id) => userRequest.get(`/users/${user_id}/permissions`, headers)
export const apiSetUserPermission = (headers, user_id, data) => userRequest.put(`/users/${user_id}/permissions`, data, headers)
export const apiChangeUserPassword = (headers, data, user_id) => userRequest.put(`/users/${user_id}/password`, data, headers)
export const apiGetUserSessions = (headers, user_id) => userRequest.get(`/users/${user_id}/sessions`, headers)
export const apiClearUserSession = (headers, user_id) => userRequest.delete(`/users/${user_id}/sessions`, headers)
export const apiGetUserLogs = (headers, user_id, logsParams) => 
{
    const params = new URLSearchParams({
        start_dt: logsParams.start_dt,
        end_dt: logsParams.end_dt,
        sort_by: logsParams.sort_by,
        skip: logsParams.skip,
        limit: logsParams.limit
    })
    logsParams.category && params.append('category', logsParams.category)
    logsParams.keyword && params.append('keyword', logsParams.keyword)
    Array(logsParams.levels)[0].forEach(value => params.append('levels', value))

    return userRequest.get(`/users/${user_id}/logs`, 
        {
            headers: headers,
            params: params
        } 
    )
}
export const apiDownloadUserLogs = (headers, user_id, logsParams) => {
    const params = new URLSearchParams({
        format: logsParams.format,
        start_dt: logsParams.start_dt,
        end_dt: logsParams.end_dt,
        sort_by: logsParams.sort_by,
        skip: logsParams.skip,
        limit: logsParams.limit
    })
    logsParams.category && params.append('category', logsParams.category)
    logsParams.keyword && params.append('keyword', logsParams.keyword)
    Array(logsParams.levels)[0].forEach(value => params.append('levels', value))
    return userRequest.get(`/users/${user_id}/logs/export`,
        {
            headers: headers,
            responseType: 'blob',
            params: params
        }
    )
}

// Devices (BMC lites)
// export const apiGetDevices = (headers, start_num, end_num, search_keyword, connected) => userRequest.get('/bmc-lites', 
//     {
//         headers: headers,
//         params: {
//             keyword: search_keyword,
//             is_broker_connected: connected,
//             sort_by: "created_at_desc",
//             skip: start_num,
//             limit: end_num
//         }
//     })
export const apiGetDevices = (headers, params) => userRequest.get('/bmc-lites', 
    {
        headers: headers,
        params: params
    })
export const apiGetDevice = (headers, uuid) => userRequest.get(`/bmc-lites/${uuid}`, headers)
export const apiCreateDevice = (headers, data) => userRequest.post('/bmc-lites', data, headers)
export const apiModifyDevice = (headers, data, uuid) => userRequest.patch(`/bmc-lites/${uuid}`, data, headers)
export const apiGetDeviceMonitor = (headers, uuid) => userRequest.get(`/bmc-lites/${uuid}/monitor`, headers)
export const apiGetBmcLiteInfo = (headers, uuid) => userRequest.get(`/bmc-lites/${uuid}/info`, headers)
export const apiPowerOnDevice = (headers, uuid) => userRequest.put(`/bmc-lites/${uuid}/power/on`, "", headers)
export const apiPowerOffDevice = (headers, uuid) => userRequest.put(`/bmc-lites/${uuid}/power/off`, "", headers)
export const apiRebootDevice = (headers, uuid) => userRequest.put(`/bmc-lites/${uuid}/power/reboot`, "", headers)
export const apiUpgradeBmcLite = (headers, uuid) => userRequest.post(`/bmc-lites/${uuid}/firmware/update`, "", headers)
export const apiGetMonitorHistory = (headers, uuid, params) => userRequest.get(`/bmc-lites/${uuid}/feeds/monitoring`,
    { 
        headers: headers, 
        params: params
    }
)
export const apiGetInfoHistory = (headers, uuid, params) => userRequest.get(`/bmc-lites/${uuid}/feeds/info`,
    { 
        headers: headers, 
        params: params
    }
)
export const apiGetPowerHistory = (headers, uuid, params) => userRequest.get(`/bmc-lites/${uuid}/feeds/monitoring/power`,
    { 
        headers: headers, 
        params: params
    }
)
export const apiGetConnectionHistory = (headers, uuid, params) => userRequest.get(`/bmc-lites/${uuid}/feeds/connection`,
    { 
        headers: headers, 
        params: params
    }
)
export const apiGetAlertRecords = (headers, uuid, params) => userRequest.get(`/bmc-lites/${uuid}/feeds/alert`,
    { 
        headers: headers, 
        params: params
    }
)
export const apiGetScheduledControlRecords = (headers, uuid, params) => userRequest.get(`/bmc-lites/${uuid}/feeds/scheduled-power-control`,
    { 
        headers: headers, 
        params: params
    }
)
export const apiGetDeviceControlSchedule = (headers, uuid) => userRequest.get(`/bmc-lites/${uuid}/schedules`, headers)
export const apiCreateDeviceControlSchedule = (headers, data, uuid) => userRequest.post(`/bmc-lites/${uuid}/schedules`, data, headers)
export const apiDeleteDeviceControlSchedule = (headers, uuid, schedule_id) => userRequest.delete(`/bmc-lites/${uuid}/schedules/${schedule_id}`, {headers: headers})
// Statistics
export const apiGetStatistics = (headers) => userRequest.get(`/statistics`, headers)



// System
export const apiGetTimezone = (headers) => userRequest.get('/system/timezones',
    {
        headers: headers
    })

