import { useEffect, useState } from "react";
import { 
  apiGetUsers, 
  apiModifyUser, 
  apiCreateUser, 
  apiDeleteUser, 
  apiEnableUser,
  apiDisableUser,
  apiSetUserPermission,
  apiGetUserPermission,
  apiChangeUserPassword,
  apiGetUserSessions,
  apiClearUserSession } from "../../../api";
import { SearchBar } from "../../../components/SearchBar";
import InfiniteScroll from "react-infinite-scroll-component";
import { 
  CalendarIcon,
  DeleteButton,
  ExportHtmlButton,
  ExportCsvButton,
  ModifyButton, 
  NoButton, 
  PasswordButton, 
  PermissionButton, 
  SessionButton,
  UserActiveButton,
  UserLogButton,
  YesButton } from '../../../components/ActionButton';
import { PageLoader } from "../../../components/Loader";
import styles from '../../Admin/Users/AdminUser.module.scss';
import { UserLogsPage } from "../../../components/UserLogsPage";

let activeUser = undefined,
    hasMoreScroll = true,
    companyId = undefined,
    setPermit = [],
    delayTime = 0

const UserPage = (props) => {
    const accessToken = JSON.parse(localStorage.getItem('access_token'))
    const [ usersData, setUsersData ] = useState([])
    const [ isOnClick, setIsOnClick ] = useState({click: "", index: ""})
    const [ actionMsg, setActionMsg ] = useState("")
    const [ num, setNum ] = useState(10)
    const [ total, setTotal ] = useState()
    const [ newUserData, setNewUserData ] = useState({
        full_name: "",
        description: "",
        email: "",
        password: "",
        phone_number: null,
        send_welcome_email: false,
        company_id: ""
    }) 
    const [ searchWords, setSearchWords ] = useState()
    
    
    useEffect(() => {
      const getUsers = async (keywords) => {
        await apiGetUsers({
              'Authorization': `Bearer ${accessToken}`
          },
          0,
          10,
          keywords
        )
        .then(res => {
          setUsersData(res.data.data)
          setTotal(res.data.metadata.total)
          if (10 >= total) {
            hasMoreScroll = false
          } else {
            hasMoreScroll = true
          }
        })
        .catch(err => {
          // console.log('Get users data failed.');
        })
      }
      
      getUsers()
      return () => {
        activeUser = undefined
        hasMoreScroll = true
        companyId = undefined
        setPermit = []
        delayTime = 0
      }
      
    },[accessToken, total])


    const nextScrollData = () => {
      setNum(num + 10)
      if (num >= total) {
        hasMoreScroll = false
        return
      } else {
        hasMoreScroll = true
      }
      const scrollGetMoreUsers = async () => {
        apiGetUsers({
              'Authorization': `Bearer ${accessToken}`
          },
          num,
          10,
          searchWords
        )
        .then(res => {
          setUsersData(usersData.concat(res.data.data))
          if (num >= res.data.metadata.total) {
            hasMoreScroll = false
          } else {
            hasMoreScroll = true
          }
        })
        .catch(err => {
          // console.log('Get users data failed.');
        })
      }
      scrollGetMoreUsers()
    }

    const [ userSessions, setUserSessions ] = useState()
    const getUserSessions = async (user_id) => {
      setActionMsg(<div className={styles.action_note}>Clear Sessions</div>)
      setUserSessions(null)
      await apiGetUserSessions({
        headers:{
          'Authorization': `Bearer ${accessToken}`
        }},
        user_id
      ).then(res => {
        setUserSessions(res.data.data)
      }).catch(err => {
        setActionMsg(<div className={styles.action_note_err} >{err.response.data.detail}</div>)
        setUserSessions([])
      })
    } 

    const clearUserSession = async (user_id, index) => {
      await apiClearUserSession({
        headers:{
          'Authorization': `Bearer ${accessToken}`
        }},
        user_id
      ).then(res => {
        setIsOnClick({click: "", index: ""})
      }).catch(err => {
        setActionMsg(<div className={styles.action_note_err} >{err.response.data.detail}</div>)
      })
    }

    const handleEnableUserOnClick = (user_id, index) => {
      apiEnableUser({
        headers:{
          'Authorization': `Bearer ${accessToken}`
        }},
        user_id
      ).then(res => {
        usersData[index] = {...usersData[index],
          is_enabled: true}
        setUsersData([...usersData])
      }).catch(err => {

      })
    }

    const handleDisableUserOnClick = (user_id, index) => {
      apiDisableUser(
      {
        headers:{
          'Authorization': `Bearer ${accessToken}`
        }
      },
      user_id
      ).then(res => {
        usersData[index] = {...usersData[index],
        is_enabled: false}
        setUsersData([...usersData])
      }).catch(err => {
        
      })
    }

    const initAddUser = () => {
      setActionMsg("")
      setPermit = []
      setNewUserData({
        full_name: "",
        description: "",
        phone_number: null,
        company_id: "",
        send_welcome_email: false,
        password: "",
        company_id: ""
      })
      setIsOnClick({click: "add", index: ""})
    }

    const initModifyUser = (data, index) => {
      setActionMsg(<div className={styles.action_note}>Modify</div>)
      setNewUserData({
        full_name: data.full_name,
        description: "",
        email: data.email,
        phone_number: data.phone_number === "" ? null : data.phone_number,
        company_id: data.company_id,
      })
      setIsOnClick({click: "edit", index: index})
    }

    const handleEditOnChange = (e) => {
      const value = e.target.name === 'phone_number' && e.target.value === "" ? null : e.target.value
      setNewUserData({
        ...newUserData,
        [e.target.name]: value
      })
    }    
    
    const deleteUser = (user_id, index) => {
      const DeleteUser = async () => {
        await apiDeleteUser(
          {
            headers:{
              'Authorization': `Bearer ${accessToken}`
            }
          },
          user_id
        )
        .then(res => {
          usersData.splice(index, 1)
          setIsOnClick({click: "", index: ""})
        })
        .catch(err => {
          // console.log('Delete user failed.');
        })
      }
      DeleteUser()
    }
    
    const addUser = async () => {
      await apiCreateUser({
        headers:{
          'Authorization': `Bearer ${accessToken}`
        }},
        newUserData
      )
      .then(res => {
        setUsersData(usersData.concat(res.data))
        setIsOnClick({click: "", index: ""})
        if (setPermit.length !== 0) {
          setUserPermissions(res.data.id, setPermit)
        }
      })
      .catch(err => {
        // console.log('Add user failed, check data is valid or existing.')
        setActionMsg("Add user failed, check data is valid or existing")
      })
    }


    const setUserPermissions = async (id, data) => {
      await apiSetUserPermission({
        headers:{
          'Authorization': `Bearer ${accessToken}`
        }},
        id,
        data
        ).then(res => {
          setIsOnClick({click: "", index: ""})
        }).catch(err => {
          setActionMsg(<div className={styles.action_note_err} >{err.response.data.detail}</div>)
        })
    }

    const submitEditUserData = (user_id) => {
      const UpdateUser = async () => {
        await apiModifyUser({
          headers:{
            'Authorization': `Bearer ${accessToken}`
          }},
          newUserData,
          user_id
        )
        .then(res => {
          usersData[isOnClick.index] = {...usersData[isOnClick.index],
            full_name: res.data.full_name,
            email: res.data.email,
            company_id: res.data.company_id,
            company_name: res.data.company_name,
            is_enabled: res.data.is_enabled
          }
          
          setIsOnClick({click: "", index: ""})
        })
        .catch(err => {
          // console.log('Edit user failed, check data is valid or existing.')
          setActionMsg(<div className={styles.action_note_err} >{err.response.data.detail}</div>)
        })
      }
      UpdateUser()
    }
    
    // const [ backupData, setBackupData ] = useState("")
    const getAllUsers = async (keywords) => {
      await apiGetUsers({
        'Authorization': `Bearer ${accessToken}`
        },
        0,
        1000,
        keywords,
        activeUser,
        companyId
      ).then(res => {
        setUsersData(res.data.data)
        hasMoreScroll = false
      }).catch(err => {
        setUsersData()
      })
      delayTime = 0
    }
    
    const handleAllUsersOnClick = () => {
      activeUser = undefined
      getAllUsers()
      document.getElementById("search_input").value = ""
      setIsOnClick({click: "", index: ""})
    }
    
    const handleFilterActiveUsersOnClick = (bool) => {
      activeUser = bool
      getAllUsers()
      setIsOnClick({click: "", index: ""})  
      document.getElementById("search_input").value = ""
    }
    

    useEffect(() => {
      if (searchWords) {
        const searchTimeout = setTimeout(() => {
          getAllUsers(searchWords)
        }, delayTime);
        return () => clearTimeout(searchTimeout)
      }
    }, [searchWords])

    const handleSearchUsersOnChange = (e) => {
      delayTime = 500
      const value = e.target.value
      setSearchWords(value)
      if (!value) {
        getAllUsers()
      }
      setIsOnClick({click: "", index: ""})
    }

    let newPassword
    const handleNewPasswordOnChange = (e) => {
      newPassword = e.target.value
    }

    const submitChangePassword = (data, user_id) => {
      apiChangeUserPassword(
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        },
        {
          new_password: data
        },
        user_id
      ).then(res => {
        setIsOnClick({click: "", index: ""})
      }).catch(err => {
        if (err.response.status === 422 ) {
          setActionMsg(<div className={styles.action_note_err} >Invalid password format</div>)
        } else {
          setActionMsg(<div className={styles.action_note_err} >{err.response.data.detail}</div>)
        }
      })
    }

    let scroll_height = "77vh"
    const setHeight = (e) => {
      scroll_height = e
    }

    const handlePermissionOnChange = (e) => {
      const value = parseInt(e.target.value)
      if (e.target.checked) {
        setPermit.push(value)
      } else {
        setPermit.splice(setPermit.indexOf(value), 1)
      }
    }

    const [ userPermission, setUserPermission ] = useState()
    const getUserPermission = async (id) => {
      setPermit = []
      setUserPermission(null)
      await apiGetUserPermission({
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      },
      id
      ).then(res => {
        setUserPermission(res.data.data)
        res.data.data.map(data => {
          setPermit.push(data.id) 
        })
      }).catch(err => {
        setUserPermission([])
      })
    }

    const formatDate = (date) => {
        return new Date(date).toLocaleString('sv')
    }

    const changeUserData = (data, type) => {
      switch (type) {
        case "edit":
          return (
            <>
            <span>
              <input style={{width: "90%"}} placeholder="User Name" name="full_name" defaultValue={data.full_name} onChange={handleEditOnChange}/>
            </span>
            <span>
              <input autoComplete="off" style={{width: "90%"}} placeholder="E-Mail" type="email" name="email" defaultValue={data.email} onChange={handleEditOnChange}/>
            </span>
            <span>{props.me.company_name}</span>
            <span>
              { data.is_enabled ? 
              <div className={styles.user_status} style={{background:"#78e178", color:"#4e8f4e"}}>
                Active
              </div> 
              : 
              <div className={styles.user_status} style={{background:"#f5acac", color:"red"}}>
                Inactive
              </div>
              }
            </span>
            <span>
              <input style={{width: "90%"}} placeholder="+Country code is required" name="phone_number" defaultValue={data.phone_number}  onChange={handleEditOnChange}/>
            </span>
            <span>
              <YesButton onClick={() => submitEditUserData(data.id)}/>
              <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
              {actionMsg}
            </span>
            </>
          )
        case "password":
          return (
            <>
              <span>{data.full_name}</span>
              <span>{data.email}</span>
              <span>{props.me.company_name}</span>
              <span>
                { data.is_enabled ? 
                <div className={styles.user_status} style={{background:"#78e178", color:"#4e8f4e"}}>
                  Active
                </div> 
                : 
                <div className={styles.user_status} style={{background:"#f5acac", color:"red"}}>
                  Inactive
                </div>}
              </span>
              <span>{data.phone_number}</span>
              <span>
                <YesButton onClick={() => submitChangePassword(newPassword, data.id)}/>
                <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                {actionMsg}
              </span>
  
            <div className={styles.user_data_bottom_box} >
              <div style={{fontWeight: 'bold', fontSize: '1vw', marginTop: '1%'}}>New Password</div>
              <input style={{width: '20%', marginTop: '1%'}} id="new_password" placeholder="At least 8 chracters" onChange={handleNewPasswordOnChange} />
            </div>
            </>
          )
        case "permission":
          return (
            <>
            { userPermission ? 
            <>
            <span>{data.full_name}</span>
            <span>{data.email}</span>
            <span>{props.me.company_name}</span>
            <span>
              { data.is_enabled ? 
              <div className={styles.user_status} style={{background:"#78e178", color:"#4e8f4e"}}>
                Active
              </div> 
              : 
              <div className={styles.user_status} style={{background:"#f5acac", color:"red"}}>
                Inactive
              </div>}
            </span>
            <span>{data.phone_number}</span>

            <span>
              <YesButton onClick={() => setUserPermissions(data.id, setPermit)}/>
              <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
              {actionMsg}
            </span>
  
            <div className={styles.user_data_bottom_box} >
              <div style={{fontWeight: 'bold', fontSize: '1vw', marginTop: '1%'}}>Current Permission</div>
              <label className={styles.checkbox_label} htmlFor="user_read">
                <input value="4" defaultChecked={setPermit.includes(4) ? 'checked' : false} className={styles.edit_checkbox} id="user_read" type="checkbox" onChange={handlePermissionOnChange} />
                User Read： Read User
              </label>
              <label className={styles.checkbox_label} htmlFor="user_write">
                <input value="5" defaultChecked={setPermit.includes(5) ? 'checked' : false} className={styles.edit_checkbox} id="user_write" type="checkbox" onChange={handlePermissionOnChange} />
                User Write： Create/Update/Delete/Enable/Disable User
              </label>
              <label className={styles.checkbox_label} htmlFor="device_read">
                <input value="6" defaultChecked={setPermit.includes(6) ? 'checked' : false} className={styles.edit_checkbox} id="device_read" type="checkbox" onChange={handlePermissionOnChange} />
                Device Read： Read BMC-Lite
              </label>
              <label className={styles.checkbox_label} htmlFor="device_write">
                <input value="7" defaultChecked={setPermit.includes(7) ? 'checked' : false} className={styles.edit_checkbox} id="device_write" type="checkbox" onChange={handlePermissionOnChange} />
                Device Write： Create/Update/Delete BMC-Lite
              </label>
              <label className={styles.checkbox_label} htmlFor="device_execute">
                <input value="8" defaultChecked={setPermit.includes(8) ? 'checked' : false} className={styles.edit_checkbox} id="device_execute" type="checkbox" onChange={handlePermissionOnChange} />
                Device Execute： Execute BMC-Lite Command
              </label>
            </div>
            </>
            :
            <>
            <span>{data.full_name}</span>
            <span>{data.email}</span>
            <span>{props.me.company_name}</span>
            <span>
              { data.is_enabled ? 
              <div className={styles.user_status} style={{background:"#78e178", color:"#4e8f4e"}}>
                Active
              </div> 
              : 
              <div className={styles.user_status} style={{background:"#f5acac", color:"red"}}>
                Inactive
              </div>}
            </span>
            <span>{data.phone_number}</span>

            <span>
              <div className={styles.loader_user}></div>
              <div className={styles.action_note}>
                  Get Permissions
              </div>
            </span>
            </>
            }
            </>
          )
        case "session": 
          return (
            <>
              { userSessions ? 
              <>
              <span>{data.full_name}</span>
              <span>{data.email}</span>
              <span>{props.me.company_name}</span>
              <span>
                { data.is_enabled ? 
                <div className={styles.user_status} style={{background:"#78e178", color:"#4e8f4e"}}>
                  Active
                </div> 
                : 
                <div className={styles.user_status} style={{background:"#f5acac", color:"red"}}>
                  Inactive
                </div>}
              </span>
              <span>{data.phone_number}</span>
              <span >
                {userSessions.length !== 0 &&
                <YesButton onClick={() => clearUserSession(data.id)}/>
                }
                <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                {actionMsg}
              </span>

              <div className={styles.user_data_bottom_box}>
                  <div style={{fontWeight: 'bold', fontSize: '1vw', marginTop: '1%'}}>Active Sessions</div>
                  <div className={styles.session_container}>
                  {userSessions.map((data, index) => {
                    return (
                    <div className={styles.session_box} key={index}>
                      <div>OS: {data.os}</div>
                      <div>Browser: {data.browser}</div>
                      <div>Device: {data.device}</div>
                      <div>IP: {data.ip}</div>
                      <div>First Login: {formatDate(data.created_at)}</div>
                      <div>Last Access: {formatDate(data.last_use_at)}</div>
                    </div>
                    )
                  })}
                  </div>
              </div>
              </>
              :
              <>
              <span>{data.full_name}</span>
              <span>{data.email}</span>
              <span>{props.me.company_name}</span>
              <span>
                { data.is_enabled ? 
                <div className={styles.user_status} style={{background:"#78e178", color:"#4e8f4e"}}>
                  Active
                </div> 
                : 
                <div className={styles.user_status} style={{background:"#f5acac", color:"red"}}>
                  Inactive
                </div>}
              </span>
              <span>{data.phone_number}</span>

              <span >
                  <div className={styles.loader_user}></div>
                  <div className={styles.action_note}>
                      Get Sessions
                  </div>
              </span>
              </>
              }
            </>
          )
      }
    }
  
 
    return (
        <>
        { isOnClick.click === "log" && 
        <UserLogsPage user_data={usersData[isOnClick.index]} back={setIsOnClick} />
        }
        <div className={styles.user_page} style={{display: isOnClick.click === "log" && 'none'}} >
          <SearchBar 
            name="Users"
            search={handleSearchUsersOnChange}
            all={handleAllUsersOnClick}
            filter={handleFilterActiveUsersOnClick}
            add={initAddUser}
          />
          <div className={styles.user_header}>
            <span>User</span>
            <span>E-mail</span>
            <span>Company</span>
            <span>Status</span>
            <span>Phone</span>
            <span>Action</span>
          </div>
          { isOnClick.click === "add" ? 
          <div className={styles.user_data} style={{marginBottom: '0'}}>
            {setHeight("54vh")}
            <div className={styles.edit_box}>
              <div className={styles.edit_box_2}>
                <label htmlFor="add_user_full_name">User Name</label>
                <input id="add_user_full_name" name="full_name" placeholder="User Name" onChange={handleEditOnChange}/>
              </div>
              <div className={styles.edit_box_2}>
                <label htmlFor="add_user_email">E-Mail</label>
                <input id="add_user_email" type="email" name="email" placeholder="E-Mail" onChange={handleEditOnChange}/>
              </div>
            </div>
            <div className={styles.edit_box}>
              <div className={styles.edit_box_2}>
                <label htmlFor="add_user_company_id">Company</label>
                <select id="add_user_company_id" defaultValue={props.me.company_id} disabled >
                  <option value={props.me.company_id}>{props.me.company_name}</option>
                </select>
              </div>
              <div className={styles.edit_box_2}>
                <label htmlFor="add_user_password">Password</label>
                <input id="add_user_password" name="password" placeholder="At least 8 chracters" onChange={handleEditOnChange}/>
              </div>
            </div>
            <div className={styles.edit_box}>
              <div className={styles.edit_box_2}>
                <label htmlFor="add_user_phone_number">Phone Number</label>
                <input id="add_user_phone_number" name="phone_number" placeholder="+Country code is required" onChange={handleEditOnChange}/>
              </div>
              <div className={styles.edit_box_2}>
                <label htmlFor="add_user_welcome_email">Welcome Mail</label>
                <select id="add_user_welcome_email" name="send_welcome_email" defaultValue="false" onChange={handleEditOnChange}>
                  <option value="true">Yes</option> 
                  <option value="false">No</option>
                </select>
              </div>
            </div>
            <div className={styles.edit_box} style={{padding: '0.8% 0'}}>
                  <span>Permission</span>
                    <label title="User Read" className={styles.checkbox_label} htmlFor="user_read">
                      <input title="User Read" value="4" className={styles.edit_checkbox} id="user_read" type="checkbox" onChange={handlePermissionOnChange} />
                      User Read
                    </label>
                    <label title="Create/Update/Delete/Enable/Disable User" className={styles.checkbox_label} htmlFor="user_write">
                      <input title="Create/Update/Delete/Enable/Disable User" value="5" className={styles.edit_checkbox} id="user_write" type="checkbox" onChange={handlePermissionOnChange} />
                      User Write
                    </label>
                    <label title="Read BMC-Lite" className={styles.checkbox_label} htmlFor="device_read">
                      <input title="Read BMC-Lite" value="6" className={styles.edit_checkbox} id="device_read" type="checkbox" onChange={handlePermissionOnChange} />
                      Device Read
                    </label>
                    <label title="Create/Update/Delete BMC-Lite" className={styles.checkbox_label} htmlFor="device_write">
                      <input title="Create/Update/Delete BMC-Lite" value="7" className={styles.edit_checkbox} id="device_write" type="checkbox" onChange={handlePermissionOnChange} />
                      Device Write
                    </label>
                    <label title="Execute BMC-Lite Command" className={styles.checkbox_label} htmlFor="device_execute">
                      <input title="Execute BMC-Lite Command" value="8" className={styles.edit_checkbox} id="device_execute" type="checkbox" onChange={handlePermissionOnChange} />
                      Device Execute
                    </label>
            </div>
            <div className={styles.edit_box} style={{marginLeft: '0'}} >
              <div className={styles.edit_box_2}>
                <div className={styles.edit_box_btn} onClick={() => addUser()}>ADD</div>
              </div>
              <div className={styles.edit_box_2}>
                <div className={styles.edit_box_btn} onClick={() => setIsOnClick({click: "", index: ""})}>CANCEL</div>
              </div>
            </div>
            <div className={styles.user_err_msg}>{actionMsg}</div>
          </div>
          : 
          setHeight("77vh")
          }

          <div className={styles.user_content}>
            { usersData && 
            <InfiniteScroll
                style={{"overflowY":"overlay", "overflowX":"hidden"}}
                dataLength={usersData.length} 
                next={nextScrollData}
                hasMore={hasMoreScroll}
                loader={<PageLoader />}
                // height={"78vh"}
                height={scroll_height}
                // endMessage={
                //   <p style={{ textAlign: 'center' }}>
                //     <b>NO MORE DATA</b>
                //   </p>
                // }
            >
              { usersData.map((data, index) => {
              return (
                <div key={data.id}>
                  <div className={styles.user_data} key={data.id}>
                  { (isOnClick.click === "edit" || isOnClick.click === "password" || isOnClick.click === "permission" || isOnClick.click === "session") && isOnClick.index === index ?
                  <>
                  { changeUserData(data, isOnClick.click)}
                  </>
                  :
                  <>
                    <span>{data.full_name}</span>
                    <span>{data.email}</span>
                    <span>{props.me.company_name}</span>
                    <span>
                      { data.is_enabled ? 
                      <div className={styles.user_status} style={{background:"#78e178", color:"#4e8f4e"}}>
                        Active
                      </div> 
                      : 
                      <div className={styles.user_status} style={{background:"#f5acac", color:"red"}}>
                        Inactive
                      </div>}
                    </span>
                    <span>{data.phone_number}</span>
                    { isOnClick.click === "delete" && isOnClick.index === index ?
                    <span>
                      <YesButton onClick={() => deleteUser(data.id, index)}/>
                      <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                      {actionMsg}
                    </span>
                    :
                    <span>
                      <UserActiveButton status={data.is_enabled} onClick={() => {data.is_enabled ? handleDisableUserOnClick(data.id, index) : handleEnableUserOnClick(data.id, index)}} />
                      <PermissionButton onClick={() => {setIsOnClick({click: "permission", index: index}); getUserPermission(data.id); setActionMsg(<div className={styles.action_note}>Change Permission</div>); }}/>
                      <SessionButton onClick={() => {setIsOnClick({click: "session", index: index}); getUserSessions(data.id);}}/>
                      <UserLogButton onClick={() => {setIsOnClick({click: "log", index: index})  }} />
                      <PasswordButton onClick={() => {setIsOnClick({click: "password", index: index}); setActionMsg(<div className={styles.action_note}>New Password</div>); newPassword=""; }}/>
                      <ModifyButton onClick={() => initModifyUser(data, index)}/>
                      <DeleteButton onClick={() => {setIsOnClick({click: "delete", index: index}); setActionMsg(<div className={styles.action_note}>Delete</div>)}}/>
                    </span>
                    }
                  </>
                  }
                  </div>
                </div>
              )
              })}
            </InfiniteScroll>}
          </div>
        </div>
        </>
      )
}
export default UserPage