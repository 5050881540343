import React, { useState, useEffect, useRef, useCallback } from "react";
import { 
    apiUrl,
    apiGetDevices,
    apiAdminGetDevices,
    apiGetDeviceMonitor,
    apiAdminGetDeviceModels,
    apiAdminGetCompanies, 
    apiAdminCreateDevice,
    apiAdminDeleteDevice,
    apiGetMonitorHistroy,
    apiGetBmcLiteInfo,
    apiPowerOnDevice,
    apiPowerOffDevice,
    apiRebootDevice,
    apiUpgradeBmcLite,
    apiAdminModifyDevice,
    apiGetDeviceControlSchedule,
    apiCreateDeviceControlSchedule,
    apiDeleteDeviceControlSchedule,
    apiGetPowerHistory,
    apiGetConnectionHistory } from "../../../api";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchBar } from "../../../components/SearchBar";
import { Link } from "react-router-dom";
import ReactECharts from "echarts-for-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from './AdminDevice.module.scss'
import { 
    DeleteButton, 
    ModifyButton, 
    HistoryButton,
    UpgradeButton,
    RebootButton,
    PowerButton, 
    YesButton,
    NoButton,
    CalendarIcon,
    ScheduleControlButton,
    AddScheduleButton,
    DeleteScheduleButton} from '../../../components/ActionButton';
import webSocket from 'socket.io-client';
import { PageLoader } from "../../../components/Loader";
import HistoryPage from "../../../components/HistoryPage";
import DeviceDetailPage from "../../../components/DeviceDetailPage";
import { Switch, Modal, notification } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
const { confirm } = Modal;


let hasMoreScroll = true, 
    delayTime = 0,
    reloadDataTime = 5000,
    uuidList = [],
    wsDevData,
    socket,
    newScheduleData = {},
    deviceParams = {
        keyword: null,
        is_broker_connected: null,
        company_id: null,
        bmc_lite_model_id: null,
        sort_by: "created_at_desc",
        skip: 0,
        limit: 1000
    }

let modelParams = {
    keyword: null,
    sort_by: 'name',
    skip: 0,
    limit: 1000
}

const DevicePage = ({me}) => {
    const accessToken = JSON.parse(localStorage.getItem('access_token'))
    const [ devicesData, setDevicesData ] = useState([])
    const [ isOnClick, setIsOnClick ] = useState({click: "", index: ""})
    const [ companiesData, setCompaniesData ] = useState()
    const [ modelsData, setModelsData ] = useState()
    const [ actionMsg, setActionMsg ] = useState("")
    const [ moreInfo, setMoreInfo ] = useState([])
    const [ monitorData, setMonitorData ] = useState({bmclite: "", device: ""})
    const [ latestVersion, setLatestVersion ] = useState([])
    const [ isHistoryFilterActive, setIsHistoryFilterActive ] = useState("oob")


    let userInfo = JSON.parse(localStorage.getItem('me'));
    const [ newDeviceData, setNewDeviceData ] = useState({
        name: "",
        uuid: "",
        company_id: "",
        description: "",
        bmc_lite_model_id: ""
    })

    const showNotification = (type, title, content) => {
        notification.open({
            type: `${type}`,
            message: `${title}`,
            description: `${content}`,
            className: 'bmc__notification'
        });
    };

    const [ searchWords, setSearchWords ] = useState()

    const connectWebSocket = async () => {
        // socket = webSocket('http://192.168.5.214:8000/bmc-lites',{
        socket = webSocket(`${apiUrl}/bmc-lites`, {
            auth: {
                token: accessToken,
            },
        })
    }

    const wsDataUpdate = (data) => {
        const index = wsDevData.findIndex((dev) => dev.uuid === data.uuid)
        wsDevData[index] = {...wsDevData[index], ...data } 
        setDevicesData([...wsDevData])
    }


    const initWebSocket = (uuid) => {
       
        socket.on('connect', () => {
            socket.emit('subscribe', uuid);
            console.log('device list connect')
        })
        console.log(socket)
        socket.on('monitor', (data) => {
            const index = wsDevData.findIndex((dev) => dev.uuid === data.uuid)
            data.monitor.is_power_on = wsDevData[index].monitor.is_power_on
            wsDataUpdate(data)
            // console.log('monitor', data);
        });

        socket.on('update_status', (data) => {
            wsDataUpdate(data)
            // console.log('update_status', data);
        });

        socket.on('monitor_indication', (data) => {
            wsDataUpdate(data)
            // console.log('monitor_indication', data);
        });

        socket.on('connection', (data) => {
            wsDataUpdate(data)
            // console.log('connection', data);
        })

        socket.on('info', (data) => {
            wsDataUpdate(data)
            // console.log('info', data);
        })

        socket.on('disconnect', () => {
            // console.log('disconnect');
        })
    }

    const getAllDevices = async () => {
        uuidList = []
        if(me && me.is_superuser) {
            await apiAdminGetDevices( {'Authorization': `Bearer ${accessToken}`}, deviceParams)
            .then(res => {
                setDevicesData(res.data.data)
                hasMoreScroll = false
                wsDevData = res.data.data
                    res.data.data.forEach(data => {
                        uuidList.push(data.uuid)
                    })
                if (!socket) {
                    connectWebSocket() 
                } 
            })
            .catch(err => {
                setDevicesData()
            })
        } else {
            await apiGetDevices( {'Authorization': `Bearer ${accessToken}`}, deviceParams)
            .then(res => {
                setDevicesData(res.data.data)
                hasMoreScroll = false
                wsDevData = res.data.data
                    res.data.data.forEach(data => {
                        uuidList.push(data.uuid)
                    })
                if (!socket) {
                    connectWebSocket() 
                } 
            })
            .catch(err => {
                setDevicesData()
            })
        };
        // await apiAdminGetDevices( {'Authorization': `Bearer ${accessToken}`}, deviceParams)
        // .then(res => {
        //     setDevicesData(res.data.data)
        //     hasMoreScroll = false
        //     wsDevData = res.data.data
        //         res.data.data.forEach(data => {
        //             uuidList.push(data.uuid)
        //         })
        //     if (!socket) {
        //         connectWebSocket() 
        //     } 
        // })
        // .catch(err => {
        //     setDevicesData()
        // })
        delayTime = 0
    }

    const getAllModels = async () => {
        await apiAdminGetDeviceModels( {
        'Authorization': `Bearer ${accessToken}`
        },
        modelParams
        ).then(res => {
            setModelsData(res.data.data)
        })
    }

    const getAllCompanies = async () => {
        await apiAdminGetCompanies( {
            'Authorization': `Bearer ${accessToken}`
            },
            0,
            1000
        )
        .then(res => {
            setCompaniesData(res.data.data)
        })
    }

    useEffect(() => {
        if (socket) {
            initWebSocket(uuidList)
        }
    },[socket])

    useEffect(() => {   
        getAllDevices()
        if(me && me.is_superuser) getAllCompanies()   
        if(me && me.is_superuser) getAllModels()
        
        return () => {
            delayTime = 0
            hasMoreScroll = true
            uuidList = []
            socket && socket.disconnect()
            socket = undefined
            deviceParams = {
                keyword: null,
                is_broker_connected: null,
                company_id: null,
                bmc_lite_model_id: null,
                sort_by: "created_at_desc",
                skip: 0,
                limit: 1000
            }
        }
    },[accessToken])

    const deleteDevice = (uuid, index) => {
        const DeleteDevice = async () => {
            await apiAdminDeleteDevice({headers: {'Authorization': `Bearer ${accessToken}`}}, uuid)
            .then(res => {
                if (socket) {
                    socket.emit('unsubscribe', [res.data.uuid]);
                }
                wsDevData.splice(index, 1)
                devicesData.splice(index, 1)
                showNotification('success', 'Success!', 'Selected OOB board has been deleted.')
            })
            .catch(err => {
                showNotification('error', 'Error!', `${err.response.data.detail}`)
            })
        }
        DeleteDevice()
    }

    const handleEditOnChange = (e) => {
        setNewDeviceData({
            ...newDeviceData,
            [e.target.name]: e.target.value
        })
    }

    const initAddDevice = () => {
        setActionMsg("")
        setNewDeviceData({
            name: undefined,
            uuid: undefined,
            company_id: "",
            description: "",
            bmc_lite_model_id: ""
        })
        setIsOnClick({click: "add", index: ""})
    }

    const submitAddDeviceData = () => {
        const AddDevice = async () => {
            await apiAdminCreateDevice({headers: {'Authorization': `Bearer ${accessToken}`}}, newDeviceData)
            .then(res => {
                if (socket) {
                    socket.emit('subscribe', [res.data.uuid]);
                }
                setMoreInfo([])
                wsDevData = wsDevData.concat(res.data)
                setDevicesData(devicesData.concat(res.data))
                setIsOnClick({click: "", index: ""})
                showNotification('success', 'Success!', 'Successfully add a new OOB board.')
            })
            .catch(err => {
                showNotification('error', 'Error!', `${err.response.data.detail}`)
                // console.log('Add device failed, check data is valid or existing.')
                // setActionMsg(<div className={styles.device_err_msg}>Add device failed, check data is valid or existing</div>)
            })
        }
        AddDevice()
    }

    const initModifyDevice = (data, index) => {
        setMoreInfo([])
        setActionMsg("")
        setNewDeviceData({
            name: data.name,
            description: data.description,
            company_id: data.company_id
        })
        setIsOnClick({click: "edit", index: index})
    }

    const submitEditDeviceData = (uuid) => {
        const UpdateDevice = async() => {
            await apiAdminModifyDevice({
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }},
                newDeviceData,
                uuid
            )
            .then(res => {
                devicesData[isOnClick.index] = {
                    ...devicesData[isOnClick.index],
                    name: res.data.name,
                    company_id: res.data.company_id,
                    company_name: res.data.company_name,
                }
                setIsOnClick({click: "", index: ""})
            })
            .catch(err => {
                // console.log('Edit device failed, check data is valid or existing.')
                setActionMsg(<div className={styles.device_err_msg}>Check data is valid or existing</div>)
            })
        }
        UpdateDevice()
    }

    const handleDevicePowerOnClick = (data, power, index) => {
        setIsOnClick({click: "loading", index: index})
        setActionMsg(
        <div className={styles.action_note}>
            Power {data.monitor.is_power_on ? "off" : "on"}...
        </div>)
        if (power === 'on') {
            apiPowerOnDevice({
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }},
                data.uuid
            ).then(res => {
                // devicesData[index].monitor.is_power_on = !devicesData[index].monitor.is_power_on
                // setDevicesData([...devicesData])
                setIsOnClick({click: "", index: ""})
            })
        } else if (power === 'off') {
            apiPowerOffDevice({
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }},
                data.uuid
            ).then(res => {
                // devicesData[index].monitor.is_power_on = !devicesData[index].monitor.is_power_on
                // setDevicesData([...devicesData])
                setIsOnClick({click: "", index: ""})
            })
        } else if (power === 'reboot') {
            apiRebootDevice({
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }},
                data.uuid
            ).then(res => {
                setIsOnClick({click: "", index: ""})
            })
        }
    }

    const flashBmcLite = async (uuid, index) => {
        setIsOnClick({click: "loading", index: index})
        setActionMsg(
            <div className={styles.action_note}>
                Loading...
            </div>)
        await apiUpgradeBmcLite({
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }},
            uuid
        ).then(res => {
            setIsOnClick({click: "", index: ""})
        })
    }

    
    // Search device
    useEffect(() => {
        if (searchWords) {
            const searchTimeout = setTimeout(() => {
            socket && socket.disconnect()
            socket = undefined
            deviceParams.keyword = searchWords
            getAllDevices()
        }, delayTime);
        return () => {clearTimeout(searchTimeout);}
        // return () => {clearTimeout(searchTimeout); updateDataTimerRef.current = setInterval(myIntervalFn(), reloadDataTime);}
        }
    }, [searchWords])

    const handleSearchDevicesOnChange = (e) => {
        // clearInterval(updateDataTimerRef.current)
        delayTime = 500
        const value = e.target.value
        setSearchWords(value)
        if (!value) {
            deviceParams.keyword = null
            getAllDevices()
        }

        setMoreInfo([])
        setIsOnClick({click: "", index: ""})
    }


    const handleFilterAllDevicesOnClick = () => {
        socket && socket.disconnect()
        socket = undefined
        setMoreInfo([])
        deviceParams.is_broker_connected = null
        deviceParams.keyword = null
        getAllDevices()

        document.getElementById("search_input").value = ""
        setIsOnClick({click: "", index: ""})
    }
    
    const handleFilterOnClick = (bool) => {
        socket && socket.disconnect()
        socket = undefined
        deviceParams.is_broker_connected = bool
        deviceParams.keyword = null
        setMoreInfo([])
        setIsOnClick({click: "", index: ""})
        getAllDevices()
        hasMoreScroll = false
        document.getElementById("search_input").value = ""
    }

    // Only root user can select
    // This is for all device data + device status api
    const handleFilterCompanyOnChange = (e) => {
        socket && socket.disconnect()
        socket = undefined
        setMoreInfo([])
        // setUpdateType("filter_company")
        deviceParams.company_id = e.target.value
        if (deviceParams.company_id === "") {
            deviceParams.company_id = undefined
        }

        getAllDevices()
        setIsOnClick({click: "", index: ""})
        hasMoreScroll = false
    }

    const handleFilterModelOnChange = (e) => {
        socket && socket.disconnect()
        socket = undefined
        setMoreInfo([])
        // setUpdateType("filter_model")
        deviceParams.bmc_lite_model_id = e.target.value
        if (deviceParams.bmc_lite_model_id === "") {
            deviceParams.bmc_lite_model_id = undefined
        }
        getAllDevices()
        hasMoreScroll = false
        setIsOnClick({click: "", index: ""})
    }
    // END Search device

    let scroll_height = "77vh"
    const setHeight = (e) => {
        scroll_height = e
    }

    const handleGetHistoryOnClick = (index) => {
        setIsOnClick({click: "history", index: index});
        setActionMsg(<div className={styles.action_note}>Get History Data...</div>);
        socket && socket.disconnect()
        socket = undefined
    }

    const [ pickScheduleDateTime, setPickScheduleDateTime ] = useState()
    const [ scheduleData, setScheduleData ] = useState([])

    const handleScheduleOnClick = (uuid, index) => {
        setPickScheduleDateTime()
        if (moreInfo.filter(showInfo => showInfo === index).length !== 0) {
            moreInfo.splice(moreInfo.indexOf(index), 1)
            setMoreInfo([...moreInfo])
        }
        setIsOnClick({click: "loading", index: index});
        setActionMsg(<div className={styles.action_note}>Get Schedule Data...</div>)
        newScheduleData = {
            scheduled_date: null,
            task: "power_on",
            recurrent: "daily"
        }
        getSchedule(uuid, index)
    }

    const addSchedule = async (uuid, index) => {
        await apiCreateDeviceControlSchedule({
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }},
            newScheduleData,
            uuid
        ).then(res => {
            setPickScheduleDateTime()
            newScheduleData.scheduled_date = null
            getSchedule(uuid, index)
        }).catch(err => {
            if (err.response.status === 422 || err.response.status === 400) {
                setActionMsg(<div className={styles.action_note} style={{color: 'red'}}>Invalid date time.</div>)
            } else {
                setActionMsg(<div className={styles.action_note} style={{color: 'red'}}>{err.response.data.detail}</div>)
            }
        })
    }

    const deleteSchedule = async (uuid, id, index) => {
        await apiDeleteDeviceControlSchedule(
            {
                'Authorization': `Bearer ${accessToken}`
            },
            uuid,
            id
        ).then(res => {
            getSchedule(uuid, index)
        }).catch(err => {
            setActionMsg(<div className={styles.action_note} style={{color: 'red'}}>{err.response.data.detail}</div>)
        })
    }

    const getSchedule = async (uuid, index) => {
        await apiGetDeviceControlSchedule({
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }},
            uuid
        ).then(res => {
            setIsOnClick({click: "schedule", index: index})
            setActionMsg(<div className={styles.action_note}>Power Contorl Schedule</div>)
            setScheduleData(res.data)
        }).catch(err => {
            setIsOnClick({click: "schedule", index: index})
            setScheduleData([])
            setActionMsg(<div className={styles.action_note} style={{color: 'red'}}>{err.response.data.detail}</div>)
        })
    }

    const showDeviceDeleteConfirm = (data, id, index) => {
        confirm({
            title: 'Warming!',
            icon: <ExclamationCircleFilled />,
            content: `Are you sure delete this OOB board?`,
            centered: true,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
                deleteDevice(data.uuid, index);
            }
        });
    };

    return (
        <>
        { isOnClick.click === "history" && isOnClick.index !== "" && 
        <HistoryPage device_data={devicesData[isOnClick.index]} back={(data) => {setIsOnClick(data); if (!socket) connectWebSocket() }}/>
        }
        { isOnClick.click === "detail" &&
        <DeviceDetailPage data={devicesData[isOnClick.index]} back={setIsOnClick} superuser />
        }
        <div className={styles.device_page} style={{display: isOnClick.click === "history" && 'none'}}>
            <SearchBar 
                name="Devices"
                all={handleFilterAllDevicesOnClick}
                search={handleSearchDevicesOnChange}
                filter={handleFilterOnClick}
                companyList={companiesData}
                companyOnChange={handleFilterCompanyOnChange}
                modelList={modelsData}
                modelOnChange={handleFilterModelOnChange}
                add={initAddDevice}
                superuser={me.is_superuser}
            />
            <div className={styles.device_header}>
                <div className={styles.device_header_item}>
                    <span>Served on</span>
                </div>
                <div className={styles.device_header_item_10}>
                    <span>Company</span>
                </div>
                <div className={styles.device_header_item_50}>
                    <span>Description</span>
                </div>
                <div className={styles.device_header_item}>
                    <span>Actions</span>
                </div>
            </div>
            { isOnClick.click === "add" ?
            <div className={styles.device__data__add__wrap} >
                <div className={styles.device__data__input__block}>
                    <label htmlFor="add_device_name">Device Name</label>
                    <input id="add_device_name" autoComplete="off" name="name" placeholder="Device Name" onChange={handleEditOnChange}/>
                    <label htmlFor="add_device_description">Description</label>
                    <input id="add_device_description" name="description" placeholder="Device Description" onChange={handleEditOnChange}/>
                </div>
                <div className={styles.device__data__input__block}>
                    <label htmlFor="add_device_model_id">Model</label>
                    <select defaultValue="" name="bmc_lite_model_id" id="add_device_model_id" onChange={handleEditOnChange}>
                        <option value="" disabled>Select Model</option>
                        { modelsData.map((data) => {
                            return <option value={data.id} key={data.id}>{data.name}</option>
                        })}
                    </select>
                    <label htmlFor="add_device_company_id">Company</label>
                    <select id="add_device_company_id" name="company_id" defaultValue="" onChange={handleEditOnChange} >
                            <option value="" disabled>Select Company</option>
                        { companiesData && companiesData.map((data) => {
                        return <option value={data.id} key={data.id}>{data.name}</option>
                    })}
                    </select>
                </div>
                <div className={styles.device__data__input__block}>
                    <label htmlFor="add_device_uuid">UUID</label>
                    <input placeholder="UUID" name="uuid" id="add_device_uuid" defaultValue={newDeviceData.uuid} onChange={handleEditOnChange} />
                </div>
                <div className={styles.device__data__add__block}>
                    <div className={styles.device__add__cancel__btn} onClick={() => setIsOnClick({click: "", index: ""})}>
                        <span>Cancel</span>
                    </div>
                    <div className={styles.device__add__create__btn} onClick={() => submitAddDeviceData()}>
                        <span>Create</span>
                    </div>
                </div>
            </div>
            // <div className={styles.device_data} style={{marginBottom: 0, flexWrap: 'nowrap'}}>
            //     {setHeight("54vh")}
            //     <div className={styles.edit_box}>
            //         <div className={styles.edit_box_2}>
            //             <label htmlFor="add_device_name">Device Name</label>
            //             <input id="add_device_name" autoComplete="off" name="name" placeholder="Device Name" onChange={handleEditOnChange}/>
            //         </div>
            //         <div className={styles.edit_box_2}>
            //             <label htmlFor="add_device_model_id">Model</label>
            //             <select defaultValue="" name="bmc_lite_model_id" id="add_device_model_id" onChange={handleEditOnChange}>
            //                 <option value="" disabled>Select Model</option>
            //                 { modelsData.map((data) => {
            //                     return <option value={data.id} key={data.id}>{data.name}</option>
            //                 })}
            //             </select>
            //         </div>
            //     </div>
            //     <div className={styles.edit_box}>
            //         <div className={styles.edit_box_2}>
            //             <label htmlFor="add_device_uuid">UUID</label>
            //             <input placeholder="UUID" name="uuid" id="add_device_uuid" defaultValue={newDeviceData.uuid} onChange={handleEditOnChange} />
            //         </div>
            //         <div className={styles.edit_box_2}>
            //             <label htmlFor="add_device_description">Description</label>
            //             <input id="add_device_description" defaultValue="" disabled />
            //         </div>
            //     </div>
            //     <div className={styles.edit_box}>
            //         <div className={styles.edit_box_2}>
            //             <label htmlFor="add_device_company_id">Company</label>
            //             <select id="add_device_company_id" name="company_id" defaultValue="" onChange={handleEditOnChange} >
            //                 <option value="" disabled>Select Company</option>
            //             { companiesData && companiesData.map((data) => {
            //                 return <option value={data.id} key={data.id}>{data.name}</option>
            //             })}
            //             </select>
            //         </div>
            //     </div>
            //     <div className={styles.edit_box}>
            //         <div className={styles.edit_box_2}>
            //             <div className={styles.edit_box_btn} onClick={() => submitAddDeviceData()}>ADD</div>
            //         </div>
            //         <div className={styles.edit_box_2}>
            //             <div className={styles.edit_box_btn} onClick={() => setIsOnClick({click: "", index: ""})}>CANCEL</div>
            //         </div>
            //     </div>
            //     {actionMsg}
            // </div>
            :
            setHeight("77vh")
            }            

            <div className={styles.device_content}>
                { devicesData && 
                <InfiniteScroll
                    style={{"overflowY":"overlay", "overflowX":"hidden"}}
                    dataLength={devicesData.length} 
                    next={undefined}
                    hasMore={hasMoreScroll}
                    loader={<PageLoader /> }
                    height={scroll_height}
                >
                <div className={styles.devices__group}>
                { devicesData.map((data, index) => {
                return (
                        <div className={styles.device_data} key={data.uuid} style={{position: isOnClick.click === "schedule" && isOnClick.index === index && 'relative', zIndex: isOnClick.click === "schedule" && isOnClick.index === index && '3'}}>
                        { isOnClick.click === "edit" && isOnClick.index === index ?
                        <>
                            <span>
                                <input autoComplete="off" id="edit_device_name" placeholder="Device Name" style={{width: "90%"}} name="name" defaultValue={data.name} onChange={handleEditOnChange}/>
                            </span>
                            <span>
                                <select id="edit_device_company" className={styles.company_select} style={{fontSize: '0.9vw', textAlign: 'left', marginLeft: '-4px', width: '100%'}} name="company_id" defaultValue={data.company_id} onChange={handleEditOnChange}>
                                    { companiesData && companiesData.map((data) => {
                                    return (
                                        <option value={data.id} key={data.id}>{data.name}</option>
                                    )
                                    })}
                                </select>
                            </span>
                            <span>
                                <div className={styles.device_info_name}>UUID:</div>
                                <div className={styles.device_info}>{data.uuid}</div>
                                <br/>
                                <div className={styles.device_info_name}>Model:</div>
                                <div className={styles.device_info}>{data.bmc_lite_model_name}</div>
                            </span>
                            <span>
                                <div className={styles.device_status_name}>OOB Status:</div>
                                <div className={styles.device_status} style={{width: '32%'}}>{data.is_broker_connected ? <span className={styles.online_status}>Online</span> : <span className={styles.offline_status}>Offline</span>}</div>
                                <br/>
                                { data.is_broker_connected && 
                                <>
                                <div className={styles.device_status_name}>OOB Version:</div>
                                <div className={styles.device_status} style={{width: '32%'}}>{data.version}
                                    { data.is_update_available && 
                                    <svg className={styles.device_version_note} viewBox="0 0 24 24">
                                        <title>New version {data.latest_version} is available!</title>
                                        <path d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
                                    </svg> }
                                </div>
                                <br/>
                                </> }
                            </span>
                            <span>
                                <YesButton onClick={() => submitEditDeviceData(data.uuid)}/>
                                <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                                <div className={styles.action_note}>Modify</div>
                            </span>
                        </>
                        :
                        <>
                            <div className={styles.device_content_item}>
                                {data.is_broker_connected ? 
                                    <span className={styles.online_status}></span> 
                                    : <span className={styles.offline_status}></span>
                                }
                                <span>{data.name ? data.name : 'N/A' }</span>
                            </div>
                            <div className={styles.device_content_item_10}>
                                <span>{data.company_name ? data.company_name : userInfo.company_name}</span>
                            </div>
                            <div className={styles.device_content_item_50}>
                                <span>{data.description ? data.description : 'N/A'}</span>
                            </div>
                            {/* <div className={styles.device_content_item_10}>
                                {data.is_broker_connected ? <span className={styles.online_status}>Online</span> : <span className={styles.offline_status}>Offline</span>}
                            </div> */}
                            <div className={styles.device__action__btn__group}>
                                
                                <div className={styles.device__detail__btn}
                                    onClick={(e) => {e.stopPropagation(); setIsOnClick({click: "detail", index: index})}}
                                >
                                    <Link to={`/admin/device/${data.uuid}`} >
                                        <span>Detail</span>
                                    </Link>
                                </div>
                                {me && me.is_superuser &&
                                    <div className={styles.device__delete__btn}
                                        onClick={(e) => {e.stopPropagation(); showDeviceDeleteConfirm(data, data.id, index);}}
                                    >
                                        <span>Delete</span>
                                    </div>
                                }
                            </div>
                            {/* <span>
                                <div className={styles.device_info_name}>UUID:</div>
                                <div className={styles.device_info}>{data.uuid}</div>
                                <br/>
                                <div className={styles.device_info_name}>Model:</div>
                                <div className={styles.device_info}>{data.bmc_lite_model_name}</div>
                                { isOnClick.click !== "shcedule" && isOnClick.index !== index && 
                                <>
                                { moreInfo.filter(showInfo => showInfo === index).length === 0 ?
                                <div style={{bottom: "1.2em", position: "absolute", width: "100%"}}>
                                    <svg className={styles.more_info_btn} viewBox="6 5 12 12" onClick={() => {moreInfo.push(index); setMoreInfo([...moreInfo]); }}>    
                                        <title>More info</title>
                                        <path d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z" />
                                    </svg>
                                </div> 
                                :
                                <div style={{bottom: "1.2em", position: "absolute", width: "100%"}}>
                                    <svg className={styles.less_info_btn} viewBox="5.5 4 13 13" onClick={() => {moreInfo.splice(moreInfo.indexOf(index), 1); setMoreInfo([...moreInfo])}}>
                                        <title>Less info</title>
                                        <path d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
                                    </svg>
                                </div>
                                }
                                </>
                                }
                                
                                { moreInfo.filter(showInfo => showInfo === index).length !== 0 && 
                                <>
                                <br/>
                                <div className={styles.device_info_name}>Credential:</div>
                                <div className={styles.device_info}>{data.credential_name ? data.credential_name : 'N/A'}</div>
                                <br/>
                                <div className={styles.device_info_name}>Created:</div>
                                <div className={styles.device_info}>{new Date(data.created_at).toLocaleDateString('sv') + " " + new Date(data.created_at).toLocaleTimeString('sv')}</div>
                                <br/>
                                <div className={styles.device_info_name}>Updated:</div>
                                <div className={styles.device_info}>{data.updated_at ? new Date(data.updated_at).toLocaleDateString('sv') + " " + new Date(data.updated_at).toLocaleTimeString('sv') : 'N/A'}</div>
                                { data.ip && 
                                <>
                                <br/>
                                <div className={styles.device_info_name}>IP:</div>
                                <div className={styles.device_info}>{data.ip}</div>
                                </>
                                }
                                { data.description && 
                                <>
                                <br/>
                                <div className={styles.device_info_name}>Description:</div>
                                <div className={styles.device_info}>{data.description}</div>
                                </>
                                }
                                </>
                                }
                            </span> */}
                            {/* <span>
                                <div className={styles.device_status_name}>OOB Status:</div>
                                <div className={styles.device_status} style={{width: "32%"}}>{data.is_broker_connected ? <span className={styles.online_status}>Online</span> : <span className={styles.offline_status}>Offline</span>}</div>

                                { data.is_broker_connected && 
                                <>
                                <div className={styles.device_status_name}>OOB Version:</div>
                                <div className={styles.device_status} style={{width: "32%"}}>{data.version}
                                    { data.is_update_available && 
                                    <svg className={styles.device_version_note} viewBox="0 0 24 24">
                                        <title>New version {data.latest_version} is available!</title>
                                        <path d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
                                    </svg> }
                                </div>
                                <br/>
                                { moreInfo.filter(showInfo => showInfo === index).length !== 0 && 
                                <>
                                <div className={styles.device_status_name}>OOB System Usage</div>
                                <ul >
                                    <li><div className={styles.device_status_name}>CPU:</div><div className={styles.device_status}>{data.cpu_usage + " %"}</div></li>
                                    <li><div className={styles.device_status_name}>Memory:</div><div className={styles.device_status}>{data.mem_usage + " %"}</div></li>
                                </ul>
                                <div className={styles.device_status_name} style={{"fontWeight":"bold"}}>Device H/W Monitor</div>
                                <br/>
                                <div className={styles.device_status_name}>Power:</div>
                                <div className={styles.device_status} style={{width: "32%"}}>{data.monitor.is_power_on ? "On" : "Off"}</div>
                                <br/>
                                { data.monitor.is_power_on && 
                                <>
                                { data.monitor.voltage[0] && 
                                <>
                                <div className={styles.device_status_name}>Voltage</div>
                                <ul>
                                { data.monitor.voltage.map((data, index) => {
                                    return (
                                    <li key={index}><div className={styles.device_status_name}>{data.name}:</div><div className={styles.device_status}>{data.value / 1000 + " V"}</div></li>
                                    )
                                })}
                                </ul>
                                </> }
                                
                                { data.monitor.fan_speed[0] && 
                                <>
                                <div className={styles.device_status_name}>Fan</div>
                                <ul >
                                { data.monitor.fan_speed.map((data, index) => {
                                    return (
                                    <li key={index}><div className={styles.device_status_name}>{data.name}:</div><div className={styles.device_status}>{data.value + " rpm"}</div></li>
                                    )
                                })}
                                </ul>
                                </> }

                                { data.monitor.temperature[0] && 
                                <>
                                <div className={styles.device_status_name}>Temperature</div>
                                <ul>
                                    { data.monitor.temperature.map((data, index) => {
                                    return (
                                    <li key={index}><div className={styles.device_status_name}>{data.name}:</div><div className={styles.device_status}>{data.value + " ℃"}</div></li>
                                    )
                                    })}
                                </ul>
                                </> }
                                </>
                                }
                                </> 
                                }
                                </> 
                                }
                                
                            </span> */}
                            {/* { isOnClick.click === "delete" && isOnClick.index === index && 
                            <span>
                                <YesButton onClick={() => {deleteDevice(data.uuid, index)}}/>
                                <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                                <div className={styles.action_note}>Delete</div>
                            </span> }
                            { isOnClick.click === "flash" && isOnClick.index === index && 
                            <span>
                                <YesButton onClick={() => flashBmcLite(data.uuid, index)}/>
                                <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                                <div className={styles.action_note}>
                                    Upgrade
                                </div>
                            </span> }
                            { isOnClick.click === "power" && isOnClick.index === index && 
                            <span>
                                <YesButton onClick={() => handleDevicePowerOnClick(data, data.monitor.is_power_on ? "off" : "on", index) }/>
                                <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                                <div className={styles.action_note}>Power {data.monitor.is_power_on ? "off" : "on"}</div>
                            </span>
                            }
                            { isOnClick.click === "reboot" && isOnClick.index === index && 
                            <span>
                                <YesButton onClick={() => handleDevicePowerOnClick(data, 'reboot', index) }/>
                                <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                                <div className={styles.action_note}>Reboot</div>
                            </span>
                            }
                            { isOnClick.click === "loading" && isOnClick.index === index && 
                            <span>
                                <div className={styles.loader_device}></div>
                                {actionMsg}
                            </span>
                            }
                            { isOnClick.click === "schedule" && isOnClick.index === index && 
                            <span>
                                <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                                {actionMsg}
                            </span>
                            }
                            { data.update_status !== "Idle" &&
                            <span>
                                <div className={styles.action_note}>
                                    Firmware {data.update_status}
                                </div>
                                <div className={styles.loader_device}></div>
                            </span> }
                            
                            { isOnClick.index !== index && data.update_status === "Idle" &&
                            <span>
                                { data.is_broker_connected ? 
                                <>
                                <PowerButton status={data.monitor.is_power_on} onClick={() => setIsOnClick({click: "power", index: index})} />
                                <RebootButton onClick={() => setIsOnClick({click: "reboot", index: index})} />
                                <UpgradeButton disabled={data.is_update_available ? false : true} onClick={() => data.is_update_available ? setIsOnClick({click: "flash", index: index}) : undefined} />
                                <ScheduleControlButton onClick={() => {handleScheduleOnClick(data.uuid, index); }}/>
                                </>
                                :
                                <>
                                <PowerButton disabled />
                                <RebootButton disabled />
                                <UpgradeButton disabled />
                                <ScheduleControlButton disabled />
                                </>
                                }
                                <HistoryButton onClick={() => handleGetHistoryOnClick(index)}/>
                                <ModifyButton onClick={() => initModifyDevice(data, index)}/>
                                <DeleteButton onClick={() => setIsOnClick({click: "delete", index: index})}/>
                            </span> }

                            { isOnClick.click === "schedule" && isOnClick.index === index && 
                            <div className={styles.device_bottom_box}>
                                <div style={{fontWeight: 'bold', fontSize: '1vw', margin: '1% 0'}}>Power Control Schedule</div>
                                <div className={styles.schedule_container}>
                                    <div className={styles.schedule_box}>
                                        <div style={{paddingRight: '0', paddingLeft: '5%'}}>
                                            <div className={styles.schedule_data} >
                                                <div style={{marginRight: 'auto'}}>Date</div>
                                                <DatePicker 
                                                    id="add_schedule_date"
                                                    placeholderText=" Select a date"
                                                    className={styles.schedule_date_picker}
                                                    selected={pickScheduleDateTime}
                                                    dateFormat='yyyy-MM-dd HH:mm'
                                                    timeFormat='HH:mm'
                                                    timeIntervals={1}
                                                    showTimeSelect
                                                    onChange={date => {newScheduleData.scheduled_date = date; setPickScheduleDateTime(date); }}
                                                />
                                            </div>
                                            <div className={styles.schedule_data} >
                                                <div>Power</div> 
                                                <select id="add_schedule_power" onChange={e => newScheduleData.task = e.target.value}>
                                                    <option value="power_on">Power On</option>
                                                    <option value="power_off">Power Off</option>
                                                    <option value="power_reboot">Reboot</option>
                                                </select>
                                            </div>
                                            <div className={styles.schedule_data} >
                                                <div >Repetitive</div>
                                                <select id="add_schedule_event" onChange={e => newScheduleData.recurrent = e.target.value}>
                                                    <option value="daily">Daily</option>
                                                    <option value="monthly">Monthly</option>
                                                    <option value="never">Once</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className={styles.add_schedule_btn} title="Add Schedule" onClick={() => addSchedule(data.uuid, index)}>
                                            <AddScheduleButton />
                                        </div>
                                    </div>
                                    <div style={{margin: '0 1%', borderRight: '1px solid #a4a4a4'}}></div>
                                    <div className={styles.schedule_area}>
                                    { scheduleData.map((schedule, schedule_index) => {
                                        return (
                                            <div className={styles.schedule_box} key={schedule_index}>
                                                <div style={{paddingRight: '0', paddingLeft: '5%'}}>
                                                    <div className={styles.schedule_data} >
                                                        <div style={{marginRight: 'auto'}}>Date</div>
                                                        <div>
                                                            {new Date(schedule.scheduled_date).toLocaleDateString('sv') + " " + new Date(schedule.scheduled_date).toLocaleTimeString('sv')}
                                                        </div>
                                                    </div>
                                                    <div className={styles.schedule_data} style={{textTransform: 'capitalize'}}>
                                                        <div>Power</div> 
                                                        <div>{schedule.task.replace('_', ' ')}</div>
                                                    </div>
                                                    <div className={styles.schedule_data} style={{textTransform: 'capitalize'}}>
                                                        <div >Repetitive</div>
                                                        <div>{schedule.recurrent === "never" ? "Once" : schedule.recurrent}</div>
                                                    </div>
                                                </div>
                                                <div className={styles.add_schedule_btn} title="Delete Schedule" 
                                                    onClick={() => deleteSchedule(data.uuid, schedule.index, index)}>
                                                    <DeleteScheduleButton />
                                                </div>
                                            </div>
                                        )
                                    })} */}
                                    {/* </div> */}
                                    
                                {/* </div> */}
                            {/* </div> */}
                            {/* } */}
                            
                        </> }
                        </div>
                    
                )
                })}
                </div>
                </InfiniteScroll> }
            </div>
        </div>
    </>
    )
}
export default DevicePage