import { useState } from "react";
import "./style.scss"

export const SearchBar = (props) => {
    const [ btnActive, setBtnActive ] = useState("all")
    
    return (
        <div className="search_wrapper">
            <div className={btnActive === "all" ? "filter_btn_active" : "filter_btn"} onClick={() => {props.all(); setBtnActive("all")}}><span>All {props.name}</span></div>
            { props.name === "Users" && 
            <>
                <div className={btnActive === "active" ? "filter_btn_active" : "filter_btn"} onClick={() => {props.filter(true); setBtnActive("active")}}>
                    <span>Active</span>
                </div>
                <div className={btnActive === "inactive" ? "filter_btn_active" : "filter_btn"} onClick={() => {props.filter(false); setBtnActive("inactive")}}>
                    <span>Inactive</span>
                </div>
            { props.superuser && 
                <div className="filter_select">
                    { props.companyList ?
                    <select id="company_select" className="filter_option_select" onChange={e => {props.companyOnChange(e); document.getElementById("search_input").value = "" }} >
                        <option value="">All Companies</option>
                        {props.companyList.map((data, index) => {
                            return <option value={data.id} key={index}>{data.name}</option>
                            })}
                    </select>
                    :
                    <select id="company_select" className="filter_option_select" defaultValue="">
                        <option value="" disabled>All Companies</option>
                    </select>
                    }
                </div>
            }
            { props.add && 
                <div className="filter_btn" onClick={() => {props.add();}}>
                    <span style={{fontSize:'1.4vw', paddingRight:'5px'}}>+</span>
                    <span>Add User</span>
                </div>
            }
            </>
            }

            { props.name === "Devices" && 
            <>
            {/* <div className={btnActive === "on" ? "filter_btn_active" : "filter_btn"} onClick={() => {props.filter(true, "filter_power"); setBtnActive("on")}}>Power On</div> */}
            {/* <div className={btnActive === "off" ? "filter_btn_active" : "filter_btn"} onClick={() => {props.filter(false, "filter_power"); setBtnActive("off")}}>Power Off</div> */}
            <div className={btnActive === "online" ? "filter_btn_active" : "filter_btn"} onClick={() => {props.filter(true); setBtnActive("online")}}>
                <span>Online</span>
            </div>
            <div className={btnActive === "offline" ? "filter_btn_active" : "filter_btn"} onClick={() => {props.filter(false); setBtnActive("offline")}}>
                <span>Offline</span>
            </div>

            { props.superuser && 
            <div className="filter_select" >
                { props.companyList ?
                <select id="company_select" className="filter_option_select" onChange={e => {props.companyOnChange(e); document.getElementById("search_input").value = "" }} >
                    <option value="">All Companies</option>
                    { props.companyList.map((data, index) => {
                        return (
                            <option value={data.id} key={data.id}>{data.name}</option>
                        )
                    })
                    }
                </select>
                :
                <select id="company_select" className="filter_option_select" defaultValue="">
                    <option value="" disabled>All Companies</option>
                </select>
                }
            </div>
            }

            { props.superuser &&
                <div className="filter_select">
                    { props.modelList ?
                    <select id="model_select" className="filter_option_select" onChange={e => {props.modelOnChange(e); document.getElementById("search_input").value = "" }} >
                        <option value=""><span>All Models</span></option>
                        { props.modelList.map((data, index) => {
                            return (
                                <option value={data.id} key={data.id}>
                                    <span>{data.name}</span>
                                </option>
                            )
                        })
                        }
                    </select>
                    :
                    <select id="model_select" className="filter_option_select" defaultValue="">
                        <option value="" disabled>
                            <span>All Models</span>
                        </option>
                    </select>
                    }
                </div>
            }

            { props.add && props.superuser && 
                <div className="filter_btn" onClick={() => {props.add();}}>
                    <span style={{fontSize:'1.4vw', paddingRight:'5px'}}>+</span>
                    <span>Add Device</span>
                </div>
            }
            </>
            }

            { props.name === "Credentials" && 
            <div className="filter_btn" onClick={() => {props.add();}}>
                <span style={{fontSize:'1.4vw', paddingRight:'5px'}}>+</span>
                <span>Add Credential</span>
            </div>
            }

            { props.name === "Companies" && 
            <div className="filter_btn" onClick={() => {props.add();}}>
                <span style={{fontSize:'1.4vw', paddingRight:'5px'}}>+</span>
                <span>Add Company</span>
            </div>
            }

            { props.name === "Files" &&
            <div className="filter_btn" onClick={() => {props.add();}}>
                <span style={{fontSize:'1.4vw', paddingRight:'5px'}}>+</span>
                <span>Add File</span>
            </div>
            }

            { props.name === "Models" && 
            <div className="filter_btn" onClick={() => {props.add();}}>
                <span style={{fontSize:'1.4vw', paddingRight:'5px'}}>+</span>
                <span>Add Model</span>
            </div>
            }
            
            <div className="search_bar">
                <svg viewBox="1 2 21 20" height="1.2vw" width="1.2vw" >
                    <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                </svg>
                {props.name === "Users" && <input id="search_input" name="search" placeholder="Search user, phone and email" onChange={e => {props.search(e); }}/>}
                {props.name === "Devices" && <input id="search_input" name="search" placeholder="Search device and uuid" onChange={e => {props.search(e); }}/>}
                {props.name === "Companies" && <input id="search_input" name="search" placeholder="Search company" onChange={e => {props.search(e); }}/>}
                {props.name === "Credentials" && <input id="search_input" name="search" placeholder="Search credential name" onChange={e => {props.search(e); }}/>}
                {props.name === "Files" && <input id="search_input" name="file" placeholder="Search version" onChange={e => {props.search(e); }}/>}
                {props.name === "Models" && <input id="search_models_input" name="search" placeholder="Search model name" onChange={e => {props.search(e); }}/>}
            </div>
        </div>
    )
}
