import { useEffect, useState } from "react";
import { apiGetConnectionHistory, apiGetMonitorHistory, apiGetInfoHistory, apiGetPowerHistory, apiGetAlertRecords, apiGetScheduledControlRecords } from "../../api";
import { CalendarIcon } from "../ActionButton";
import ReactECharts from "echarts-for-react";
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import styles from './HistoryPage.module.scss'

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const bmcLiteData = [],
    fanSpeedData = [],
    tempData = [],
    voltData = [],
    dateData = [],
    formatData = []

const historyParams = {
    start_dt: moment.tz(new Date().toJSON().slice(0, 10)+ ' 00:00:00', timezone).format(),
    end_dt: moment(new Date().toISOString()).format(),
    sort_by: 'created_at',
    skip: 0,
    limit: 0
}

const HistoryPage = ({device_data, back}) => {
    const accessToken = JSON.parse(localStorage.getItem('access_token'))
    const [ pickedDate, setPickedDate ] = useState(new Date());
    const [ startTime, setStartTime ] = useState(null);
    const [ endTime, setEndTime ] = useState(null);
    const [ optionSeries, setOptionSeries ] = useState()
    const [ optionYAxis, setOptionYAxis ] = useState()
    const [ optionTitle, setOptionTitle ] = useState()
    const [ optionDate, setOptionDate ] = useState([])
    const [ actionMsg, setActionMsg ] = useState()
    const [ isHistoryFilterActive, setIsHistoryFilterActive ] = useState("oob")
    
    const bmclite_usage_option = {
        tooltip: {
            trigger: 'axis',
            position: function (pt) {
                return [pt[0], '10%'];
            }
        },
            legend: {
            top: '28vw'
        },
        title: {
            left: 'center',
            text: optionTitle
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: optionDate && optionDate
        },
        yAxis: {
            name: optionYAxis,
            type: 'value',
            boundaryGap: [0, '100%'],
            minInterval: optionTitle === "Power" && 1,
            maxCount: optionTitle === "Power" && 1,
            axisLabel: {
                formatter: optionTitle === "Power" && function (value, index) {
                    if (value === 0) {
                        return "OFF"
                    } else if (value === 1) {
                        return "ON"
                    } else 
                        return ""
                }
            }
        },
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 10
            },
            {
                start: 0,
                end: 10
            }
        ],
        series: optionSeries && optionSeries
    }


    useEffect(() => {
        setStartTime(null); 
        setEndTime(null);
        setOptionYAxis('Usage(%)'); 
        setOptionTitle('CPU and Memory');
        setPickedDate(new Date());
        getHistoryData("oob"); 
        return () => {
            historyParams.start_dt = moment.tz(new Date().toJSON().slice(0, 10)+ ' 00:00:00', timezone).format()
            historyParams.end_dt = moment(new Date().toISOString()).format()
        }
    }, [accessToken])

    const getHistoryData = (type) => {
        setOptionSeries()
        dateData.length = 0
        formatData.length = 0

        apiGetInfoHistory({'Authorization': `Bearer ${accessToken}`}, device_data.uuid,  historyParams)
        .then(res => {
            console.log(res)
            console.log(type)
            // Insert name
            bmcLiteData.push({name: "CPU Usage", value: []}, {name: "Memory Usage", value: []})
            // Insert value
            res.data.data.forEach(data => {
                // date
                dateData.push(data.created_at.slice(0, 19).replace('T', ' '))
                // cpu usage
                bmcLiteData[0].value.push(data.cpu_usage)
                // mem usage
                bmcLiteData[1].value.push(data.mem_usage)
                
            })
            setOptionYAxis('Usage(%)'); 
            setOptionTitle('CPU and Memory');
            bmcLiteData.forEach(data => {
                formatData.push(
                    {
                        name: data.name,
                        type: 'line',
                        symbol: 'none',
                        sampling: 'lttb',
                        data: data.value
                    }
                )}
            )
            setOptionDate(dateData)
            setOptionSeries(formatData)
            setActionMsg(null)
        }).catch(err => {
            setOptionSeries()
            setOptionDate()
            setActionMsg(<div style={{display: 'flex', color: 'red', justifyContent: 'center', height: '0', fontSize: '1vw'}} >{err.response.data.detail}</div>   )
        })




        apiGetMonitorHistory({'Authorization': `Bearer ${accessToken}`}, device_data.uuid,  historyParams)
        .then(res => {
            // Insert name
            bmcLiteData.push({name: "CPU Usage", value: []}, {name: "Memory Usage", value: []})

            if (res.data.data[0].monitor.fan_speed.length === 0) {
                fanSpeedData.push({name: 'Fan', value: [] })
            } else {
                res.data.data[0].monitor.fan_speed.forEach(fan => {
                    fanSpeedData.push({name: fan.name, value: []})
                })
            }

            if (res.data.data[0].monitor.temperature.length === 0) {
                tempData.push({name: 'Temperature', value: []})
            } else {
                res.data.data[0].monitor.temperature.forEach(temp => {
                    tempData.push({name: temp.name, value: []})
                })
            }

            if (res.data.data[0].monitor.voltage.length === 0) {
                voltData.push({name: 'Voltage', value: [] })
            } else {
                res.data.data[0].monitor.voltage.forEach(volt => {
                    voltData.push({name: volt.name, value: [] })
                })
            }

            // Insert value
            res.data.data.forEach(data => {
                // date
                dateData.push(data.created_at.slice(0, 19).replace('T', ' '))
                // cpu usage
                bmcLiteData[0].value.push(data.cpu_usage)
                // mem usage
                bmcLiteData[1].value.push(data.mem_usage)
                // fan speed
                if (res.data.data[0].monitor.fan_speed.length === 0) {
                    fanSpeedData[0].value = []
                } else {
                    data.monitor.fan_speed.forEach((fan, index) => {
                        fanSpeedData[index].value.push(fan.value)
                    })
                }
                // temp
                if (res.data.data[0].monitor.temperature.length === 0) {
                    tempData[0].value = []
                } else {
                    data.monitor.temperature.forEach((temp, index) => {
                        tempData[index].value.push(temp.value)
                        }
                    )
                }
                // volt
                if (res.data.data[0].monitor.voltage.length === 0) {
                    voltData[0].value = []
                } else {
                    data.monitor.voltage.forEach((volt, index) => {
                        voltData[index].value.push(volt.value / 1000)
                        }
                    )
                }
            })

            if (type === "oob") {
                setOptionYAxis('Usage(%)'); 
                setOptionTitle('CPU and Memory');
                bmcLiteData.forEach(data => {
                    formatData.push(
                        {
                            name: data.name,
                            type: 'line',
                            symbol: 'none',
                            sampling: 'lttb',
                            data: data.value
                        }
                    )}
                )
            } else if (type === "hwm") {
                setOptionYAxis('℃');
                setOptionTitle('Temperature');
                tempData.forEach(data => {
                    formatData.push(
                        {
                            name: data.name,
                            type: 'line',
                            symbol: 'none',
                            sampling: 'lttb',
                            data: data.value
                        }
                    )}
                )
            }

            setOptionDate(dateData)
            setOptionSeries(formatData)
            setActionMsg(null)
        }).catch(err => {
            setOptionSeries()
            setOptionDate()
            setActionMsg(<div style={{display: 'flex', color: 'red', justifyContent: 'center', height: '0', fontSize: '1vw'}} >{err.response.data.detail}</div>   )
        })
    }

    const [ powerHistoryData, setPowerHistoryData ] = useState([])
    const getPowerHistory = async () => {
        await apiGetPowerHistory({
            'Authorization': `Bearer ${accessToken}`
        },
        device_data.uuid, 
        historyParams
        ).then(res => {
            setPowerHistoryData(res.data.data)
        }).catch(err => {
            setPowerHistoryData([])
            setActionMsg(<div style={{display: 'flex', marginTop: '10px', marginLeft: '6px'}} className={styles.action_note_err} >{err.response.data.detail}</div>)
        })
    }

    const [ connHistoryData, setConnHistoryData ] = useState([])
    const getConnectionHistory = async () => {
        await apiGetConnectionHistory({
            'Authorization': `Bearer ${accessToken}`
        },
        device_data.uuid, 
        historyParams
        ).then(res => {
            setConnHistoryData(res.data.data)
        }).catch(err => {
            setConnHistoryData([])
            setActionMsg(<div style={{display: 'flex', marginTop: '10px', marginLeft: '6px'}} className={styles.action_note_err} >{err.response.data.detail}</div>)
        })
    }
    const [ alertRecords, setAlertRecords ] = useState([])
    const getAlertRecords = async () => {
        await apiGetAlertRecords({
            'Authorization': `Bearer ${accessToken}`
        },
        device_data.uuid, 
        historyParams
        ).then(res => {
            setAlertRecords(res.data.data)
        }).catch(err => {
            setAlertRecords([])
            setActionMsg(<div style={{display: 'flex', marginTop: '10px', marginLeft: '6px'}} className={styles.action_note_err} >{err.response.data.detail}</div>)
        })
    }
    const [scheduledControlRecords, setScheduledControlRecords ] = useState([])
    const getScheduledControlRecords = async () => {
        await apiGetScheduledControlRecords({
            'Authorization': `Bearer ${accessToken}`
        },
        device_data.uuid, 
        historyParams
        ).then(res => {
            setScheduledControlRecords(res.data.data)
        }).catch(err => {
            setScheduledControlRecords([])
            setActionMsg(<div style={{display: 'flex', marginTop: '10px', marginLeft: '6px'}} className={styles.action_note_err} >{err.response.data.detail}</div>)
        })
    }




    // if (isOnClick === "loading")
    // return (
    //     <span>
    //         <div className={styles.loader_history}></div>
    //         {actionMsg}
    //     </span>
    // )

    //if (isOnClick === "history")
    return (
        <div className={styles.device_history_page}>
            <svg className={styles.history_close} viewBox="2 2 20 20"
                onClick={() => {back(false);}} >
                <title>Back</title>
                <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
            </svg>
            <div className={styles.device_history_filter_btn} >
                <div className={isHistoryFilterActive === "oob" ? styles.filter_btn_active : styles.filter_btn} 
                    onClick={() => {setActionMsg()
                                    setIsHistoryFilterActive("oob");
                                    setOptionYAxis('Usage(%)'); 
                                    setOptionTitle('CPU and Memory');
                                    getHistoryData(); 
                                    bmcLiteData.forEach(data => {
                                        formatData.push(
                                            {
                                                name: data.name,
                                                type: 'line',
                                                symbol: 'none',
                                                sampling: 'lttb',
                                                data: data.value
                                            }
                                        )}
                                    )
                                    setOptionSeries(formatData)
                } }>
                    <span>OOB System Usage</span>
                </div>

                <div className={isHistoryFilterActive === "hwm" ? styles.filter_btn_active : styles.filter_btn} 
                    onClick={() => {setActionMsg()
                                    tempData.length = 0
                                    if (document.getElementById("hwm_select")) document.getElementById("hwm_select").value = "Temperature"
                                    setIsHistoryFilterActive("hwm"); 
                                    setOptionYAxis('℃');
                                    setOptionTitle('Temperature');
                                    getHistoryData("hwm"); 
                                    tempData.forEach(data => {
                                        formatData.push(
                                            {
                                                name: data.name,
                                                type: 'line',
                                                symbol: 'none',
                                                sampling: 'lttb',
                                                data: data.value
                                            }
                                        )}
                                    )
                                    setOptionSeries(formatData)
                } }>
                    <span>Device H/W Monitor</span>
                </div>
                <div className={isHistoryFilterActive === "power" ? styles.filter_btn_active : styles.filter_btn} 
                    onClick={() => {
                        historyParams.sort_by = "created_at"
                        setPowerHistoryData([])
                        setActionMsg()
                        setIsHistoryFilterActive("power"); 
                        getPowerHistory(); 
                    }}>
                    <span>Power Control History</span>
                </div>
                <div className={isHistoryFilterActive === "conn" ? styles.filter_btn_active : styles.filter_btn} 
                    onClick={() => {
                        historyParams.sort_by = "created_at"
                        setConnHistoryData([])
                        setActionMsg()
                        setIsHistoryFilterActive("conn"); 
                        getConnectionHistory(); 
                    }}>
                    <span>Connection History</span>
                </div>
                <div className={isHistoryFilterActive === "alert" ? styles.filter_btn_active : styles.filter_btn} 
                    onClick={() => {
                        historyParams.sort_by = "created_at"
                        setAlertRecords([])
                        setActionMsg()
                        setIsHistoryFilterActive("alert"); 
                        getAlertRecords(); 
                    }}
                >
                    <span>Alert Records</span>
                </div>
                <div className={isHistoryFilterActive === "scheduled" ? styles.filter_btn_active : styles.filter_btn} 
                    onClick={() => {
                        historyParams.sort_by = "created_at"
                        setScheduledControlRecords([])
                        setActionMsg()
                        setIsHistoryFilterActive("scheduled"); 
                        getScheduledControlRecords(); 
                    }}
                >
                    <span>Scheduled Control Records</span>
                </div>
            </div>

            <div className={styles.device_header}>
                <div className={styles.device_header_item}>
                    <span>Served on</span>
                </div>
                <div className={styles.device_header_item_10}>
                    <span>Company</span>
                </div>
                <div className={styles.device_header_item_50}>
                    <span>Description</span>
                </div>
                <div className={styles.device_header_item}>
                    <span>Actions</span>
                </div>
            </div>
            <div className={styles.device_data}>

                <div className={styles.device_content_item}>
                    {device_data.is_broker_connected ? 
                        <span className={styles.online_status}></span> 
                        : <span className={styles.offline_status}></span>
                    }
                    <span>{device_data.name ? device_data.name : 'N/A' }</span>
                </div>
                <div className={styles.device_content_item_10}>
                    <span>{device_data.company_name ? device_data.company_name : 'N/A'}</span>
                </div>
                <div className={styles.device_content_item_50}>
                    <span>{device_data.description ? device_data.description : 'N/A'}</span>
                </div>
                {/* <span>{device_data.name}</span>
                <span>{device_data.company_name}</span>
                <span>{device_data.description}</span> */}
                <span style={{display: "flex"}}>
                    <label className={styles.calendar_icon} htmlFor="set_date_picker">
                        <CalendarIcon />
                    </label>
                    <DatePicker 
                        id="set_date_picker" 
                        className={styles.set_date_picker} 
                        selected={pickedDate} 
                        onChange={(date, e) => {e.preventDefault(); 
                                                setPickedDate(new Date(date));
                                                setStartTime(null)
                                                setEndTime(null)
                                                }} 
                        dateFormat="yyyy - MM - dd"
                    />
                </span>
                <span style={{display: "flex"}}>
                    <svg className={styles.calendar_icon} viewBox="1 1 22 22">
                        <path d="M12 20C16.4 20 20 16.4 20 12S16.4 4 12 4 4 7.6 4 12 7.6 20 12 20M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M17 13.9L16.3 15.2L11 12.3V7H12.5V11.4L17 13.9Z" />
                    </svg>
                    <DatePicker 
                        id="start_time_picker"
                        className={styles.set_time_picker}
                        selected={startTime}
                        onChange={date => {setEndTime(null); setStartTime(date);}}
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        onKeyDown={e => {e.preventDefault();}}
                    />
                    <div>–</div>
                    <DatePicker 
                        id="end_time_picker"
                        className={styles.set_time_picker}
                        selected={endTime}
                        onChange={date => {setEndTime(date); 
                            bmcLiteData.length = 0
                            tempData.length = 0
                            historyParams.start_dt = moment.tz(pickedDate.toISOString().slice(0, 10) + ' ' + startTime.getHours().toString().padStart(2, "0") + ':' + startTime.getMinutes().toString().padStart(2, "0") + ':00', timezone).format()
                            historyParams.end_dt =  moment.tz(pickedDate.toISOString().slice(0, 10) + ' ' + date.getHours().toString().padStart(2, "0") + ':' + date.getMinutes().toString().padStart(2, "0") + ':59', timezone).format()
                            if (document.getElementById("hwm_select")) document.getElementById("hwm_select").value = "Temperature"
                            if (isHistoryFilterActive === "power") {
                                getPowerHistory()
                            } else if (isHistoryFilterActive === "conn") {
                                getConnectionHistory()
                            } else {
                                getHistoryData(isHistoryFilterActive)
                            }
                            setActionMsg(null);
                            }
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        onKeyDown={e => {e.preventDefault();}}
                        injectTimes={startTime && [new Date(startTime.toISOString().slice(0, 10) + " 23:59:59")]}
                        minTime={startTime}
                        maxTime={startTime && new Date(startTime.toISOString().slice(0, 10) + " 23:59:59") }
                        disabled={startTime ? false : true}
                    />
                </span>
            </div>
            { isHistoryFilterActive !== "power" && isHistoryFilterActive !== "conn" && isHistoryFilterActive !== "alert" && isHistoryFilterActive !== "scheduled" && actionMsg }
            { isHistoryFilterActive !== "power" && isHistoryFilterActive !== "conn" && isHistoryFilterActive !== "alert" && isHistoryFilterActive !== "scheduled" && 
            <div style={{display: 'flex', marginTop: '-1%'}}>
                <ReactECharts 
                    style={{
                        height: '63vh',
                        width: '100%',
                        marginTop: '3%',
                        zIndex: '0'
                    }}
                    option={bmclite_usage_option}
                    notMerge={true}
                />
                { isHistoryFilterActive === "hwm" && 
                <select className={styles.history_hwm_select} 
                    onChange={e => {
                        formatData.length = 0
                        setOptionTitle(e.target.value); 
                        switch(e.target.value) {
                            case 'Fan':
                                setOptionYAxis('RPM');
                                setOptionTitle('Fan');
                                fanSpeedData.forEach(data => {
                                    formatData.push(
                                        {
                                            name: data.name,
                                            type: 'line',
                                            symbol: 'none',
                                            sampling: 'lttb',
                                            data: data.value
                                        }
                                    )
                                })
                                setOptionSeries(formatData)
                                break
                            case 'Voltage':
                                setOptionYAxis('V');
                                setOptionTitle('Voltage');
                                voltData.forEach(data => {
                                    formatData.push(
                                        {
                                            name: data.name,
                                            type: 'line',
                                            symbol: 'none',
                                            sampling: 'lttb',
                                            data: data.value
                                        }
                                    )
                                })
                                setOptionSeries(formatData)
                                break
                            case 'Temperature':
                                setOptionYAxis('℃');
                                setOptionTitle('Temperature');
                                tempData.forEach(data => {
                                    formatData.push(
                                        {
                                            name: data.name,
                                            type: 'line',
                                            symbol: 'none',
                                            sampling: 'lttb',
                                            data: data.value
                                        }
                                    )
                                })
                                setOptionSeries(formatData)
                                break
                            default:
                                break
                        }

                }} id="hwm_select" >
                    <option value='Temperature'>Temperature</option>    
                    <option value='Voltage'>Voltage</option>
                    <option value='Fan'>Fan</option>
                </select>
                }
            </div>
            }
            { isHistoryFilterActive === "power" && 
            <div className={styles.device_history_log_container}>
                <div className={styles.device_log_header}>
                    <span>
                        Time
                        { historyParams.sort_by === "created_at" && 
                        <svg className={styles.history_sort_btn} viewBox="6 6 11 11" onClick={() => {historyParams.sort_by = "created_at_desc"; getPowerHistory() }} >
                        <path d="M8 15H16L12 8" />
                        </svg>}
                        { historyParams.sort_by === "created_at_desc" && 
                        <svg className={styles.history_sort_btn} viewBox="6 6 11 11" onClick={() => {historyParams.sort_by = "created_at"; getPowerHistory() }} >
                        <path d="M8 9H16L12 16" />
                        </svg>
                        }
                    </span>
                    <span>Status</span>
                </div>
                <div className={styles.device_log_content}>
                {actionMsg}
                { powerHistoryData.map((data, index) => {
                    return (
                        <div className={styles.device_log_data} key={index}>
                            <span>{new Date(data.created_at).toLocaleDateString('sv') + " " + new Date(data.created_at).toLocaleTimeString('sv')}</span>
                            <span>{data.power_status ? "Power On" : "Power Off"}</span>
                        </div>
                    )
                })}
                </div>
            </div>
            }
            { isHistoryFilterActive === "conn" && 
            <div className={styles.device_history_log_container}>
                <div className={styles.device_log_header}>
                    <span>
                        Time
                        { historyParams.sort_by === "created_at" && 
                        <svg className={styles.history_sort_btn} viewBox="6 6 11 11" onClick={() => {historyParams.sort_by = "created_at_desc"; getConnectionHistory() }} >
                        <path d="M8 15H16L12 8" />
                        </svg>}
                        { historyParams.sort_by === "created_at_desc" && 
                        <svg className={styles.history_sort_btn} viewBox="6 6 11 11" onClick={() => {historyParams.sort_by = "created_at"; getConnectionHistory() }} >
                        <path d="M8 9H16L12 16" />
                        </svg>
                        }
                    </span>
                    <span>Status</span>
                </div>
                <div className={styles.device_log_content}>
                {actionMsg}
                { connHistoryData.map((data, index) => {
                    return (
                        <div className={styles.device_log_data} key={index}>
                            <span>{new Date(data.created_at).toLocaleDateString('sv') + " " + new Date(data.created_at).toLocaleTimeString('sv')}</span>
                            <span>{data.is_broker_connected ? "Connected" : "Disconnected"}</span>
                        </div>
                    )
                })}
                </div>
            </div>
            }
            { isHistoryFilterActive === "alert" && 
            <div className={styles.device_history_log_container}>
                <div className={styles.device_log_header}>
                    <span>
                        Time
                        { historyParams.sort_by === "created_at" && 
                        <svg className={styles.history_sort_btn} viewBox="6 6 11 11" onClick={() => {historyParams.sort_by = "created_at_desc"; getConnectionHistory() }} >
                        <path d="M8 15H16L12 8" />
                        </svg>}
                        { historyParams.sort_by === "created_at_desc" && 
                        <svg className={styles.history_sort_btn} viewBox="6 6 11 11" onClick={() => {historyParams.sort_by = "created_at"; getConnectionHistory() }} >
                        <path d="M8 9H16L12 16" />
                        </svg>
                        }
                    </span>
                    <span>Status</span>
                </div>
                <div className={styles.device_log_content}>
                {actionMsg}
                { alertRecords.map((data, index) => {
                    return (
                        <div className={styles.device_log_data} key={index}>
                            <span>{new Date(data.created_at).toLocaleDateString('sv') + " " + new Date(data.created_at).toLocaleTimeString('sv')}</span>
                            <span>{data.msg}</span>
                        </div>
                    )
                })}
                </div>
            </div>
            }
            { isHistoryFilterActive === "scheduled" && 
            <div className={styles.device_history_log_container}>
                <div className={styles.device_log_header}>
                    <span>
                        Time
                        { historyParams.sort_by === "created_at" && 
                        <svg className={styles.history_sort_btn} viewBox="6 6 11 11" onClick={() => {historyParams.sort_by = "created_at_desc"; getConnectionHistory() }} >
                        <path d="M8 15H16L12 8" />
                        </svg>}
                        { historyParams.sort_by === "created_at_desc" && 
                        <svg className={styles.history_sort_btn} viewBox="6 6 11 11" onClick={() => {historyParams.sort_by = "created_at"; getConnectionHistory() }} >
                        <path d="M8 9H16L12 16" />
                        </svg>
                        }
                    </span>
                    <span>Status</span>
                </div>
                <div className={styles.device_log_content}>
                {actionMsg}
                { scheduledControlRecords.map((data, index) => {
                    return (
                        <div className={styles.device_log_data} key={index}>
                            <span>{new Date(data.created_at).toLocaleDateString('sv') + " " + new Date(data.created_at).toLocaleTimeString('sv')}</span>
                            <span>{data.msg}</span>
                        </div>
                    )
                })}
                </div>
            </div>
            }
        </div> 
    )
}

export default HistoryPage