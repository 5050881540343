import React, { useState, useEffect, useRef, useCallback } from "react";
import { 
    apiUrl,
    apiGetDevices,
    apiModifyDevice,
    apiGetDeviceMonitor,
    apiGetMonitorHistroy,
    apiGetBmcLiteInfo,
    apiPowerOnDevice,
    apiPowerOffDevice,
    apiRebootDevice,
    apiUpgradeBmcLite,
    apiGetDeviceControlSchedule,
    apiCreateDeviceControlSchedule,
    apiDeleteDeviceControlSchedule } from "../../../api";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchBar } from "../../../components/SearchBar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from '../../Admin/Devices/AdminDevice.module.scss'
import { 
    ModifyButton, 
    HistoryButton,
    UpgradeButton,
    RebootButton,
    PowerButton, 
    YesButton,
    NoButton,
    ScheduleControlButton,
    AddScheduleButton,
    DeleteScheduleButton} from '../../../components/ActionButton';
import webSocket from 'socket.io-client';
import { PageLoader } from "../../../components/Loader";
import HistoryPage from "../../../components/HistoryPage";

let hasMoreScroll = true, 
    delayTime = 0,
    uuidList = [],
    wsDevData,
    socket,
    newShceduleData = {},
    deviceParams = {
        keyword: null,
        is_broker_connected: null,
        sort_by: "created_at_desc",
        skip: 0,
        limit: 1000
    }

const DevicePage = (props) => {
    const accessToken = JSON.parse(localStorage.getItem('access_token'))
    const [ devicesData, setDevicesData ] = useState([])
    const [ isOnClick, setIsOnClick ] = useState({click: "", index: ""})
    const [ actionMsg, setActionMsg ] = useState("")
    const [ moreInfo, setMoreInfo ] = useState([])
    const [ monitorData, setMonitorData ] = useState({bmclite: "", device: ""})
    const [ latestVersion, setLatestVersion ] = useState([])
    const [ isHistoryFilterActive, setIsHistoryFilterActive ] = useState("oob")
    const [ newDeviceData, setNewDeviceData ] = useState({
        name: "",
        uuid: "",
        company_id: "",
        description: "",
        bmc_lite_model_id: ""
    })
    const [ searchWords, setSearchWords ] = useState()

    const connectWebSocket = async () => {
        // socket = webSocket('http://192.168.5.214:8000/bmc-lites',{
        socket = webSocket(`${apiUrl}/bmc-lites`, {
            auth: {
                token: accessToken,
            },
        })
    }

    const wsDataUpdate = (data) => {
        const index = wsDevData.findIndex((dev) => dev.uuid === data.uuid)
        wsDevData[index] = {...wsDevData[index], ...data } 
        setDevicesData([...wsDevData])
    }

    const initWebSocket = (uuid) => {
        socket.on('connect', () => {
            socket.emit('subscribe', uuid);
            // console.log('connect')
        })

        socket.on('monitor', (data) => {
            const index = wsDevData.findIndex((dev) => dev.uuid === data.uuid)
            data.monitor.is_power_on = wsDevData[index].monitor.is_power_on
            wsDataUpdate(data)
            // console.log('monitor', data);
        });

        socket.on('update_status', (data) => {
            wsDataUpdate(data)
            // console.log('update_status', data);
        });

        socket.on('monitor_indication', (data) => {
            wsDataUpdate(data)
            // console.log('monitor_indication', data);
        });

        socket.on('connection', (data) => {
            wsDataUpdate(data)
            // console.log('connection', data);
        })

        socket.on('info', (data) => {
            wsDataUpdate(data)
            // console.log('info', data);
        })

        socket.on('disconnect', () => {
            // console.log('disconnect');
        })
    }

  

    const getAllDevices = async () => {
        uuidList = []
        await apiGetDevices( {
            'Authorization': `Bearer ${accessToken}`
            },
            deviceParams
        )
        .then(res => {
            setDevicesData(res.data.data)
            hasMoreScroll = false
            wsDevData = res.data.data
                res.data.data.forEach(data => {
                    uuidList.push(data.uuid)
                })
            if (!socket) {
                connectWebSocket() 
            } 
        })
        .catch(err => {
            setDevicesData()
        })
        delayTime = 0
    }


    useEffect(() => {
        if (socket) {
            initWebSocket(uuidList)
        }
    },[socket])

    useEffect(() => {   
        getAllDevices()
        
        return () => {
            delayTime = 0
            hasMoreScroll = true
            uuidList = []
            socket && socket.disconnect()
            socket = undefined
            deviceParams = {
                keyword: null,
                is_broker_connected: null,
                sort_by: "created_at_desc",
                skip: 0,
                limit: 1000
            }
        }
    },[accessToken])


    const handleEditOnChange = (e) => {
        setNewDeviceData({
            ...newDeviceData,
            [e.target.name]: e.target.value
        })
    }



    const initModifyDevice = (data, index) => {
        setMoreInfo([])
        setActionMsg("")
        setNewDeviceData({
            name: data.name,
            description: data.description,
            company_id: data.company_id
        })
        setIsOnClick({click: "edit", index: index})
    }

    const submitEditDeviceData = (uuid) => {
        const UpdateDevice = async() => {
            await apiModifyDevice({
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }},
                newDeviceData,
                uuid
            )
            .then(res => {
                devicesData[isOnClick.index] = {
                    ...devicesData[isOnClick.index],
                    name: res.data.name,
                    company_id: res.data.company_id,
                    company_name: res.data.company_name,
                }
                setIsOnClick({click: "", index: ""})
            })
            .catch(err => {
                // console.log('Edit device failed, check data is valid or existing.')
                setActionMsg(<div className={styles.device_err_msg}>Check data is valid or existing</div>)
            })
        }
        UpdateDevice()
    }

    const handleDevicePowerOnClick = (data, power, index) => {
        setIsOnClick({click: "loading", index: index})
        setActionMsg(
        <div className={styles.action_note}>
            Power {data.monitor.is_power_on ? "off" : "on"}...
        </div>)
        if (power === 'on') {
            apiPowerOnDevice({
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }},
                data.uuid
            ).then(res => {
                // devicesData[index].monitor.is_power_on = !devicesData[index].monitor.is_power_on
                // setDevicesData([...devicesData])
                setIsOnClick({click: "", index: ""})
            })
        } else if (power === 'off') {
            apiPowerOffDevice({
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }},
                data.uuid
            ).then(res => {
                // devicesData[index].monitor.is_power_on = !devicesData[index].monitor.is_power_on
                // setDevicesData([...devicesData])
                setIsOnClick({click: "", index: ""})
            })
        } else if (power === 'reboot') {
            apiRebootDevice({
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }},
                data.uuid
            ).then(res => {
                setIsOnClick({click: "", index: ""})
            })
        }
    }

    const flashBmcLite = async (uuid, index) => {
        setIsOnClick({click: "loading", index: index})
        setActionMsg(
            <div className={styles.action_note}>
                Loading...
            </div>)
        await apiUpgradeBmcLite({
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }},
            uuid
        ).then(res => {
            setIsOnClick({click: "", index: ""})
        })
    }


    const handleMoreInfoOnClick = async (uuid) => {
        setMonitorData({device: "", bmclite: ""})
        let bmc_data
        await apiGetBmcLiteInfo({
            headers : {
                'Authorization': `Bearer ${accessToken}`
            }
        },
        uuid
        ).then(res => {
            bmc_data = res.data
        })

        await apiGetDeviceMonitor({
            headers : {
                'Authorization': `Bearer ${accessToken}`
            }
        },
        uuid
        ).then(res => {
            setMonitorData(
                {device: res.data,
                bmclite: bmc_data})
        })
    }
    
    // Search device

    useEffect(() => {
        if (searchWords) {
            const searchTimeout = setTimeout(() => {
            socket && socket.disconnect()
            socket = undefined
            deviceParams.keyword = searchWords
            getAllDevices()
        }, delayTime);
        return () => {clearTimeout(searchTimeout);}
        // return () => {clearTimeout(searchTimeout); updateDataTimerRef.current = setInterval(myIntervalFn(), reloadDataTime);}
        }
    }, [searchWords])

    const handleSearchDevicesOnChange = (e) => {
        // clearInterval(updateDataTimerRef.current)
        delayTime = 500
        const value = e.target.value
        setSearchWords(value)
        if (!value) {
            deviceParams.keyword = null
            getAllDevices()
        }

        setMoreInfo([])
        setIsOnClick({click: "", index: ""})
    }


    const handleFilterAllDevicesOnClick = () => {
        socket && socket.disconnect()
        socket = undefined
        setMoreInfo([])
        deviceParams.is_broker_connected = null
        deviceParams.keyword = null
        getAllDevices()

        document.getElementById("search_input").value = ""
        setIsOnClick({click: "", index: ""})
     
    }
    
    const handleFilterOnClick = (bool) => {
        socket && socket.disconnect()
        socket = undefined
        deviceParams.is_broker_connected = bool
        deviceParams.keyword = null
        setMoreInfo([])
        setIsOnClick({click: "", index: ""})
        getAllDevices()
        hasMoreScroll = false
        document.getElementById("search_input").value = ""
      }

   
    // END Search device

    let scroll_height = "77vh"
    const setHeight = (e) => {
        scroll_height = e
    }


    const handleGetHistoryOnClick = (index) => {
        devicesData[index].company_name = props.me.company_name
        setIsOnClick({click: "history", index: index})
        setActionMsg(<div className={styles.action_note}>Get History Data...</div>)
        socket && socket.disconnect()
        socket = undefined
    }

    const [ pickScheduleDateTime, setPickScheduleDateTime ] = useState()
    const [ scheduleData, setScheduleData ] = useState([])

    const handleScheduleOnClick = (uuid, index) => {
        setPickScheduleDateTime()
        if (moreInfo.filter(showInfo => showInfo === index).length !== 0) {
            moreInfo.splice(moreInfo.indexOf(index), 1)
            setMoreInfo([...moreInfo])
        }
        setIsOnClick({click: "loading", index: index});
        setActionMsg(<div className={styles.action_note}>Get Schedule Data...</div>)
        newShceduleData = {
            scheduled_date: null,
            task: "power_on",
            recurrent: "daily"
        }
        getSchedule(uuid, index)
    }

    const addSchedule = async (uuid, index) => {
        await apiCreateDeviceControlSchedule({
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }},
            newShceduleData,
            uuid
        ).then(res => {
            setPickScheduleDateTime()
            newShceduleData.scheduled_date = null
            getSchedule(uuid, index)
        }).catch(err => {
            setActionMsg(<div className={styles.action_note} style={{color: 'red'}}>{err.response.data.detail}</div>)
        })
    }

    const deleteSchedule = async (uuid, id, index) => {
        await apiDeleteDeviceControlSchedule(
            {
                'Authorization': `Bearer ${accessToken}`
            },
            uuid,
            id
        ).then(res => {
            getSchedule(uuid, index)
        }).catch(err => {
            setActionMsg(<div className={styles.action_note} style={{color: 'red'}}>{err.response.data.detail}</div>)
        })
    }

    const getSchedule = async (uuid, index) => {
        await apiGetDeviceControlSchedule({
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }},
            uuid
        ).then(res => {
            setIsOnClick({click: "schedule", index: index})
            setActionMsg(<div className={styles.action_note}>Power Contorl Schedule</div>)
            setScheduleData(res.data)
        }).catch(err => {
            setIsOnClick({click: "schedule", index: index})
            setScheduleData([])
            setActionMsg(<div className={styles.action_note} style={{color: 'red'}}>{err.response.data.detail}</div>)
        })
    }

    

    return (
        <>
        { isOnClick.click === "history" && isOnClick.index !== "" && 
        <HistoryPage device_data={devicesData[isOnClick.index]} back={(data) => {setIsOnClick(data); if (!socket) connectWebSocket() }} />
        }
        <div className={styles.device_page} style={{display: isOnClick.click === "history" && 'none'}}>
            <SearchBar 
                name="Devices"
                all={handleFilterAllDevicesOnClick}
                search={handleSearchDevicesOnChange}
                filter={handleFilterOnClick}
            />
            <div className={styles.device_header}>
                <span>Device</span>
                <span>Company</span>
                {/* <span>UUID</span> */}
                <span>Information</span>
                <span>Status</span>
                <span>Action</span>
            </div>            

            <div className={styles.device_content}>
                { devicesData && 
                <InfiniteScroll
                    style={{"overflowY":"overlay", "overflowX":"hidden"}}
                    dataLength={devicesData.length} 
                    next={undefined}
                    hasMore={hasMoreScroll}
                    loader={<PageLoader /> }
                    height={scroll_height}
                >
                { devicesData.map((data, index) => {
                return (
                    <div key={data.uuid}>
                        <div className={styles.device_data} key={data.uuid} style={{position: isOnClick.click === "schedule" && isOnClick.index === index && 'relative', zIndex: isOnClick.click === "schedule" && isOnClick.index === index && '3'}}>
                        { isOnClick.click === "edit" && isOnClick.index === index ?
                        <>
                            <span>
                                <input id="edit_device_name" name="name" placeholder="Device Name" style={{width: "90%"}} defaultValue={data.name} onChange={handleEditOnChange}/>
                            </span>
                            <span>{props.me.company_name}</span>
                            <span>
                                <div className={styles.device_info_name}>UUID:</div>
                                <div className={styles.device_info}>{data.uuid}</div>
                                <br/>
                                <div className={styles.device_info_name}>Model:</div>
                                <div className={styles.device_info}>{data.bmc_lite_model_name}</div>
                            </span>
                            <span>
                                <div className={styles.device_status_name}>OOB Status:</div>
                                <div className={styles.device_status} style={{width: '32%'}}>{data.is_broker_connected ? <span className={styles.online_status}>Online</span> : <span className={styles.offline_status}>Offline</span>}</div>
                                <br/>
                                { data.is_broker_connected && 
                                <>
                                <div className={styles.device_status_name}>OOB Version:</div>
                                <div className={styles.device_status} style={{width: '32%'}}>{data.version}
                                    { data.is_update_available && 
                                    <svg className={styles.device_version_note} viewBox="0 0 24 24">
                                        <title>New version {data.latest_version} is available!</title>
                                        <path d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
                                    </svg> }
                                </div>
                                <br/>
                                </> }
                            </span>
                            <span>
                                <YesButton onClick={() => submitEditDeviceData(data.uuid)}/>
                                <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                                <div className={styles.action_note}>Modify</div>
                            </span>
                        </>
                        :
                        <>
                            <span>{data.name}</span>
                            <span>{props.me.company_name}</span>
                            <span>
                                <div className={styles.device_info_name}>UUID:</div>
                                <div className={styles.device_info}>{data.uuid}</div>
                                <br/>
                                <div className={styles.device_info_name}>Model:</div>
                                <div className={styles.device_info}>{data.bmc_lite_model_name}</div>
                                { isOnClick.click !== "shcedule" && isOnClick.index !== index && 
                                <>
                                { moreInfo.filter(showInfo => showInfo === index).length === 0 ?
                                <div style={{bottom: "1.2em", position: "absolute", width: "100%"}}>
                                    <svg className={styles.more_info_btn} viewBox="6 5 12 12" onClick={() => {moreInfo.push(index); setMoreInfo([...moreInfo]); }}>    
                                        <title>More info</title>
                                        <path d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z" />
                                    </svg>
                                </div> 
                                :
                                <div style={{bottom: "1.2em", position: "absolute", width: "100%"}}>
                                    <svg className={styles.less_info_btn} viewBox="5.5 4 13 13" onClick={() => {moreInfo.splice(moreInfo.indexOf(index), 1); setMoreInfo([...moreInfo])}}>
                                        <title>Less info</title>
                                        <path d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
                                    </svg>
                                </div>
                                }
                                </>
                                }
                                
                                { moreInfo.filter(showInfo => showInfo === index).length !== 0 && 
                                <>
                                <br/>
                                <div className={styles.device_info_name}>Credential:</div>
                                <div className={styles.device_info}>{data.credential_name ? data.credential_name : 'N/A'}</div>
                                <br/>
                                <div className={styles.device_info_name}>Created:</div>
                                <div className={styles.device_info}>{new Date(data.created_at).toLocaleDateString('sv') + " " + new Date(data.created_at).toLocaleTimeString('sv')}</div>
                                <br/>
                                <div className={styles.device_info_name}>Updated:</div>
                                <div className={styles.device_info}>{data.updated_at ? new Date(data.updated_at).toLocaleDateString('sv') + " " + new Date(data.updated_at).toLocaleTimeString('sv') : 'N/A'}</div>
                                { data.ip && 
                                <>
                                <br/>
                                <div className={styles.device_info_name}>IP:</div>
                                <div className={styles.device_info}>{data.ip}</div>
                                </>
                                }
                                { data.description && 
                                <>
                                <br/>
                                <div className={styles.device_info_name}>Description:</div>
                                <div className={styles.device_info}>{data.description}</div>
                                </>
                                }
                                </>
                                }
                            </span>
                            <span>
                                <div className={styles.device_status_name}>OOB Status:</div>
                                <div className={styles.device_status} style={{width: "32%"}}>{data.is_broker_connected ? <span className={styles.online_status}>Online</span> : <span className={styles.offline_status}>Offline</span>}</div>

                                { data.is_broker_connected && 
                                <>
                                <div className={styles.device_status_name}>OOB Version:</div>
                                <div className={styles.device_status} style={{width: "32%"}}>{data.version}
                                    { data.is_update_available && 
                                    <svg className={styles.device_version_note} viewBox="0 0 24 24">
                                        <title>New version {data.latest_version} is available!</title>
                                        <path d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
                                    </svg> }
                                </div>
                                <br/>
                                { moreInfo.filter(showInfo => showInfo === index).length !== 0 && 
                                <>
                                <div className={styles.device_status_name}>OOB System Usage</div>
                                <ul >
                                    <li><div className={styles.device_status_name}>CPU:</div><div className={styles.device_status}>{data.cpu_usage + " %"}</div></li>
                                    <li><div className={styles.device_status_name}>Memory:</div><div className={styles.device_status}>{data.mem_usage + " %"}</div></li>
                                </ul>
                                <div className={styles.device_status_name} style={{"fontWeight":"bold"}}>Device H/W Monitor</div>
                                <br/>
                                <div className={styles.device_status_name}>Power:</div>
                                <div className={styles.device_status} style={{width: "32%"}}>{data.monitor.is_power_on ? "On" : "Off"}</div>
                                <br/>
                                { data.monitor.is_power_on && 
                                <>
                                { data.monitor.voltage[0] && 
                                <>
                                <div className={styles.device_status_name}>Voltage</div>
                                <ul>
                                { data.monitor.voltage.map((data, index) => {
                                    return (
                                    <li key={index}><div className={styles.device_status_name}>{data.name}:</div><div className={styles.device_status}>{data.value / 1000 + " V"}</div></li>
                                    )
                                })}
                                </ul>
                                </> }
                                
                                { data.monitor.fan_speed[0] && 
                                <>
                                <div className={styles.device_status_name}>Fan</div>
                                <ul >
                                { data.monitor.fan_speed.map((data, index) => {
                                    return (
                                    <li key={index}><div className={styles.device_status_name}>{data.name}:</div><div className={styles.device_status}>{data.value + " rpm"}</div></li>
                                    )
                                })}
                                </ul>
                                </> }

                                { data.monitor.temperature[0] && 
                                <>
                                <div className={styles.device_status_name}>Temperature</div>
                                <ul>
                                    { data.monitor.temperature.map((data, index) => {
                                    return (
                                    <li key={index}><div className={styles.device_status_name}>{data.name}:</div><div className={styles.device_status}>{data.value + " ℃"}</div></li>
                                    )
                                    })}
                                </ul>
                                </> }
                                </>
                                }
                                </> 
                                }
                                </> 
                                }
                                
                            </span>
                            { isOnClick.click === "flash" && isOnClick.index === index && 
                            <span>
                                <YesButton onClick={() => flashBmcLite(data.uuid, index)}/>
                                <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                                <div className={styles.action_note}>
                                    Upgrade
                                </div>
                            </span> }
                            { isOnClick.click === "power" && isOnClick.index === index && 
                            <span>
                                <YesButton onClick={() => handleDevicePowerOnClick(data, data.monitor.is_power_on ? "off" : "on", index) }/>
                                <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                                <div className={styles.action_note}>Power {data.monitor.is_power_on ? "off" : "on"}</div>
                            </span>
                            }
                            { isOnClick.click === "reboot" && isOnClick.index === index && 
                            <span>
                                <YesButton onClick={() => handleDevicePowerOnClick(data, 'reboot', index) }/>
                                <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                                <div className={styles.action_note}>Reboot</div>
                            </span>
                            }
                            { isOnClick.click === "loading" && isOnClick.index === index && 
                            <span>
                                <div className={styles.loader_device}></div>
                                {actionMsg}
                            </span>
                            }
                            { isOnClick.click === "schedule" && isOnClick.index === index && 
                            <span>
                                <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                                {actionMsg}
                            </span>
                            }
                            { data.update_status !== "Idle" &&
                            <span>
                                <div className={styles.action_note}>
                                    Firmware {data.update_status}
                                </div>
                                <div className={styles.loader_device}></div>
                            </span> }
                            
                            { isOnClick.index !== index && data.update_status === "Idle" &&
                            <span>
                                { data.is_broker_connected ? 
                                <>
                                <PowerButton status={data.monitor.is_power_on} onClick={() => setIsOnClick({click: "power", index: index})} />
                                <RebootButton onClick={() => setIsOnClick({click: "reboot", index: index})} />
                                <UpgradeButton disabled={data.is_update_available ? false : true} onClick={() => data.is_update_available ? setIsOnClick({click: "flash", index: index}) : undefined} />
                                <ScheduleControlButton onClick={() => {handleScheduleOnClick(data.uuid, index); }}/>
                                </>
                                :
                                <>
                                <PowerButton disabled />
                                <RebootButton disabled />
                                <UpgradeButton disabled />
                                <ScheduleControlButton disabled />
                                </>
                                }
                                <HistoryButton onClick={() => handleGetHistoryOnClick(index)}/>
                                <ModifyButton onClick={() => initModifyDevice(data, index)}/>
                            </span> }

                            { isOnClick.click === "schedule" && isOnClick.index === index && 
                            <div className={styles.device_bottom_box}>
                                <div style={{fontWeight: 'bold', fontSize: '1vw', margin: '1% 0'}}>Power Control Schedule</div>
                                <div className={styles.schedule_container}>
                                    <div className={styles.schedule_box}>
                                        <div style={{paddingRight: '0', paddingLeft: '5%'}}>
                                            <div className={styles.schedule_data} >
                                                <div style={{marginRight: 'auto'}}>Date</div>
                                                <DatePicker 
                                                    id="add_schedule_date"
                                                    placeholderText=" Select a date"
                                                    className={styles.schedule_date_picker}
                                                    selected={pickScheduleDateTime}
                                                    dateFormat='yyyy-MM-dd HH:mm'
                                                    timeFormat='HH:mm'
                                                    timeIntervals={1}
                                                    showTimeSelect
                                                    onChange={date => {newShceduleData.scheduled_date = date; setPickScheduleDateTime(date); }}
                                                />
                                            </div>
                                            <div className={styles.schedule_data} >
                                                <div>Power</div> 
                                                <select id="add_schedule_power" onChange={e => newShceduleData.task = e.target.value}>
                                                    <option value="power_on">Power On</option>
                                                    <option value="power_off">Power Off</option>
                                                    <option value="power_reboot">Reboot</option>
                                                </select>
                                            </div>
                                            <div className={styles.schedule_data} >
                                                <div >Repetitive</div>
                                                <select id="add_schedule_event" onChange={e => newShceduleData.recurrent = e.target.value}>
                                                    <option value="daily">Daily</option>
                                                    <option value="monthly">Monthly</option>
                                                    <option value="never">Once</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className={styles.add_schedule_btn} title="Add Schedule" onClick={() => addSchedule(data.uuid, index)}>
                                            <AddScheduleButton />
                                        </div>
                                    </div>
                                    <div style={{margin: '0 1%', borderRight: '1px solid #a4a4a4'}}></div>
                                    <div className={styles.schedule_area}>
                                    { scheduleData.map((schedule, schedule_index) => {
                                        return (
                                            <div className={styles.schedule_box} key={schedule_index}>
                                                <div style={{paddingRight: '0', paddingLeft: '5%'}}>
                                                    <div className={styles.schedule_data} >
                                                        <div style={{marginRight: 'auto'}}>Date</div>
                                                        <div>
                                                            {new Date(schedule.scheduled_date).toLocaleDateString('sv') + " " + new Date(schedule.scheduled_date).toLocaleTimeString('sv')}
                                                        </div>
                                                    </div>
                                                    <div className={styles.schedule_data} style={{textTransform: 'capitalize'}}>
                                                        <div>Power</div> 
                                                        <div>{schedule.task.replace('_', ' ')}</div>
                                                    </div>
                                                    <div className={styles.schedule_data} style={{textTransform: 'capitalize'}}>
                                                        <div >Repetitive</div>
                                                        <div>{schedule.recurrent === "never" ? "Once" : schedule.recurrent}</div>
                                                    </div>
                                                </div>
                                                <div className={styles.add_schedule_btn} title="Delete Schedule" 
                                                    onClick={() => deleteSchedule(data.uuid, schedule.index, index)}>
                                                    <DeleteScheduleButton />
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </div>
                                    
                                </div>
                            </div>
                            }
                            
                        </> }
                        </div>
                    </div>
                )
                })}
                </InfiniteScroll> }
            </div>
        </div>
        </>
    )
}
export default DevicePage