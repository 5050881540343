import { apiGetUserMe } from "../../api"
export const CheckOwner = async() => {
    const accessToken = JSON.parse(localStorage.getItem('access_token'))
    const response = await apiGetUserMe({
        headers: {
            'Authorization': `bearer ${accessToken}`
        }
    }
    )
    
    const data = await response.data
    return data
}