import { Link } from "react-router-dom";
import './style.scss'

const PageNotFound = (props) => {
    return (
        <div className="page_not_found_page">
            <div className="page_not_found_wrap">
                <div className="code_404_wrap">
                    <div className="code_404-1"><span>4</span></div>
                    <div className="code_404-2"><span>0</span></div>
                    <div className="code_404-3"><span>4</span></div>
                </div>
                <div className="page_not_found_text">
                    <span>|</span>
                    <span>Page not found.</span>
                </div>
            </div>
            {JSON.stringify(localStorage.getItem('access_token')).length > 0 &&
                <div className="return_to_dash_btn">
                    <Link to='/'>
                        <span>Back to Dashboard</span>
                    </Link>
                </div>
            }
        </div>
    );
};

export default PageNotFound;