import React, { useState } from "react";
import { useEffect } from "react";
import { 
        apiAdminGetDevices,
        apiAdminGetStatistics,
        apiModifyUserMe,
        apiGetMeSessions,
        apiDeleteMeSession,
        apiClearMeSessions,
        apiAdminGetMQTTBroker,
        apiGetStatistics
    } from "../../../api"
import ReactECharts  from "echarts-for-react";
import styles from './AdminDashboard.module.scss'
import './AdminDashboard.module.scss';

const Dashboard = ({me}) => {
    const accessToken = JSON.parse(localStorage.getItem('access_token'))
    const [ totalDevice, setTotalDevice ] = useState()
    const [ onlineDevice, setOnlineDevice ] = useState()
    const [ latestVersion, setLatestVersion ] = useState()
    const [ statisticsData, setStatisticsData ] = useState()
    const [ editProfile, setEditProfile ] = useState('init')
    const [ mqttInfo, setMqttInfo ] = useState({});

    const handleEditProfile = (e) => {
        e.stopPropagation();
        editProfile === 'init' ? setEditProfile(true) : setEditProfile(!editProfile);
    };

    const getMQTTInfo = async () => {
        await apiAdminGetMQTTBroker({headers: {'Authorization': `Bearer ${accessToken}`}})
        .then(res => {
            console.log(res)
            setMqttInfo(res.data);
        })
    }

    const getStatistics =  async () => {
        await apiAdminGetStatistics({headers: {'Authorization': `Bearer ${accessToken}`}}
        ).then(res => {
            setStatisticsData(res.data)
        })
    };
    const normalUserGetStatistics =  async () => {
        await apiGetStatistics({headers: {'Authorization': `Bearer ${accessToken}`}}
        ).then(res => {
            setStatisticsData(res.data)
        })
    };

    const getDevices = async () => {
        await apiAdminGetDevices({'Authorization': `Bearer ${accessToken}`},
            {
                keyword: null,
                is_broker_connected: null,
                company_id: null,
                bmc_lite_model_id: null,
                sort_by: "created_at_desc",
                skip: 0,
                limit: 1
            }
        )
        .then(res => {
            setTotalDevice(res.data.metadata.total)
        })
        .catch(err => {
            setTotalDevice(0)
        })
    }
    
    const getOnlineDevices = async (keywords) => {
        await apiAdminGetDevices( {
            'Authorization': `Bearer ${accessToken}`
            },
            0,
            1,
            keywords,
            undefined,
            undefined,
            true
        )
        .then(res => {
            setOnlineDevice(res.data.metadata.total)
        })
        .catch(err => {
            setOnlineDevice(0)
        })
    }

    useEffect(() => {
        if(me.is_superuser){
            getStatistics()
            getDevices()
            getOnlineDevices()
            getMQTTInfo();
        } else {
            normalUserGetStatistics();
        };
    },[accessToken])

    console.log(me)

    const power_pie = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
        //   top: '5%',
            left: 'center',
            textStyle: {
                fontSize: '1vw'
            }
        },
        series: [{
            name: 'Devices',
            type: 'pie',
            color: ['rgb(120, 225, 120)', 'red'],
            top: '5%',
            left: 'center',
            height: '100%',
            width: '100%',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '0.6vw',
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            // data: [
            //     { value: totalDevice ? totalDevice.filter(device => device.device_status.power === true && device.OOB.enable === true).length : 0, name: 'Power On' },
            //     { value: totalDevice ? totalDevice.filter(device => device.device_status.power === false && device.OOB.enable === true).length : 0, name: 'Power Off' }
            // ]
        }]
    };
    
    const connection_pie = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: '0%',
            left: 'center',
            textStyle: {
                fontSize: '1vw'
            }
        },
        series: [{
            name: 'Devices',
            type: 'pie',
            color: ['#486E4A', '#486e4a59'],
            top: '-10%',
            left: 'center',
            height: '100%',
            width: '100%',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '0.6vw',
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            data: [
                {
                    // value: statisticsData && statisticsData.bmc_lite.online_count,
                    value: statisticsData ? statisticsData.bmc_lite ? statisticsData.bmc_lite.online_count : 0 : 0,
                    name: 'Online'
                },
                { 
                    value: statisticsData ? statisticsData.bmc_lite ? statisticsData.bmc_lite.offline_count : 0 : 0,
                    name: 'Offline'
                }
            ]
        }]
    };

    const models_pie = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: '0%',
            left: 'center',
            textStyle: {
                fontSize: '1vw'
            }
        },
        series: [{
            name: 'Devices',
            type: 'pie',
            color: ['#486E4A', '#486e4a59'],
            top: '-10%',
            left: 'center',
            height: '100%',
            width: '100%',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '0.6vw',
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            data: [
                // { value: statisticsData && statisticsData.bmc_lite.models[0].count, name: 'NUC980' },
                { 
                    value: statisticsData ? statisticsData.bmc_lite ? statisticsData.bmc_lite.models[0].count : 0 : 0, 
                    name: 'CA-R570'
                }
            ]
        }]
    }

    const auth_pie = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: '-5%',
            left: 'center',
            textStyle: {
                fontSize: '1vw'
            }
        },
        series: [{
            name: 'Devices',
            type: 'pie',
            color: ['#486E4A', '#486e4a59'],
            top: '-10%',
            left: 'center',
            height: '100%',
            width: '100%',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '0.6vw',
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            data: [
                { 
                    // value: statisticsData && statisticsData.bmc_lite.has_credential_count, 
                    value: statisticsData ? statisticsData.bmc_lite ? statisticsData.bmc_lite.has_credential_count : 0  : 0,
                    name: 'Credentialed'
                },
                { 
                    // value: statisticsData && statisticsData.bmc_lite.no_credential_count, 
                    value: statisticsData ? statisticsData.bmc_lite ? statisticsData.bmc_lite.no_credential_count : 0  : 0,
                    name: 'Non-credential'
                }
            ]
        }]
    }

    const user_pie = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: '-5%',
            left: 'center',
            textStyle: {
                fontSize: '1vw'
            }
        },
        series: [{
            name: 'Devices',
            type: 'pie',
            color: ['#486E4A', '#486e4a59'],
            top: '-10%',
            left: 'center',
            height: '100%',
            width: '100%',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '0.6vw',
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            data: [
                { 
                    // value: statisticsData && statisticsData.user.enabled_count, 
                    value: statisticsData ? statisticsData.user ? statisticsData.user.enabled_count : 0  : 0,
                    name: 'Enable' 
                },
                { 
                    // value: statisticsData && statisticsData.user.disabled_count,
                    value: statisticsData ? statisticsData.user ? statisticsData.user.disabled_count : 0  : 0,
                    name: 'Disable'
                }
            ]
        }]
    }

    const credentials_pie = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: '0%',
            left: 'center',
            textStyle: {
                fontSize: '1vw'
            }
        },
        series: [{
            name: 'Devices',
            type: 'pie',
            color: ['#486E4A', '#486e4a59'],
            top: '-10%',
            left: 'center',
            height: '100%',
            width: '100%',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '0.6vw',
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            data: [
                { value: statisticsData && statisticsData.credential && statisticsData.credential.unexpired_count, name: 'Valid' },
                { value: statisticsData && statisticsData.credential && statisticsData.credential.expired_count, name: 'Expired' }
            ]
        }]
    }

    const versions_bar = {
        grid:{
            top: '20%',
            left: '15%',
            bottom: '30%',
            right: '10%'
        },
        xAxis: {
            type: 'value',
            
            axisLabel: {
                margin: 20
            }
        },
        yAxis: {
            type: 'category',
            data: [
                statisticsData && statisticsData.bmc_lite_version && statisticsData.bmc_lite_version.models[0].bmc_lite_model_name,
                statisticsData && statisticsData.bmc_lite_version && statisticsData.bmc_lite_version.models[1].bmc_lite_model_name
            ],
            axisLabel: {
                margin: 10,
                textStyle: {
                    align: 'right'
                }
            }
        },
        series: [
            {
                data: [
                    {value: statisticsData && statisticsData.bmc_lite_version && statisticsData.bmc_lite_version.models[0].count, itemStyle: {color:'#486E4A'}},
                    {value: statisticsData && statisticsData.bmc_lite_version && statisticsData.bmc_lite_version.models[1].count, itemStyle: {color:'#486e4a59'}}
                ],
                type: 'bar',
            }
        ],
    }

    console.log('statistic data', statisticsData)

    const [ isProfileEditorOpen, setIsProfileEditorOpen ] = useState(false)
    const [ editProfileData, setEditProfileData ] = useState()
    const [ meData, setMeData ] = useState(me)

    const handleProfileEditorOnChange = (e) => {
        const value = e.target.value
        if (editProfileData.phone_number === "") {
            editProfileData.phone_number = null
        }
        setEditProfileData({
            ...editProfileData,
            [e.target.name]: value
        })
    }

    const modifyMe = () => {
        apiModifyUserMe({
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        },
        editProfileData
        ).then(res => {
            setMeData(res.data)
            setIsProfileEditorOpen(false)
        }).catch(err => {
            // console.log(err)
        })
    }

    const [ isSessionManageOpen, setIsSessionManageOpen ] = useState(false)
    const [ meSessionData, setMeSessionData ] = useState([])

    const getMeSession = () => {
        apiGetMeSessions(
        {
            'Authorization': `Bearer ${accessToken}`
        }).then(res => {
            // console.log(res)
            setMeSessionData(res.data.data)
        }).catch(err => {
            // console.log(err)
        })
    }
    
    const deleteMeSession = async (session_id, index) => {
        await apiDeleteMeSession({
            headers:{
                'Authorization': `Bearer ${accessToken}`
            }},
            session_id
        ).then(res => {
            meSessionData.splice(index, 1)
            setMeSessionData([...meSessionData])
        }).catch(err => {

        })
    }
    
    const clearMeSession = async () => {
        await apiClearMeSessions({
            headers:{
                'Authorization': `Bearer ${accessToken}`
            }}
        ).then(res => {
            getMeSession()
        }).catch(err => {
        
        })
    }

    const formatDate = (date) => {
        return new Date(date).toLocaleString('sv')
    }

    return (
        <div className={me.is_superuser ? styles.dashboard_page : styles.dashboard_page_user} >
            { isProfileEditorOpen && 
            <div className={styles.profile_editor_page}>
                <div className={styles.profile_editor}>
                    <div className={styles.profile__data__input__block}>
                        <label htmlFor="full_name">Name</label>
                        <input id="full_name" name="full_name" placeholder="Name" defaultValue={meData.full_name} onChange={handleProfileEditorOnChange} />
                        <label htmlFor="profile_email">E-mail</label>
                        <input autoComplete="off" id="profile_email" name="email" placeholder="E-mail" defaultValue={meData.email} onChange={handleProfileEditorOnChange} />
                    </div>
                    <div className={styles.profile__data__input__block}>
                        <label htmlFor="phone">Phone</label>
                        <input id="phone" name="phone_number" placeholder="Phone Number" defaultValue={meData.phone_number} onChange={handleProfileEditorOnChange} />
                        <label htmlFor="description">Description</label>
                        <input id="description" name="description" placeholder="Description" defaultValue={meData.description} onChange={handleProfileEditorOnChange} />
                    </div>
                    <div className={styles.profile__data__add__block}>
                        <div className={styles.profile__add__cancel__btn} onClick={() => setIsProfileEditorOpen(false)} >
                            <span>Cancel</span>
                        </div>
                        <div className={styles.profile__add__create__btn} onClick={() => modifyMe()} >
                            <span>Edit</span>
                        </div>
                    </div>
                </div>
            </div>
            }

            { isSessionManageOpen && 
            <div className={styles.profile_editor_page}>
                <div className={styles.profile_editor} style={{flexDirection: 'column', overflowY: 'scroll'}} >
                    { meSessionData.map((data, index) => (
                    <div className={styles.session_box} key={index}>
                        { !data.is_current_session &&
                        <div style={{height: '0', textAlign: 'right'}}>
                            <svg className={styles.close_session_cross} viewBox="2 2 20 20" onClick={() => deleteMeSession(data.id, index)}>
                                <title>Close Session</title>
                                <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
                            </svg>
                        </div>
                        }
                        { data.is_current_session &&
                        <div className={styles.profile_item_current} style={{fontWeight: 'bolder'}}>
                            <span>Current Session</span>
                            <div className={styles.profile__add__create__btn} style={{width: '25%', marginRight: '1%'}} onClick={() => clearMeSession()} >
                                <span style={{width: 'unset', fontSize: '1.5vmin' }}>Clear all</span>
                            </div>
                            <div className={styles.profile__add__cancel__btn} style={{width: '25%'}} onClick={() => setIsSessionManageOpen(false)} >
                                <span style={{width: 'unset', fontSize: '1.5vmin' }}>Cancel</span>
                            </div>
                        </div>
                        }
                        <div className={styles.profile_item}>
                            <span>Browser</span>
                            <span>{data.browser}</span>
                        </div>
                        <div className={styles.profile_item}>
                            <span>OS</span>
                            <span>{data.os}</span>
                        </div>
                        <div className={styles.profile_item}>
                            <span>Device</span>
                            <span>{data.device}</span>
                        </div>
                        <div className={styles.profile_item}>
                            <span>IP</span>
                            <span>{data.ip}</span>
                        </div>
                        <div className={styles.profile_item}>
                            <span>First Login</span>
                            <span>{formatDate(data.created_at)}</span>
                        </div>
                        <div className={styles.profile_item}>
                            <span>Last Access</span>
                            <span>{formatDate(data.last_use_at)}</span>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
            }

            {statisticsData ? 
            <>  
                {me && !me.is_superuser && (
                    <>
                        <div className={styles.dashboard__row}>
                            <div className={styles.dashboard__row__title}>
                                <span>Device Information</span>
                            </div>
                            <div className={styles.dashboard__row__content}>
                                <div className={styles.dashboard__info__card}>
                                    <div className={styles.dashboard__info__card__head}>
                                        <span>Total DMS OOB</span>
                                    </div>
                                    { statisticsData && 
                                    <div className={styles.dashboard__info__card__body}>
                                        <span>{statisticsData ? statisticsData.bmc_lite != null ? statisticsData.bmc_lite.total_count : 0 : 0}</span>
                                    </div>}
                                </div>
                                <div className={styles.dashboard__info__card}>
                                    <div className={styles.dashboard__info__card__head}>
                                        <span>Connection Status</span>
                                    </div>
                                    <div className={styles.dashboard__info__card__body}>
                                        <ReactECharts
                                            option={connection_pie}
                                            style={{height: '100%', width: '100%'}}
                                        />
                                    </div>
                                </div>
                                <div className={styles.dashboard__info__card}>
                                    <div className={styles.dashboard__info__card__head}>
                                        <span>DMS OOB Models</span>
                                    </div>
                                    <div className={styles.dashboard__info__card__body}>
                                        <ReactECharts
                                            option={models_pie}
                                            style={{height: '100%', width: '100%'}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.dashboard__row}>
                            <div className={styles.dashboard__row__title}>
                                <span>Basic Information</span>
                            </div>
                            <div className={styles.dashboard__row__content}>
                                <div className={styles.dashboard__info__card}>
                                    <div className={styles.dashboard__info__card__head}>
                                        <span>Users Status</span>
                                    </div>
                                    <div className={styles.dashboard__info__card__body}>
                                        <ReactECharts
                                            option={user_pie}
                                            style={{height: '100%', width: '100%'}}
                                        />
                                    </div>
                                </div>
                                <div className={styles.dashboard__info__card}>
                                    <div className={styles.dashboard__info__card__head}>
                                        <span>User Groups</span>
                                    </div>
                                    <div className={styles.dashboard__info__card__body}>
                                        <span>{statisticsData.group ? statisticsData.group .total_count : '0'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {  me && me.is_superuser && (

                <>
                <div className={styles.dashboard__row}>
                    <div className={styles.dashboard__row__title}>
                        <span>Devices</span>
                    </div>
                    <div className={styles.dashboard__row__content}>
                        <div className={styles.dashboard__info__card}>
                            <div className={styles.dashboard__info__card__head}>
                                <span>Total OOB Devices</span>
                            </div>
                            { totalDevice && 
                            <div className={styles.dashboard__info__card__body}>
                                <span>{totalDevice}</span>
                            </div>}
                        </div>
                        <div className={styles.dashboard__info__card}>
                            <div className={styles.dashboard__info__card__head}>
                                <span>Connection Status</span>
                            </div>
                            <div className={styles.dashboard__info__card__body}>
                                <ReactECharts
                                    option={connection_pie}
                                    style={{height: '100%', width: '100%'}}
                                />
                            </div>
                        </div>
                        <div className={styles.dashboard__info__card}>
                            <div className={styles.dashboard__info__card__head}>
                                <span>Devices Status</span>
                            </div>
                            <div className={styles.dashboard__info__card__body}>
                                <ReactECharts
                                    option={auth_pie}
                                    style={{height: '100%', width: '100%'}}
                                />
                            </div>
                        </div>
                        <div className={styles.dashboard__info__card}>
                            <div className={styles.dashboard__info__card__head}>
                                <span>DMS OOB Models</span>
                            </div>
                            <div className={styles.dashboard__info__card__body}>
                                <ReactECharts
                                    option={models_pie}
                                    style={{height: '100%', width: '100%'}}
                                />
                            </div>
                        </div>
                        {/* <div className={styles.dashboard__profile__card}>
                            <div className={styles.dashboard__profile__card__head__wrap}>
                                <div className={styles.dashboard__profile__card__head}>
                                    <span>Profile</span>
                                </div>
                                <div className={styles.dashboard__profile__card__head}>
                                    <span className={styles.dashboard__profile__card__role}>Superuser</span>
                                </div>
                            </div>
                            <div className={styles.dashboard__profile__card__body} onClick={(e) => handleEditProfile(e)}>
                                <span>Administrator</span>
                                <div className={editProfile === 'init' ? styles.dashboard__edit__profile__init : editProfile ? styles.dashboard__edit__profile__open : styles.dashboard__edit__profile__close}>
                                    <div className={styles.dashboard__edit__item} onClick={(e) => {e.stopPropagation(); setIsProfileEditorOpen(true); setEditProfileData(meData); }}><span>Edit Profile</span></div>
                                    <div className={styles.dashboard__edit__item} onClick={(e) => {e.stopPropagation(); getMeSession(); setIsSessionManageOpen(true); }}><span>Edit Session</span></div>
                                    <div className={styles.dashboard__edit__item} onClick={(e) => e.stopPropagation()}><span>Logs</span></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className={styles.dashboard__row}>
                    <div className={styles.dashboard__row__title}>
                        <span>Models</span>
                    </div>
                    <div className={styles.dashboard__row__content}>
                        <div className={styles.dashboard__info__card__large}>
                            <div className={styles.dashboard__info__card__head}>
                                <span>DMS OOB Versions</span>
                            </div>
                            <div className={styles.dashboard__info__card__body}>
                                <ReactECharts
                                    option={versions_bar}
                                    style={{height: '100%', width: '100%'}}
                                />
                            </div>
                        </div>
                        <div className={styles.dashboard__info__card}>
                            <div className={styles.dashboard__info__card__head}>
                                <span>{statisticsData && statisticsData.bmc_lite_version.models[0].bmc_lite_model_name + ' Latest Ver.'}</span>
                            </div>
                            { statisticsData && statisticsData.bmc_lite_version &&
                            <div className={styles.dashboard__info__card__body__small}>
                                <span>{'v' + statisticsData.bmc_lite_version.models[0].latest_version}</span>
                            </div>}
                        </div>
                        <div className={styles.dashboard__info__card}>
                            <div className={styles.dashboard__info__card__head}>
                                <span>{statisticsData && statisticsData.bmc_lite_version.models[1].bmc_lite_model_name + ' Latest Ver.'}</span>
                            </div>
                            { statisticsData && statisticsData.bmc_lite_version &&
                            <div className={styles.dashboard__info__card__body__small}>
                                <span>{'v' + statisticsData.bmc_lite_version.models[1].latest_version}</span>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className={styles.dashboard__row}>
                    <div className={styles.dashboard__row__title}>
                        <span>Other Info</span>
                    </div>
                    <div className={styles.dashboard__row__content}>
                        <div className={styles.dashboard__info__card}>
                            <div className={styles.dashboard__info__card__head}>
                                <span>Credentials Status</span>
                            </div>
                            <div className={styles.dashboard__info__card__body}>
                                <ReactECharts
                                    option={credentials_pie}
                                    style={{height: '100%', width: '100%'}}
                                />
                            </div>
                        </div>
                        <div className={styles.dashboard__info__card}>
                            <div className={styles.dashboard__info__card__head}>
                                <span>Users Status</span>
                            </div>
                            <div className={styles.dashboard__info__card__body}>
                                <ReactECharts
                                    option={user_pie}
                                    style={{height: '100%', width: '100%'}}
                                />
                            </div>
                        </div>
                        <div className={styles.dashboard__info__card}>
                            <div className={styles.dashboard__info__card__head}>
                                <span>User Groups</span>
                            </div>
                            <div className={styles.dashboard__info__card__body}>
                                <span>{statisticsData.group.total_count}</span>
                            </div>
                        </div>
                        <div className={styles.dashboard__info__card}>
                            <div className={styles.dashboard__info__card__head}>
                                <span>Companies</span>
                            </div>
                            <div className={styles.dashboard__info__card__body}>
                                <span>{statisticsData.company.total_count}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {me && me.is_superuser && (
                    <>
                    <div className={styles.dashboard__row}>
                        <div className={styles.dashboard__row__title}>
                            <span>MQTT Broker Info</span>
                        </div>
                        <div className={styles.dashboard__row__content}>
                            <div className={styles.dashboard__info__card}>
                                <div className={styles.dashboard__info__card__head}>
                                    <span>Broker version</span>
                                </div>
                                <div className={styles.dashboard__mqtt__info__card__body}>
                                    <span>{mqttInfo.version ? mqttInfo.version : 'N/A'}</span>
                                </div>
                            </div>
                            <div className={styles.dashboard__info__card}>
                                <div className={styles.dashboard__info__card__head}>
                                    <span>Total clients</span>
                                </div>
                                <div className={styles.dashboard__mqtt__info__card__body}>
                                    <span>{mqttInfo.clients ? mqttInfo.clients.total : 0}</span>
                                </div>
                            </div>
                            <div className={styles.dashboard__info__card}>
                                <div className={styles.dashboard__info__card__head}>
                                    <span>Active clients</span>
                                </div>
                                <div className={styles.dashboard__mqtt__info__card__body}>
                                    <span>{mqttInfo.clients ? mqttInfo.clients.active : 0 }</span>
                                </div>
                            </div>
                            <div className={styles.dashboard__info__card}>
                                <div className={styles.dashboard__info__card__head}>
                                    <span>Connected clients</span>
                                </div>
                                <div className={styles.dashboard__mqtt__info__card__body}>
                                    <span>{mqttInfo.clients ? mqttInfo.clients.connected : 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.dashboard__row}>
                        <div className={styles.dashboard__row__title__blank}></div>
                        <div className={styles.dashboard__row__content}>
                            <div className={styles.dashboard__info__card}>
                                <div className={styles.dashboard__info__card__head}>
                                    <span>Uptime</span>
                                </div>
                                <div className={styles.dashboard__mqtt__info__card__body}>
                                    <span>{mqttInfo.uptime ? mqttInfo.uptime : 'Unknown'}</span>
                                </div>
                            </div>
                            <div className={styles.dashboard__info__card}>
                                <div className={styles.dashboard__info__card__head}>
                                    <span>Subscriptions</span>
                                </div>
                                <div className={styles.dashboard__mqtt__info__card__body}>
                                    <span>{mqttInfo.subscriptions ? mqttInfo.subscriptions.count : 0}</span>
                                </div>
                            </div>
                            <div className={styles.dashboard__info__card}>
                                <div className={styles.dashboard__info__card__head}>
                                    <span>PUBLISH received</span>
                                </div>
                                <div className={styles.dashboard__mqtt__info__card__body}>
                                    <span>{mqttInfo.publish ? mqttInfo.publish.messages.received : 0}</span>
                                </div>
                            </div>
                            <div className={styles.dashboard__info__card}>
                                <div className={styles.dashboard__info__card__head}>
                                    <span>PUBLISH sent</span>
                                </div>
                                <div className={styles.dashboard__mqtt__info__card__body}>
                                    <span>{mqttInfo.publish ? mqttInfo.publish.messages.sent : 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.dashboard__row}>
                        <div className={styles.dashboard__row__title__blank}></div>
                        <div className={styles.dashboard__row__content}>
                            <div className={styles.dashboard__info__card}>
                                <div className={styles.dashboard__info__card__head}>
                                    <span>Bytes received</span>
                                </div>
                                <div className={styles.dashboard__mqtt__info__card__body}>
                                    <span>{mqttInfo.bytes ? mqttInfo.bytes.received : 0}</span>
                                </div>
                            </div>
                            <div className={styles.dashboard__info__card}>
                                <div className={styles.dashboard__info__card__head}>
                                    <span>Bytes sent</span>
                                </div>
                                <div className={styles.dashboard__mqtt__info__card__body}>
                                    <span>{mqttInfo.bytes ? mqttInfo.bytes.sent : 0}</span>
                                </div>
                            </div>
                            <div className={styles.dashboard__info__card}>
                                <div className={styles.dashboard__info__card__head}>
                                    <span>Total received</span>
                                </div>
                                <div className={styles.dashboard__mqtt__info__card__body}>
                                    <span>{mqttInfo.messages ? mqttInfo.messages.received : 0}</span>
                                </div>
                            </div>
                            <div className={styles.dashboard__info__card}>
                                <div className={styles.dashboard__info__card__head}>
                                    <span>Total messages sent</span>
                                </div>
                                <div className={styles.dashboard__mqtt__info__card__body}>
                                    <span>{mqttInfo.messages ? mqttInfo.messages.sent : 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                )}
                </>
                )}
            </>
            :
                <div className={styles.dashboard__loader__animation}></div>
            }
        </div>
    )
}
export default Dashboard