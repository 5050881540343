import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"
import { apiUrl } from './api';
import LoginPage from './pages/Login';
import PageNotFound from "./components/PageNotFound";
import LINE from './pages/LINE';
import MainPage from './pages/Main';

import './App.css';
import './App.scss';

let setToken = (accessToken, refreshToken) => {
  localStorage.setItem('access_token', JSON.stringify(accessToken))
  localStorage.setItem('refresh_token', JSON.stringify(refreshToken))
};

let getToken = () => {
  const tokenString = localStorage.getItem('access_token');
  const userToken = JSON.parse(tokenString);
  return userToken;
};


function App() {

  let token = getToken();

  if (!token) {
    return (
      <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/line" element={<LINE setToken={setToken} />} />
          <Route exact path="/" element={<LoginPage setToken={setToken} />} />
          
        </Routes>
      </BrowserRouter>
    </div>)
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<MainPage />} />
          {/* User Pages */}
          <Route exact path="/dashboard" element={<MainPage showDashboard/>}  />
          <Route exact path="/devices" element={<MainPage showDevices/>}  />
          <Route exact path="/users" element={<MainPage showUsers/>}  />
          {/* Admin Pages */}
          <Route exact path="/admin/dashboard" element={<MainPage adminShowDashboard/>}  />
          <Route exact path="/admin/devices" element={<MainPage adminShowDevices/>}  />
          <Route exact path="/admin/device/:id" element={<MainPage showDeviceDetail/>}  />
          <Route exact path="/admin/users" element={<MainPage adminShowUsers/>}  />
          <Route exact path="/admin/users/:name" element={<MainPage showUserDetail/>}/>
          <Route exact path="/admin/users/:name/logs" element={<MainPage showUserDetailLogs/>}/>
          <Route exact path="/admin/companies" element={<MainPage adminShowCompanies/>}  />
          <Route exact path="/admin/oob-models" element={<MainPage adminShowFiles/>}  />
          <Route exact path="/admin/oob-models/:name" element={<MainPage showModelVersion/>}  />
          <Route exact path="/admin/oob-models/:name/:version" element={<MainPage showModelVersionNote/>}  />
          <Route exact path="/admin/credentials" element={<MainPage adminShowCredentials/>} />
          {/* <Route path="/" element={<Navigate to="/dashboard" />}  /> */}
          <Route exact path="/profile" element={<MainPage showPersonalSetting/>}  />
          <Route exact path="/profile/my-logs" element={<MainPage showPersonalLog/>}  />
          <Route exact path='*' element={<PageNotFound />} />
          {/* // <div style={{fontSize: '3vw', display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center'}}>404 Not Found</div>} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App;
