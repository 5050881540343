import { useEffect, useState } from "react";
// import { AddButton } from "../../components/AddButton";
import { SearchBar } from "../../../components/SearchBar";
import { apiAdminGetDeviceFiles,
        apiAdminGetDeviceModels,
        apiAdminUploadDeviceFiles,
        apiAdminDeleteDeviceFiles,
        apiAdminModifyDeviceFiles,
        apiAdminCreateDeviceModels,
        apiAdminDeleteDeviceModel,
        apiAdminModifyDeviceModel } from "../../../api";
import InfiniteScroll from "react-infinite-scroll-component"
import styles from './AdminFile.module.scss'
import { DeleteButton, FirmwareButton, ModifyButton, NoButton, ReleaseNoteButton, YesButton } from "../../../components/ActionButton";
import { Link } from "react-router-dom";
import { PageLoader } from "../../../components/Loader";
// import { ReleaseNotePage } from "../../../components/ReleaseNotePage";
import ReleaseNotePage from '../../../components/ReleaseNotePage';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, notification } from 'antd';
const { confirm } = Modal;

// import axios from "axios";

let addFile,
    hasMoreScroll = true,
    delayTime = 0,
    newModelData
    
let fileParams = {
    keyword: null,
    bmc_lite_model_id: null,
    sort_by: "created_at_desc",
    skip: 0,
    limit: 1000
}

let modelParams = {
    keyword: null,
    sort_by: 'name',
    skip: 0,
    limit: 1000
}

const FilePage = (props) => {
    const accessToken = JSON.parse(localStorage.getItem('access_token'))
    const [ isAddFile, setIsAddFile ] = useState(false)
    const [ actionMsg, setActionMsg ] = useState("")
    const [ isOnClick, setIsOnClick ] = useState({click: "", index: ""})
    const [ bmcLiteFilesData, setBmcLiteFilesData ] = useState([])
    const [ backupData, setBackupData ] = useState("")
    const [ modelsData, setModelsData ] = useState()
    const [ searchWords, setSearchWords ] = useState()

    const showNotification = (type, title, content) => {
        notification.open({
            type: `${type}`,
            message: `${title}`,
            description: `${content}`,
            className: 'bmc__notification'
        });
    };

    useEffect(() => {
        if(props.setActiveItem) props.setActiveItem('files')
      }, [props.setActiveItem]);

    const getAllModels = async () => {
        await apiAdminGetDeviceModels({'Authorization': `Bearer ${accessToken}`}, modelParams)
        .then(res => {
            setModelsData(res.data.data)
        })
    }

    const addDeviceModel = async () => {
        apiAdminCreateDeviceModels({headers: {'Authorization': `Bearer ${accessToken}`}}, newModelData)
        .then(res => {
            setModelsData(modelsData.concat(res.data))
            setIsOnClick({click: "", index: ""})
            showNotification('success', 'Success!', 'Successfully add a new model.')
        }).catch(err => {
            // setActionMsg(<div style={{color: 'red'}} className={styles.action_note} >{err.response.data.detail}</div>)
            showNotification('error', 'Error!', `${err.response.data.detail}`)
        })
    }

    const deleteModel = async (id, index) => {
        apiAdminDeleteDeviceModel({headers: {'Authorization': `Bearer ${accessToken}`}}, id)
        .then(res => {
            modelsData.splice(index, 1)
            setIsOnClick({click: "", index: ""})
            showNotification('success', 'Success!', 'Selected model has been deleted.')
        }).catch(err => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
        })
    }

    const modifyModel = async (id) => {
        apiAdminModifyDeviceModel({headers: {'Authorization': `Bearer ${accessToken}`}}, newModelData, id)
        .then(res => {
            modelsData[isOnClick.index].name = res.data.name
            setIsOnClick({click: "", index: ""})
            showNotification('success', 'Success!', 'Edition has been successfully done.')
        })
        .catch(err => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
        })
    }

    const [ searchModelWords, setSearchModelWords ] = useState()
    useEffect(() => {
        if (searchModelWords) {
            const searchTimeout = setTimeout(() => {
                modelParams.keyword = searchModelWords
                getAllModels()
        }, delayTime);
        return () => {clearTimeout(searchTimeout);}
        }
    }, [searchModelWords])

    const handleSearchModelsOnChange = (e) => {
        delayTime = 500
        const value = e.target.value
        setSearchModelWords(value)
        if (!value) {
            modelParams.keyword = null
            getAllModels()
        }

        setIsOnClick({click: "", index: ""})
    }

    const getAllDeviceFiles = async (keywords) => {
        await apiAdminGetDeviceFiles({
        'Authorization': `Bearer ${accessToken}`
        },
        fileParams
        ).then(res => {
            setBmcLiteFilesData(res.data.data)
            hasMoreScroll = false
        }).catch(err => {
            setBmcLiteFilesData([])
        })
        delayTime = 0
    }

    const deleteFileOnClick = async (id, index) => {
        await apiAdminDeleteDeviceFiles({headers: {'Authorization': `Bearer ${accessToken}`}}, id)
        .then(res => {
            bmcLiteFilesData.splice(index, 1)
            setIsOnClick({click: "", index: ""})
            getAllDeviceFiles()
        })
        .catch(err => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
        })
    }

    useEffect(() => {
        getAllModels()
        // getAllDeviceFiles()

        return () => {
            hasMoreScroll = true
            delayTime = 0
            fileParams = {
                keyword: null,
                bmc_lite_model_id: null,
                sort_by: "created_at_desc",
                skip: 0,
                limit: 1000
            }
            modelParams = {
                keyword: null,
                sort_by: 'name',
                skip: 0,
                limit: 1000
            }
        }
    }, [accessToken])

    let scroll_height = "77vh"
    const setHeight = (e) => {
        scroll_height = e
    }

    const initAddFile = () => {
        addFile = {
            bmc_lite_model_id : fileParams.bmc_lite_model_id,
            is_latest: false
        }
        setIsOnClick({click: "add", index: ""})
        setIsAddFile(!isAddFile)
    }

    const initAddModel = () => {
        newModelData = {
            name: null
        }
        setIsOnClick({click: "add", index: ""})
    }

    const initModifyModel = (data, index) => {
        setIsOnClick({click: "edit", index: index})
        setActionMsg(<div className={styles.action_note}>Modify</div>)
        newModelData = {
            name: data.name
        }
    }




    let newFileData 
    const initModifyFile = (data, index) => {
        setIsOnClick({click: "edit", index: index})
        setActionMsg(<div className={styles.action_note}>Modify</div>)
        newFileData = {
            is_latest: data.is_latest
        }
    }

    const fileChosen = (e) => {
        addFile = {
            ...addFile,
            file: e.target.files[0]
        }
        document.getElementById("file_chosen").textContent = e.target.files[0].name
    }

    const modifyFileOnClick = (id) => {
        apiAdminModifyDeviceFiles({headers:{'Authorization': `Bearer ${accessToken}`}}, newFileData, id)
        .then(res => {
            setIsOnClick({click: "", index: ""})
            getAllDeviceFiles()
        })
        .catch(err => {
            console.log(err.response)
            setIsOnClick({click: "", index: ""})
        })
    }

    const uploadFileOnClick = () => {
        setIsAddFile(false)
        setIsOnClick({click: "", index: ""})
        bmcLiteFilesData.splice(0, 0, {id: "uploading" ,name: "Uploading...", created_at: "---------- --:--:--", is_latest: false, uploading: true})
        setBmcLiteFilesData([...bmcLiteFilesData])
        
        apiAdminUploadDeviceFiles({
            headers:{
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data'
            }},
            addFile
        ).then(res => {
            // setBmcLiteFilesData(backupData)
            // setBmcLiteFilesData(bmcLiteFilesData.concat(res.data.detail))
            getAllDeviceFiles()
            setIsAddFile(false)
            setIsOnClick({click: "", index: ""})
        }).catch(err => {
            // console.log('err', err);
            setIsOnClick({click: "", index: ""})
            getAllDeviceFiles()
        })
    }

    const handleLatestOnChange = (e) => {
        // if (e.target.checked) {
            newFileData = {
                is_latest: true
            }
        // }
    }
    
    const handleAddFileOnChange = (e) => {
        addFile = {
            ...addFile,
            [e.target.name]: e.target.value
        }
    }

    useEffect(() => {
        if (searchWords) {
            const searchTimeout = setTimeout(() => {
                fileParams.keyword = searchWords
                getAllDeviceFiles()
        }, delayTime);
        return () => {clearTimeout(searchTimeout);}
        }
    }, [searchWords])

    const handleSearchFilesOnChange = (e) => {
        delayTime = 500
        const value = e.target.value
        setSearchWords(value)
        if (!value) {
            fileParams.keyword = null
            getAllDeviceFiles()
        }

        setIsOnClick({click: "", index: ""})
    }

    const handleFilterModelOnChange = (e) => {
        fileParams.bmc_lite_model_id = e.target.value
        if (e.target.value === "") {
            fileParams.bmc_lite_model_id = null
        }
        getAllDeviceFiles()
        hasMoreScroll = false
        setIsOnClick({click: "", index: ""})
    }

    const handleAllFilesOnClick = () => {
        document.getElementById("search_input").value = ""
        setIsAddFile(false)
        if (backupData) {
            setBmcLiteFilesData(backupData)
        }
    }
    
    const formatDate = (date) => {
        return new Date(date).toLocaleString('sv')
    }

    const showModelDeleteConfirm = (data, id, index) => {
        confirm({
            title: 'Warming!',
            icon: <ExclamationCircleFilled />,
            content: `Are you sure delete this model, ${data.name}?`,
            centered: true,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
                deleteModel(data.id, index);
            }
        });
    };

    const showVersionDeleteConfirm = (data, id, index) => {
        confirm({
            title: 'Warming!',
            icon: <ExclamationCircleFilled />,
            content: `Are you sure delete this version, v${data.name}?`,
            centered: true,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
                deleteFileOnClick(data.id, index);
            }
        });
    };

    const showLatestConfirm = (data, id, index) => {
        confirm({
            title: 'Warming!',
            icon: <ExclamationCircleFilled />,
            content: `Would you like to set this version, v${data.name}, as the Latest?`,
            centered: true,
            okText: 'Yes',
            okType: 'primary',
            cancelText: 'Cancel',
            onOk() {
                handleLatestOnChange();
                modifyFileOnClick(data.id);
            }
        });
    };

    const [ page, setPage ] = useState("model")
    return (
        <>
        {/* { page === "note" && 
        <ReleaseNotePage 
            file_data={{...bmcLiteFilesData[isOnClick.index], 
                // created_at: formatDate(bmcLiteFilesData[isOnClick.index].created_at)
            }} 
            back={(data) => {setIsOnClick(data); setPage("fw"); getAllDeviceFiles()}}
            showLatestConfirm = {showLatestConfirm} 
        />
        } */}
        <div className={styles.file_page} style={{display: page !== "model" && 'none'}}>
            <SearchBar 
                name="Models"
                search={handleSearchModelsOnChange}
                all={handleAllFilesOnClick}
                add={initAddModel}
            />
            <div className={styles.file_header}>
                <div className={styles.file__model__header__item}>
                    <span>Model</span>
                </div>
                <div className={styles.file__model__header__item}>
                    <span>Actions</span>
                </div>
            </div>
            { isOnClick.click === "add" ? 
            <div className={styles.file__data__add__wrap} >
                {/* {setHeight("54vh")} */}
                <div className={styles.file__data__add__block}>
                    <label htmlFor="add_model_name">Model Name</label>
                    <input id="add_model_name" name="name" placeholder="Model Name" onChange={(e) => newModelData.name = e.target.value}/>
                </div>
                <div className={styles.file__data__add__block}>
                    <div className={styles.file__add__cancel__btn} onClick={() => setIsOnClick({click: "", index: ""})}>
                        <span>Cancel</span>
                    </div>
                    <div className={styles.file__add__create__btn} onClick={() => addDeviceModel()}>
                        <span>Create</span>
                    </div>
                </div>
            </div>
            :
            // setHeight("77vh")
            <></>
            }
            { modelsData && 
            <div className={styles.model_content} >
                { modelsData.map((data, index) => {
                return (
                    <div className={styles.file_data} key={data.id}>
                        { isOnClick.click === "edit" && isOnClick.index === index ? 
                            <div className={styles.file__model__name}>
                                <input name="name" defaultValue={data.name} onChange={(e) => newModelData.name = e.target.value }/>
                            </div>
                            :
                            <div className={styles.file__model__name}>
                                <span>{data.name}</span>
                            </div>
                        }
                        { isOnClick.click === "edit" && isOnClick.index === index &&
                            <div className={styles.file__models__action__btn__group}>
                                <div className={styles.file__models__cancel__btn}
                                    onClick={(e) => {e.stopPropagation(); setIsOnClick({click: "", index: ""})}}
                                >
                                    <span>Cancel</span>
                                </div>
                                <div className={styles.file__models__confirm__btn}
                                    onClick={(e) => {e.stopPropagation(); modifyModel(data.id)}}
                                >
                                    <span>Save</span>
                                </div>
                            </div>
                        }
                        { isOnClick.index !== index && !data.uploading &&
                            <div className={styles.file__models__action__btn__group}>
                                <div className={styles.file__models__versions__btn}
                                    onClick={(e) => { e.stopPropagation(); fileParams.bmc_lite_model_id = data.id; getAllDeviceFiles(); setPage("fw"); localStorage.setItem('select-model', JSON.stringify(data))}}
                                >
                                    <Link to={`/admin/oob-models/${data.name}`}>
                                        <span>Versions</span>
                                    </Link>
                                </div>
                                <div className={styles.file__models__edit__btn}
                                    onClick={(e) => {e.stopPropagation(); initModifyModel(data, index)}}
                                >
                                    <span>Edit</span>
                                </div>
                                <div className={styles.file__models__delete__btn}
                                    onClick={(e) => {e.stopPropagation(); showModelDeleteConfirm(data, data.id, index);}}
                                >
                                    <span>Delete</span>
                                </div>
                            </div>
                        }                
                        {data.uploading &&
                        <span>
                            <div className={styles.loader_upload}></div>
                        </span>
                        }
                    </div>
                )
                })}
            </div>
            }
        </div>
        
        



        
        <div className={styles.file_page} style={{display: page !== "fw" && 'none'}}>
            <svg className={styles.fw_close} viewBox="2 2 20 20"
                onClick={() => {setPage("model"); setBmcLiteFilesData([])}} >
                <title>Back</title>
                <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
            </svg>              
            <SearchBar 
                name="Files"
                all={handleAllFilesOnClick}
                search={handleSearchFilesOnChange}
                add={initAddFile}
            />
            <div className={styles.file_header}>
                <div className={styles.file__header__item}>
                    <span>Version aaaaa</span>
                </div>
                <div className={styles.file__header__item}>
                    <span>Create Time</span>
                </div>
                <div className={styles.file__header__item}></div>
                <div className={styles.file__header__item__action}>
                    <span>Action</span>
                </div>
            </div>
            { isOnClick.click === "add" ? 
            <div className={styles.file__data__add__wrap} >
                {/* {setHeight("54vh")} */}
                <div className={styles.file__data__upload__block}>
                    <label htmlFor="add_file_name">Version</label>
                    <input id="add_file_name" name="name" autoComplete="off" placeholder="Version" onChange={handleAddFileOnChange}/>
                    <label htmlFor="add_file_is_latest">Latest Version</label>
                    <select id="add_file_is_latest" name="is_latest" defaultValue="false" onChange={handleAddFileOnChange}>
                        <option value="true">Yes</option> 
                        <option value="false">No</option>
                    </select>
                </div>
                <div className={styles.file__data__upload__block}>
                    <div className={styles.file__data__upload}>
                        <label>Upload File</label>
                        <input type="file" id="file_uploader" name="file" onChange={fileChosen} hidden/>
                        <label className={styles.file_uploader} htmlFor="file_uploader">Choose File</label>
                        <label id="file_chosen" htmlFor="file_uploader">No File Chosen</label>
                    </div>
                </div>
                <div className={styles.file__data__upload__btn__group}>
                    <div className={styles.file__add__cancel__btn} onClick={() => setIsOnClick({click: "", index: ""})}>
                        <span>Cancel</span>
                    </div>
                    <div className={styles.file__add__create__btn} onClick={() =>  uploadFileOnClick()}>
                        <span>Upload</span>
                    </div>
                </div>
            </div>
            // <div className={styles.file_data} >
            //     {/* {setHeight("54vh")} */}
            //     <div className={styles.edit_box}>
            //         <div className={styles.edit_box_2}>
            //             <label htmlFor="add_file_name">Version</label>
            //             <input id="add_file_name" name="name" autoComplete="off" placeholder="Version" onChange={handleAddFileOnChange}/>
            //         </div>
            //         <div className={styles.edit_box_2}>
            //             <label htmlFor="add_file_is_latest">Latest Version</label>
            //             <select id="add_file_is_latest" name="is_latest" defaultValue="false" onChange={handleAddFileOnChange}>
            //                 <option value="true">Yes</option> 
            //                 <option value="false">No</option>
            //             </select>
            //         </div>
            //     </div>
            //     <div className={styles.edit_box}>
            //         <div className={styles.edit_box_2}>
            //             <label>
            //                 Upload File
            //                 <input type="file" id="file_uploader" name="file" onChange={fileChosen} hidden/>
            //                 <div style={{height:"100%", display:"flex", alignItems:"baseline", width: "58%"}}>
            //                     <label className={styles.file_uploader} htmlFor="file_uploader">Choose File</label>
            //                     <label id="file_chosen" htmlFor="file_uploader">No File Chosen</label>
            //                 </div>
            //             </label>
            //         </div>
            //     </div> 
            //     <div className={styles.edit_box}>
            //     </div>
            //     <div className={styles.edit_box}>
            //         <div className={styles.edit_box_2}>
            //             <div className={styles.edit_box_btn} onClick={() => uploadFileOnClick()}>UPLOAD</div>
            //         </div>
            //         <div className={styles.edit_box_2}>
            //             <div className={styles.edit_box_btn} onClick={() => setIsOnClick({click: "", index: ""})}>CANCEL</div>
            //         </div>
            //     </div>
            // </div>
            :
            // setHeight("77vh")
            <></>
            }

            { bmcLiteFilesData && 
            <div className={styles.file_content}>
                <InfiniteScroll
                    style={{"overflowY":"overlay", "overflowX":"hidden"}}
                    dataLength={bmcLiteFilesData.length} 
                    hasMore={hasMoreScroll}
                    next={undefined}
                    loader={<PageLoader />}
                    height={scroll_height}
                >
                    <div className={styles.files__group}>
                        { bmcLiteFilesData.map((data, index) => {
                            return (
                                <div className={styles.file_data} key={data.id}>
                                    <div className={styles.file__content__item}>
                                        <span>{data.name}</span>
                                    </div>
                                    <div className={styles.file__content__item}>
                                        <span>{formatDate(data.created_at)}</span>
                                    </div>
                                    <div className={styles.file__content__item}>
                                        {data.is_latest ? <div className={styles.latest_status}><span>Latest</span></div> : ""}
                                    </div>

                                    { isOnClick.click === "edit" && isOnClick.index === index ?
                                    <>
                                        <span>
                                            <label htmlFor="latest" style={{display: 'flex', alignItems: 'center'}}>
                                                <input className={styles.edit_checkbox} id="latest" value={true} defaultChecked={data.is_latest && 'checked'} disabled={data.is_latest && true} type="checkbox" onChange={handleLatestOnChange}/>
                                                Latest
                                            </label>
                                        </span>
                                        <span>
                                            {actionMsg}
                                            <YesButton onClick={() => modifyFileOnClick(data.id)}/>
                                            <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                                        </span>
                                    </>
                                    :
                                    <></>
                                    }
                                    { isOnClick.click === "delete" && isOnClick.index === index &&
                                    <span>
                                        {actionMsg}
                                        <YesButton onClick={() => deleteFileOnClick(data.id, index)}/>
                                        <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                                    </span>
                                    }
                                    { isOnClick.index !== index && !data.uploading &&
                                    <div className={styles.file__content__item__action}>
                                        {/* <div className={styles.file__models__versions__btn}
                                            onClick={(e) => { e.stopPropagation(); showLatestConfirm(data, index)}}
                                        >
                                            <span>Set as Latest</span>
                                        </div> */}
                                        <div className={styles.file__models__edit__btn}
                                            onClick={(e) => {e.stopPropagation(); setPage('note'); setIsOnClick({click: "note", index: index})}}
                                        >
                                            <span>Detail</span>
                                        </div>
                                        <div className={styles.file__models__delete__btn}
                                            onClick={(e) => {e.stopPropagation(); showVersionDeleteConfirm(data, data.id, index);}}
                                        >
                                            <span>Delete</span>
                                        </div>
                                    </div>
                                    // <span>
                                    //     <ReleaseNoteButton onClick={() => {setIsOnClick({click: "note", index: index})}} />
                                    //     <ModifyButton onClick={() => {initModifyFile(data, index)}} />
                                    //     <DeleteButton onClick={() => {setIsOnClick({click: "delete", index: index}); setActionMsg(<div className={styles.action_note}>Delete</div>)}}/>
                                    // </span>
                                    // <>
                                    //     <span>Release Notes</span>
                                    //     <span>Edit</span>
                                    //     <span>Delete</span>
                                    // </>
                                    }                
                                    {data.uploading &&
                                    <span>
                                        <div className={styles.loader_upload}></div>
                                    </span>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </InfiniteScroll>
            </div>
            }
        </div>
        
        
        
        </>
    )
}
export default FilePage