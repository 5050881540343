import { useState, useEffect } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import {  apiAdminGetCompanies,
          apiAdminCreateCompany,
          apiAdminModifyCompany,
          apiAdminDeleteCompany
        } from "../../../api"
import { SearchBar } from "../../../components/SearchBar"
// import { AddButton } from "../../components/AddButton"

import styles from './AdminCompany.module.scss'
import { DeleteButton, ModifyButton, NoButton, YesButton } from "../../../components/ActionButton"
import { PageLoader } from "../../../components/Loader"

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, notification } from 'antd';
const { confirm } = Modal;

let newCompanyData,
    hasMoreScroll = true

const CompanyPage = (props) => {
  const accessToken = JSON.parse(localStorage.getItem('access_token'))
  const [ actionMsg, setActionMsg ] = useState("")
  const [ companiesData, setCompaniesData ] = useState([])
  const [ num, setNum ] = useState(10)
  const [ total, setTotal ] = useState()
  const [ searchWords, setSearchWords ] = useState()
  const [ isOnClick, setIsOnClick ] = useState({click: "", index: ""})

  const showNotification = (type, title, content) => {
    notification.open({
        type: `${type}`,
        message: `${title}`,
        description: `${content}`,
        className: 'bmc__notification'
    });
  };

  // let num = 10;
  useEffect(() => {
    const getCompanies = async () => {
      await apiAdminGetCompanies({'Authorization': `Bearer ${accessToken}`}, 0, 10, searchWords)
      .then(res => {
        setCompaniesData(res.data.data)
        setTotal(res.data.total)
        if (10 >= total) {
          hasMoreScroll = false
        } else {
          hasMoreScroll = true
        }
      })
      .catch(err => {
        // console.log('Get companies data failed.');
      })
    }
    getCompanies()

    return () => {
      hasMoreScroll = true
    }

  },[accessToken, total])

  const nextScrollData = () => {
    setNum(num + 10)
    if (num >= total) {
      hasMoreScroll = false
      return
    } else {
      hasMoreScroll = true
    }
    const scrollGetCompanies = async() => {
      await apiAdminGetCompanies({
            'Authorization': `Bearer ${accessToken}`
        },
        num,
        10
      )
      .then(res => {
        setCompaniesData(companiesData.concat(res.data.data))
      })
      .catch(err => {
        // console.log('Get companies data failed.');
      })
    }
    scrollGetCompanies()
  }

  const initAddCompany = () => {
    setActionMsg("")
    setIsOnClick({click: "add", index: ""})
    newCompanyData = {
      name: undefined,
      address: undefined
    }
  }

  const initModifyCompany = (data, index) => {
    setActionMsg(<div className={styles.action_note}>Modify</div>)
    setIsOnClick({click: "edit", index: index})
    newCompanyData = {
      name: data.name,
      address: data.address
    }
  }

  const handleEditOnChange = (e) => {
    newCompanyData = {
      ...newCompanyData,
      [e.target.name]: e.target.value
    }
  }

  const submitAddCompanyData = () => {
    const AddCompany = async() => {
      await apiAdminCreateCompany({
        headers:{
          'Authorization': `Bearer ${accessToken}`
        }},
        newCompanyData
      )
      .then(res => {
        setCompaniesData(companiesData.concat(res.data))
        setIsOnClick({click: "", index: ""})
        showNotification('success', 'Success!', 'Successfully add a new company.')
      })
      .catch(err => {
        showNotification('error', 'Error!', `${err.response.data.detail}`)
        // console.log('Add company failed, check data is valid or existing.')
        // setActionMsg(<div className={styles.company_err_msg}>Add company failed, check data is valid or existing</div>)
      })
    }
    AddCompany()
  }

  const submitEditCompanyData = (company_id) => {
    const UpdateCompany = async() => {
      await apiAdminModifyCompany({
        headers:{
          'Authorization': `Bearer ${accessToken}`
        }},
        newCompanyData,
        company_id
      )
      .then(res => {
        companiesData[isOnClick.index] = {...companiesData[isOnClick.index],
          name: res.data.name,
          address: res.data.address
        }
        setIsOnClick({click: "", index: ""})
        showNotification('success', 'Success!', 'Edition has been successfully done.')
      })
      .catch(err => {
        // console.log('Edit company failed, check data is valid or existing.')
        // console.log(err.response);
        showNotification('error', 'Error!', `${err.response.data.detail}`)
        // setActionMsg(<div className="action_note">Check data is valid or existing</div>)
      })
    }
    UpdateCompany()
  }

  const deleteCompany = (company_id, index) => {
    const DeleteCompany = async() => {
      await apiAdminDeleteCompany({
        headers:{
          'Authorization': `Bearer ${accessToken}`
        }},
        company_id
      )
      .then(res => {
        companiesData.splice(index, 1)
        setIsOnClick({click: "", index: ""})
        showNotification('success', 'Success!', 'Selected company has been deleted.')
      })
      .catch(err => {
        showNotification('error', 'Error!', `${err.response.data.detail}`)
        // console.log('Delete company failed.');
      })
    }
    DeleteCompany()
  }

  // Search company
  const handleAllCompaniesOnClick = () => {
      document.getElementById("search_input").value = ""
      setIsOnClick({click: "", index: ""})
  }

  const getAllCompanies = async (keywords) => {
    await apiAdminGetCompanies({
      'Authorization': `Bearer ${accessToken}`
    },
    0,
    1000,
    keywords
    ).then(res => {
      setCompaniesData(res.data.data)
      hasMoreScroll = false
    }).catch(err => {
      setCompaniesData()
    })
    

      // const response = await apiAdminGetCompanies({
      //       'Authorization': `Bearer ${accessToken}`
      //   },
      //   0,
      //   1000,
      //   searchWords)
      // const data = await response.data.data
      // return data
  }

  useEffect(() => {
    if (searchWords) {
      const searchTimeout = setTimeout(() => {
        getAllCompanies(searchWords)
      }, 500);
      return () => clearTimeout(searchTimeout)
    }
  }, [searchWords])

  useEffect(() => {
    if(props.setActiveItem) props.setActiveItem('companies')
  }, [props.setActiveItem]);
  
  const handleSearchCompaniesOnChagne = (e) => {
      const value = e.target.value
      setSearchWords(value)
      if (!value) {
        getAllCompanies()
      }
      setIsOnClick({click: "", index: ""})
  }
  // END Search company

  let scroll_height = "77vh"
  const setHeight = (e) => {
      scroll_height = e
  }

  const showCompanyDeleteConfirm = (data, id, index) => {
    confirm({
        title: 'Warming!',
        icon: <ExclamationCircleFilled />,
        content: `Are you sure delete this company, ${data.name}?`,
        centered: true,
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          deleteCompany(id, index);
        }
    });
};

  return (
    <div className={styles.company_page}>
      <SearchBar 
        name="Companies"
        search={handleSearchCompaniesOnChagne}
        all={handleAllCompaniesOnClick}
        add={initAddCompany}
      />

      <div className={styles.company_header}>
          <div className={styles.company__header__item}>
              <span>Name</span>
          </div>
          <div className={styles.company__header__item}>
              <span>Tax ID</span>
          </div>
          <div className={styles.company__header__item}>
              <span>Address</span>
          </div>
          <div className={styles.company__header__item}>
              <span>Action</span>
          </div>
      </div>

      { isOnClick.click === "add" ?
        <div className={styles.company__data__add__wrap} >
          <div className={styles.company__data__input__block}>
              <label htmlFor="add_company_name">Company Name</label>
              <input id="add_company_name" autoComplete="off" name="name" placeholder="Company Name" onChange={handleEditOnChange} />
              <label htmlFor="add_company_id">Tax ID</label>
              <input id="add_company_id" defaultValue="N/A" disabled/>
              <label htmlFor="add_company_address">Address</label>
              <input autoComplete="off" id="add_company_address" name="address" placeholder="Address" onChange={handleEditOnChange} />
          </div>
          <div className={styles.company__data__add__block}>
              <div className={styles.company__add__cancel__btn} onClick={() => setIsOnClick({click: "", index: ""})}>
                  <span>Cancel</span>
              </div>
              <div className={styles.company__add__create__btn} onClick={() => submitAddCompanyData()}>
                  <span>Create</span>
              </div>
          </div>
        </div>
      : <></>
      }

      { companiesData && 
      <div className={styles.company_content}>
        <InfiniteScroll
          style={{"overflowY":"overlay", "overflowX":"hidden"}}
          dataLength={companiesData.length} 
          next={nextScrollData}
          hasMore={hasMoreScroll}
          // loader={<PageLoader />}
          height={scroll_height}
        >
          <div className={styles.companies__group}>
        { companiesData.map((data, index) => {
          return (
              <div className={styles.company_data} key={data.id}>
              { isOnClick.click === "edit" && isOnClick.index === index ? 
              <>
                <div className={styles.company_content_item}>
                  <input id="edit_company_name" placeholder="Company Name" autoComplete="off" name="name" defaultValue={data.name} onChange={handleEditOnChange} />
                </div>
                <div className={styles.company_content_item}>
                  <span>N/A</span>
                </div>
                <div className={styles.company_content_item}>
                  <input autoComplete="off" id="edit_company_address" defaultValue={data.address} name="address" placeholder="Address" onChange={handleEditOnChange} />
                </div>
                <div className={styles.company__action__btn__group}>
                  <div className={styles.company__cancel__btn}
                      onClick={(e) => {e.stopPropagation(); setIsOnClick({click: "", index: ""})}}
                  >
                      <span>Cancel</span>
                  </div>
                  <div className={styles.company__edit__btn}
                      onClick={(e) => {e.stopPropagation(); submitEditCompanyData(data.id)}}
                  >
                      <span>Confirm</span>
                  </div>
                </div>
              </>
              :
              <>
                <div className={styles.company_content_item}>
                  <span>{data.name}</span>
                </div>
                <div className={styles.company_content_item}>
                  <span>N/A</span>
                </div>
                <div className={styles.company_content_item}>
                  <span>{data.address}</span>
                </div>
                {/* { isOnClick.click === "delete" && isOnClick.index === index ?
                <span>
                  {actionMsg}
                  <YesButton onClick={() => deleteCompany(data.id, index)}/>
                  <NoButton onClick={() => setIsOnClick({click: "", index: ""})}/>
                </span>
                : */}
                <div className={styles.company__action__btn__group}>
                  <div className={styles.company__edit__btn}
                      onClick={(e) => {e.stopPropagation(); initModifyCompany(data, index)}}
                  >
                      <span>Edit</span>
                  </div>
                  <div className={styles.company__delete__btn}
                      onClick={(e) => {e.stopPropagation(); showCompanyDeleteConfirm(data, data.id, index);}}
                  >
                      <span>Delete</span>
                  </div>
                </div>
                {/* } */}
              </> }
              </div>
          )
        })}
        </div>
        </InfiniteScroll>
      </div>
      }
    </div>)
}
export default CompanyPage