import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DevicePage from '../User/Devices';
import Dashboard from "../User/Dashboard";
import UserPage from "../User/Users";
import AdminDashboard from "../Admin/Dashboard";
import AdminUserPage from "../Admin/Users";
import AdminDevicesPage from "../Admin/Devices";
import AdminCompanyPage from "../Admin/Companies";
import AdminFilePage from "../Admin/Files";
import AdminCredentialPage from "../Admin/Credentials"
import ReleaseNotePage from "../../components/ReleaseNotePage";
import DeviceDetail from '../../components/DeviceDetailPage';
import UserDetailPage from "../../components/UserDetailPage";
import VersionPage from "../../components/VersionPage";
import ProfilePage from "../../components/ProfilePage";
import { 
    apiUrl,
    apiLogout, 
    apiChangeUserMePassword,
    apiModifyUserMe,
    apiGetMeSessions,
    apiClearMeSessions,
    apiDeleteMeSession,
    apiGetUserMeLogs,
    apiAdminDownloadUserMeLogs
} from "../../api";
import { CheckOwner } from "../../components/CheckOwner";

import styles from './Main.module.scss'
import user_style from '../Admin/Users/AdminUser.module.scss'
import ReactPaginate from 'react-paginate';
import DatePicker from "react-datepicker";
import { ExportCsvButton, 
        ExportHtmlButton, 
        LogLevelIcon,
        CalendarIcon,
        SideBarUserIcon,
        SideBarDeviceIcon,
        SideBarCredentialIcon,
        SideBarCompanyIcon,
        SideBarFileIcon,
        SideBarDashboardIcon,
        SideBarProfileIcon 
    } from "../../components/ActionButton";
import { UserLogsPage } from "../../components/UserLogsPage";

let editProfile,
    pageNum = -1,
    delayTime = 0
let password = {
    current_password: undefined,
    new_password: undefined,
    confirm_password: undefined
}
let logsParams = {
    levels: [ 'debug', 'info', 'warning', 'error', 'critical' ],
    start_dt: new Date().toLocaleDateString('sv') + " 00:00:00",
    end_dt: new Date().toLocaleDateString('sv') + " 23:59:59",
    sort_by: "created_at",
    skip: 0,
    limit: 100
}
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const MainPage = (props) => {

    const current_url = document.location.pathname.replace(/^\/admin\/([^]*).*$/, '$1').replace(/\//g,'')
    const [ activeItem, setActiveItem ] = useState(current_url)
    const [ me, setMe ] = useState({is_superuser: "", permissions: ""})
    const [ passwordError, setPasswordError ] = useState()
    const [ profileError, setProfileError ] = useState()
    const accessToken = JSON.parse(localStorage.getItem('access_token'))
    const [ isOnClick, setIsOnClick ] = useState()

    useEffect(() => {
        CheckOwner().then(data => {
            setMe(data)                                 
            if (data.is_superuser) {
                if (document.location.pathname === '/') {
                    window.location.assign("/admin/dashboard")
                }
            } else {
                if (document.location.pathname === '/') {
                    window.location.assign("/dashboard")
                }
            }
        })
    },[])

    const [ meSessions, setMeSessions ] = useState()
    const getMeSessions = async () => {
        setMeSessions(null)
        document.getElementById('profile_option').style.display = 'none';
        setIsOnClick("session");
        await apiGetMeSessions( {
        'Authorization': `bearer ${accessToken}`
        }).then(res => {
            setMeSessions(res.data.data)
        }).catch(err => {

        })
    }

    const clearMeSession = async () => {
        await apiClearMeSessions({
            headers:{
                'Authorization': `Bearer ${accessToken}`
            }}
        ).then(res => {
            getMeSessions()
        }).catch(err => {
        
        })
    }

    const deleteMeSession = async (session_id, index) => {
        await apiDeleteMeSession({
            headers:{
                'Authorization': `Bearer ${accessToken}`
            }},
            session_id
        ).then(res => {
            meSessions.splice(index, 1)
            setMeSessions([...meSessions])
        }).catch(err => {

        })
    }

    const initEditProfile = () => {
        document.getElementById('profile_option').style.display = 'none';
        setIsOnClick("profile")
        setProfileError('')
        setPasswordError('')
        editProfile = {
            full_name: me.full_name,
            email: me.email,
            description: "",
            phone_number: me.phone_number === "" ? null : me.phone_number,
            theme: "System Default"
        }
    }

    const handleEditProfileOnChange = (e) => {
        const value = e.target.name === 'phone_number' && e.target.value === "" ? null : e.target.value
        editProfile = {
            ...editProfile,
            [e.target.name]: value
        }
    }

    const handlePasswordOnChange = (e) => {
        password = {
            ...password,
            [e.target.name]: e.target.value
        }
    }

    const submitEditProfile = () => {
        apiModifyUserMe({
            headers: {
                'Authorization': `bearer ${accessToken}`
            }
        },
        editProfile
        ).then(res => {
            setMe({
                ...me,
                full_name: editProfile.full_name,
                email: editProfile.email
            })
            setIsOnClick("")
        })
        .catch(err => {
            setProfileError(<div style={{
                color: 'red',
                fontSize: '0.9vw',
                position: 'absolute',
                right: '0',
                marginRight: '8.5%',
                marginTop: '-1.5%'}}>
                E-mail already exist or invalid value
            </div>)
        })
    }

    const submitChangeProfilePassword = () => {
        if ( password.new_password === password.confirm_password ) {
            apiChangeUserMePassword({
                headers: {
                    'Authorization': `bearer ${accessToken}`
                }
            },
            {
                old_password: password.old_password,
                new_password: password.new_password
            })
            .then(res => {
                setIsOnClick("")
            })
            .catch(err => {
                console.log(err.response.status);
                if (err.response.status === 400) {
                    setPasswordError(<div style={{
                        color: 'red',
                        fontSize: '0.9vw',
                        position: 'absolute',
                        right: '0',
                        marginRight: '8.5%',
                        marginTop: '-1.5%'}}>
                        Current password is Incorrect
                    </div>)
                } else {
                    setPasswordError(<div style={{
                        color: 'red',
                        fontSize: '0.9vw',
                        position: 'absolute',
                        right: '0',
                        marginRight: '8.5%',
                        marginTop: '-1.5%'}}>
                        Passwords must have at least 8 valid characters 
                    </div>)
                }
                
            })
        } else {
            setPasswordError(<div style={{
                color: 'red',
                fontSize: '0.9vw',
                position: 'absolute',
                right: '0',
                marginRight: '8.5%',
                marginTop: '-1.5%'}}>
                New password does not match
            </div>)
        }
    }

    const handleLogout = () => {
        apiLogout({},{
            headers: {
                'Authorization': `bearer ${accessToken}`
            }
        })
        .then(res => {
            localStorage.clear()
            window.location.assign("/")
        })
        .catch(err => {
            // console.log(err);
        })
        // sessionStorage.clear()
    }

    // Click outside of div to close div
    // document.addEventListener('mouseup', function(e) {
    //     let container = document.getElementById('profile_option');
    //     if (!container.contains(e.target)) {
    //         container.style.display = 'none';
    //     }
    // });

    const formatDate = (date) => {
        return new Date(date).toLocaleString('sv')
    }

    const [ dateRange, setDateRange ] = useState([null, null])
    const [ startDate, endDate ] = dateRange
    const [ dateString, setDateString ] = useState('')
    const [ actionMsg, setActionMsg ] = useState("")
    const [ pageCount, setPageCount ] = useState(0)
    const [ userLogsData, setUserLogsData ] = useState()
    const [ logLevelString, setLogLevelString ] = useState('All Levels')
    const [ isLogsFilterActive, setIsLogsFilterActive ] = useState(null)
    const [ showLogLevelOption, setShowLogLevelOption ] = useState(false)
    const [ searchWords, setSearchWords ] = useState()

    const [profileSettingItem, setProfileSettingItem] = useState(false);

    // Click outside of div to close div
    let container_date = document.getElementById('log_date_option'),
        container_level = document.getElementById('log_level_option')
        document.addEventListener('mouseup', function eventHandler(e) {
        if (container_date && !container_date.contains(e.target)) {
            container_date.style.display = 'none';
        }
    })
    if (showLogLevelOption) {
        document.addEventListener('mouseup', function eventHandler(e) {
            if (container_level && !container_level.contains(e.target)) {
                logsParams.skip = 0
                delete logsParams.keyword
                document.getElementById("search_input").value = "";
                setShowLogLevelOption(false)
                getUserMeLogs()
                if (logsParams.levels.length === 5 || logsParams.levels.length === 0) {
                setLogLevelString('All Levels')  
                } else {
                setLogLevelString(logsParams.levels.toString().replaceAll(',', ', '))
                }
                document.removeEventListener('mouseup', eventHandler)
            }
        })
    }

    const handleGetMeLogsOnClick = () => {
        document.getElementById('profile_option').style.display = 'none';
        setIsOnClick("log")
        setLogLevelString('All Levels')  
        logsParams = {
            ...logsParams,
            levels: [ 'debug', 'info', 'warning', 'error', 'critical' ],
            start_dt: new Date().toLocaleDateString('sv') + " 00:00:00",
            end_dt: new Date().toLocaleDateString('sv') + " 23:59:59",
            sort_by: "created_at",
            skip: 0
        }
        setActionMsg()
        setDateRange([null, null])
        setDateString('Today')
        getUserMeLogs()
    }

    const handleLogsFilterOnClink = (catogory) => {
        setIsLogsFilterActive(catogory)
        logsParams.skip = 0
        logsParams.category = catogory
        delete logsParams.keyword
        getUserMeLogs();
        document.getElementById("search_input").value = ""
    }

    const handlePageClick = (e) => {
        pageNum = e.selected
        logsParams.skip = logsParams.limit * pageNum
    }

    useEffect(() => {
        if (searchWords) {
            const searchTimeout = setTimeout(() => {
                getUserMeLogs()
        }, delayTime);
            return () => clearTimeout(searchTimeout)
        }
    }, [searchWords])

    const handleSearchLogsOnChange = (e) => {
        delayTime = 500
        const value = e.target.value
        logsParams.keyword = value
        logsParams.skip = 0
        setSearchWords(value)
        if (!value) {
            delete logsParams.keyword
            getUserMeLogs()
        }
    }

    const handleLogsLevelOnChange = (e) => {
        if (e.target.checked) {
            logsParams.levels.push(e.target.value)
        } else {
            logsParams.levels.splice(logsParams.levels.indexOf(e.target.value), 1)
        }
    }

    const getUserMeLogs = async () => {
        await apiGetUserMeLogs(
            {
                'Authorization': `Bearer ${accessToken}`
            },
            logsParams
        ).then(res => {
            setActionMsg()
            setUserLogsData(res.data.data)
            setPageCount(Math.ceil(res.data.metadata.total / logsParams.limit))
        }).catch(err => {
            setPageCount(1)
            setUserLogsData()
            setActionMsg(<div style={{display: 'flex', marginTop: '10px', marginLeft: '6px'}} className={user_style.action_note_err} >{err.response.data.detail}</div>)
        })
        if (logsParams.skip === 0) {
            pageNum = 0
        }
    }

    const downloadUserMeLogs = async (format) => {
        logsParams.format = format
        await apiAdminDownloadUserMeLogs(
            {
                'Authorization': `Bearer ${accessToken}`
            },
            logsParams,
        ).then(res => {
            console.log("me log", res)
            const href = window.URL.createObjectURL(res.data);
            const anchorElement = document.createElement('a');
    
            anchorElement.href = href;
            // anchorElement.download = "Exported_log";
            anchorElement.download = res.headers['content-disposition'].replace(/attachment; filename=|["]/g, '')
            document.body.appendChild(anchorElement);
            anchorElement.click();
            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        }).catch(err => {

        })
        delete logsParams.format
    }

    return (
        <div className={styles.pages}>
            <div className={styles.sidebar}>
                <div className={styles.main__lanner__logo}>
                    <svg className={styles.main__lanner__logo__svg} viewBox="0 200 1280 320">
                        <path d="M418.26,465.87c-1.62.08-2.94.19-4.26.19-43.47,0-86.95.08-130.42,0-15.13,0-29.14-4.06-40.43-14.53-13.55-12.57-18.55-28.27-14.09-46.3,4.51-18.21,17-29.05,34.62-33.83a83.86,83.86,0,0,1,20.72-2.54c25.82-.28,51.64-.11,77.46-.11h6.22c0-7.05.61-13.72-.31-20.18-.35-2.47-3.84-5.26-6.54-6.41-3.06-1.31-6.84-1.23-10.3-1.24-24-.09-48-.22-72,.05-7.9.08-13.35-2.78-17.06-9.71s-7.74-13.51-12.41-21.6c7.13,0,13,0,18.87,0,36.64.11,73.28.17,109.92.45a58.11,58.11,0,0,1,13.78,2c16.86,4.27,26.33,15.09,26.52,32.43.45,39.8.15,79.61.13,119.42A10.37,10.37,0,0,1,418.26,465.87Zm-50.42-31V400.68a16.6,16.6,0,0,0-2.62-.48c-22.83-.07-45.66-.4-68.47,0a37.59,37.59,0,0,0-16.61,4.46c-10.62,5.78-10.81,18.38-.95,25.39,5.51,3.92,11.86,5,18.49,5q32.49,0,65,0C364.28,435.1,365.9,435,367.84,434.84Z"/>
                        <path d="M508.14,466.06H454.47V310.52a10.56,10.56,0,0,1,2.56-.61c43.47.06,86.94-.06,130.41.38a68.24,68.24,0,0,1,34.78,9.62c11.74,7,18.47,17.5,18.58,31.17.31,37.48.11,75,.11,112.44,0,.65-.13,1.29-.24,2.36H587.08v-5.65c0-31.65-.39-63.31.16-95,.28-15.7-10.15-23.85-21.63-25.35-7.38-1-14.93-.8-22.42-.88-11.47-.13-23,0-35.05,0Z"/>
                        <path d="M732.08,466H678.55V310.42a10.13,10.13,0,0,1,2.26-.5c42.16,0,84.32-.17,126.48.13a72.81,72.81,0,0,1,36.76,10c13.87,8.14,21.26,19.88,21.12,36.45-.28,35-.08,70-.09,105,0,1.31-.13,2.63-.22,4.4H811.17v-5.6q0-46.5,0-93c0-17.79-8.48-27.29-26.49-28-16.3-.68-32.66-.12-49-.28-4,0-3.59,2.45-3.59,5q0,28.5,0,57Z"/>
                        <path d="M975.08,403.93l53.4-65.38a36.51,36.51,0,0,0-4.5-.46c-15.29.58-30.83-.34-45.79,2.2-19.59,3.32-33,15.56-37.38,35.65-3.91,17.86,4.53,36.07,20.93,46.73,13.31,8.65,28.17,11.74,43.77,11.8,23.67.08,47.34,0,71,0,3,0,5.91.26,9.07.42v30.65a31,31,0,0,1-3.84.48c-30.83-.09-61.67.34-92.49-.49-24.69-.67-48.26-6.58-68.46-21.75-19.88-14.92-31.92-34.54-31.59-59.95.35-26.38,13.72-45.74,35.46-59.58,16-10.18,33.9-14.85,52.67-15.26,27.15-.59,54.32-.59,81.47-.45a54.59,54.59,0,0,1,16.62,2.91c8.33,2.75,12.07,9.33,9.52,17.71a59.48,59.48,0,0,1-9,18.13c-12.24,16.23-25.22,31.88-37.81,47.84-4.89,6.21-10.82,9.16-19,8.9C1004.89,403.6,990.59,403.93,975.08,403.93Z"/>
                        <path d="M211.94,430.37v35.51a13.11,13.11,0,0,1-2.82.61c-30.65-.21-61.32-.05-91.95-.95-9-.27-18.14-2.71-26.71-5.65C75.09,454.6,66.54,443,64.14,427a78.44,78.44,0,0,1-.91-11.45q-.09-77.24,0-154.48c0-1.45.18-2.91.29-4.66h54.61v6q0,68.25,0,136.48c0,23.75,7.72,31.46,31.41,31.46h62.43Z"/>
                        <path d="M1237.42,309.61c-5.76,9.16-10.5,17.16-15.73,24.82-2.72,4-7.19,4.95-12,4.91-7.49-.06-15-.3-22.46.46-13.39,1.38-21.33,10.8-21.33,24.21q0,48,0,96v5.73h-54.31v-6.66c0-33.16.56-66.34-.17-99.49-.58-26.1,16.43-41.1,34.91-46.38a71.59,71.59,0,0,1,17.65-3.08c20.32-.49,40.65-.39,61-.5C1228.65,309.59,1232.3,309.61,1237.42,309.61Z"/>
                    </svg>
                </div>
                
                <div style={{height: '64%'}}>
                { me.is_superuser ?
                <>
                    <Link to="/admin/dashboard"  >
                        <div className={activeItem === "dashboard" ? styles.sidebar_item_active : styles.sidebar_item }
                            onClick={() => {setActiveItem("dashboard")}} >
                            <SideBarDashboardIcon />
                            <span>Dashboard</span>
                        </div>
                    </Link>
                    <Link to="/admin/devices"  >
                        <div className={activeItem === "devices" ? styles.sidebar_item_active : styles.sidebar_item }
                            onClick={() => {setActiveItem("devices")}} >
                            <SideBarDeviceIcon />
                            <span>OOB Devices</span>
                        </div>
                    </Link>
                    <Link to="/admin/oob-models"  >
                        <div className={activeItem === "files" ? styles.sidebar_item_active : styles.sidebar_item }
                            onClick={() => {setActiveItem("files")}} >
                            <SideBarFileIcon />
                            <span>OOB Models</span>
                        </div>
                    </Link>
                    <Link to="/admin/users"  >
                        <div className={activeItem === "users" ? styles.sidebar_item_active : styles.sidebar_item }
                            onClick={() => {setActiveItem("users")}} >
                            <SideBarUserIcon />
                            <span>Users</span>
                        </div>
                    </Link>
                    <Link to="/admin/credentials"  >
                        <div className={activeItem === "credentials" ? styles.sidebar_item_active : styles.sidebar_item }
                            onClick={() => {setActiveItem("credentials")}} >
                            <SideBarCredentialIcon />
                            <span>Credentials</span>
                            
                        </div>
                    </Link>
                    <Link to="/admin/companies"  >
                        <div className={activeItem === "companies" ? styles.sidebar_item_active : styles.sidebar_item }
                            onClick={() => {setActiveItem("companies")}} >
                            <SideBarCompanyIcon />
                            <span>Companies</span>
                        </div>
                    </Link>
                </>
                :
                <>
                    <Link to="/dashboard"  >
                        <div className={activeItem === "dashboard" ? styles.sidebar_item_active : styles.sidebar_item }
                            onClick={() => {setActiveItem("dashboard")}} >
                            <SideBarDashboardIcon />
                            <span>Dashboard</span>
                        </div>
                    </Link>
                    {me && me.permissions.includes('bml:r') && 
                    <>
                        <Link to="/devices"  >
                            <div className={activeItem === "devices" ? styles.sidebar_item_active : styles.sidebar_item }
                                onClick={() => {setActiveItem("devices")}} >
                                <SideBarDeviceIcon />
                                <span>OOB Board</span>
                            </div>
                        </Link>
                    </>
                    }
                    {me && me.permissions.includes('user:r') && 
                    <>
                        <Link to="/users"  >
                            <div className={activeItem === "users" ? styles.sidebar_item_active : styles.sidebar_item }
                                onClick={() => {setActiveItem("users")}}>
                                <SideBarUserIcon />
                                <span>Users</span>
                            </div>
                        </Link>
                    </>
                    }
                </>
                }
                    <Link to="/profile">
                        <div className={activeItem === "personal" ? styles.sidebar_item_active : styles.sidebar_item }
                            onClick={() => {setActiveItem("personal")}}>
                            <SideBarProfileIcon />
                            <span>Profile</span>
                        </div>
                    </Link>
                </div>
                <div className={styles.profile}>
                    {/* <div className={styles.profile_block}>
                        <svg className={styles.profile_user_icon} viewBox="0 0 22 18">
                            <path fill="currentColor" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
                        </svg>
                        {profileSettingItem && 
                            <div id="profile_option" className={styles.me_option_container}>
                                <span className={styles.me_option} onClick={() => {initEditProfile();}} >Edit Profile</span>
                                <span className={styles.me_option} onClick={() => {getMeSessions();}} >Manage Sessions</span>
                            </div>
                        }
                        
                        <span className={styles.profile_user_name} onClick={(e) => {e.stopPropagation(); console.log(profileSettingItem); setProfileSettingItem(!profileSettingItem)}}>
                            My Profile
                        </span>
                    </div> */}

                    <div className={styles.btn_logout}>
                        <a onClick={handleLogout} >
                            LOG OUT
                        </a>
                    </div>
                </div>
            </div>
            { isOnClick === "log" && 
            <div className={styles.edit_profile_page} style={{padding: '1%', boxSizing: 'border-box'}}>
                <div className={user_style.user_log_page}>
                {/* <svg className={user_style.log_close} viewBox="2 2 20 20"
                    onClick={() => {setIsOnClick(null)}} >
                    <title>Back</title>
                    <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
                </svg> */}
                <svg className={styles.edit_profile_close} style={{marginTop: '1.5%', marginRight: '2.5%'}} onClick={() => setIsOnClick("")} viewBox="2 2 20 20">
                    <title>Close</title>
                    <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
                </svg>
                <div className={user_style.user_log_filter_btn} >
                    <div className={isLogsFilterActive === null ? user_style.filter_btn_active : user_style.filter_btn} onClick={() => handleLogsFilterOnClink(null)}>
                        All Logs
                    </div>
                    <div className={isLogsFilterActive === "system" ? user_style.filter_btn_active : user_style.filter_btn} onClick={() => handleLogsFilterOnClink('system')}  >
                        System
                    </div>
                    <div className={isLogsFilterActive === "connection" ? user_style.filter_btn_active : user_style.filter_btn} onClick={() => handleLogsFilterOnClink('connection')}  >
                        Connection
                    </div>
                    <div className="search_bar" style={{width: '72%'}} >
                        <svg viewBox="1 2 21 21" height="1.2vw" width="1.2vw" >
                            <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                        </svg>
                        <input id="search_input" name="search" placeholder="Search log messages" onChange={handleSearchLogsOnChange}/>
                    </div>
                </div>
                <div className={user_style.user_header}>
                    <span>Your Name</span>
                    <span>E-mail</span>
                    <span>Company</span>
                    <span>Level</span>
                    <span>Date</span>
                    <span>Action</span>
                </div>
                <div className={user_style.user_data} style={{position: "relative", zIndex: '1' }}>
                    <span>{me.full_name}</span>
                    <span>{me.email}</span>
                    <span>{me.company_name}</span>
                    <span>
                    <div style={{display: 'flex', alignItems: 'center', height: '1.5vw'}}>
                        <LogLevelIcon />
                        <div title={logLevelString.toString().replaceAll(',', ', ')} className={user_style.level_string} onClick={() => setShowLogLevelOption(true)} >{logLevelString}</div>
                    </div>
                    <div id="log_level_option" style={{display: showLogLevelOption ? 'block' : 'none'}} className={user_style.log_level_option}>
                        { [ 'debug', 'info', 'warning', 'error', 'critical' ].map((level, index) => {
                        return (
                            <label key={index} htmlFor={level} className={user_style.level_option} >
                            <input id={level} value={level} type="checkbox" defaultChecked={ logsParams.levels.includes(level) ? true : false} onChange={handleLogsLevelOnChange} />
                            {level}
                            </label>
                        )
                        })
                        }
                    </div>
                    </span>
                    <span >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <CalendarIcon />
                        <div style={{cursor: 'pointer', background: 'white'}} onClick={() => document.getElementById("log_date_option").style.display = 'block'}>{dateString}</div>
                    </div>
                    <div id="log_date_option" style={{display: 'none'}} className={user_style.log_date_option}>
                        <div className={user_style.date_option} style={{borderRadius: '6px 6px 0 0'}}
                        onClick={() => {
                            delete logsParams.keyword
                            document.getElementById("search_input").value = ""
                            setDateRange([null, null]); 
                            setDateString('Today');
                            document.getElementById("log_date_option").style.display = 'none';
                            logsParams = {
                            ...logsParams,
                            skip: 0,
                            start_dt: new Date().toLocaleDateString('sv') + " 00:00:00",
                            end_dt: new Date().toLocaleDateString('sv') + " 23:59:59"
                            }
                            getUserMeLogs()
                            }} 
                        >
                        Today
                        </div>
                        <div className={user_style.date_option} style={{borderRadius: '0 0 6px 6px'}} >
                        <DatePicker 
                            placeholderText=" Click to select a date"
                            showIcon
                            dateFormat="yyyy/MM/dd"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                            setDateRange(update);
                            if (update[1]) {
                                delete logsParams.keyword
                                document.getElementById("search_input").value = ""
                                document.getElementById("log_date_option").style.display = 'none'
                                logsParams = {
                                ...logsParams,
                                skip: 0,
                                start_dt: new Date(update[0]).toLocaleDateString('sv') + " 00:00:00",
                                end_dt: new Date(update[1]).toLocaleDateString('sv') + " 23:59:59"
                                }
                                getUserMeLogs()
                                setDateString(new Date(update[0]).toLocaleDateString('sv') + " - " + new Date(update[1]).toLocaleDateString('sv'))
                            }
                            }}
                            isClearable={true}
                        />
                        </div>
                    </div>
                    </span>
                    <span>
                    <ExportHtmlButton onClick={() => userLogsData && downloadUserMeLogs('html')}/>
                    <ExportCsvButton onClick={() => userLogsData && downloadUserMeLogs('csv')}/>
                    
                    </span>
                </div>
                <div className={user_style.user_log_container}>
                    <div className={user_style.user_log_header}>
                        <span>Category</span>
                        <span>Level</span>
                        <span>Time</span>
                        <span>Messages</span>
                    </div>
                    <div className={user_style.user_log_content}>
                    {actionMsg}
                    { userLogsData && userLogsData.map((data, index) => {
                        return (
                        <div className={user_style.user_log_data} key={index} >
                            <span>{data.category}</span>
                            <span>{data.level_name}</span>
                            <span>{new Date(data.created_at).toLocaleDateString('sv') + " " + new Date(data.created_at).toLocaleTimeString('sv')}</span>
                            <span>{data.message}</span>
                        </div>
                        
                        )
                    })
                    }
                    </div>
                </div>
                <ReactPaginate 
                    breakLabel="..."
                    pageRangeDisplayed={3}
                    previousLabel="<"
                    nextLabel=">"
                    containerClassName={user_style.pagination}
                    pageLinkClassName={user_style.page_num}
                    previousLinkClassName={user_style.page_num}
                    nextLinkClassName={user_style.page_num}
                    activeLinkClassName={user_style.page_active}
                    pageCount={pageCount}
                    forcePage={pageNum}
                    onPageChange={(e) => {pageNum = e.selected; handlePageClick(e); getUserMeLogs(); }}
                />
                </div>
            </div>
            }
            { isOnClick === "profile" && 
            <div className={styles.edit_profile_page}>
                <div className={styles.edit_profile_window}>
                    <svg className={styles.edit_profile_close} onClick={() => setIsOnClick("")} viewBox="2 2 20 20">
                        <title>Close</title>
                        <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
                    </svg>
                    <div className={styles.profile_header}>
                        <span>Edit Your Profile</span>
                    </div>
                    <form className={styles.profile_content}>
                        <div className={styles.profile_item}>
                            <span>Timezone</span>
                            <span>{timezone}</span>
                        </div>
                        <div className={styles.profile_item}>
                            <span>Company</span>
                            <span>{me.company_name}</span>
                        </div>
                        <div className={styles.profile_item}>
                            <span>Name</span>
                            <input name="full_name" type="text" defaultValue={me.full_name} onChange={handleEditProfileOnChange} placeholder="Name"/>
                        </div>
                        <div className={styles.profile_item}>
                            <span>E-mail</span>
                            <input name="email" type="email" defaultValue={me.email} onChange={handleEditProfileOnChange} placeholder="E-mail" />
                        </div>
                        <div className={styles.profile_item}>
                            <span>Phone</span>
                            <input name="phone_number" type="text" defaultValue={me.phone_number} onChange={handleEditProfileOnChange} placeholder="Phone Number" />
                        </div>
                        {profileError}
                        <div className={styles.profile_btn} onClick={() => submitEditProfile()}><span>Save</span></div>
                        <div className={styles.profile_item}>
                            <span>Current Password</span>
                            <input name="old_password" type="password" maxLength="20" onChange={handlePasswordOnChange} placeholder="Current Password" autoComplete="off"/>
                        </div>
                        <div className={styles.profile_item}>
                            <span>New Password</span>
                            <input name="new_password" type="password" maxLength="20" onChange={handlePasswordOnChange} placeholder="At least 8 Characters" autoComplete="off"/>
                        </div>
                        <div className={styles.profile_item}>
                            <span>Confirm New Password</span>
                            <input name="confirm_password" type="password" maxLength="20" onChange={handlePasswordOnChange} placeholder="Confirm New Password" autoComplete="off"/>
                        </div>
                        {passwordError}
                        <div className={styles.profile_btn} onClick={() => submitChangeProfilePassword()}><span>Change</span></div>
                    </form>
                </div>
            </div>
            }
            { isOnClick === "session" && 
            <div className={styles.edit_profile_page}>
                <div className={styles.edit_profile_window}>
                    <svg className={styles.edit_profile_close} onClick={() => setIsOnClick("")} viewBox="2 2 20 20">
                        <title>Close</title>
                        <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
                    </svg>
                    <div className={styles.profile_header}>
                        <span>Manage Your Session</span>
                    </div>
                    <div className={styles.session_container}>
                    { meSessions && 
                    <>
                    { meSessions.map((data, index) => {
                        return (
                        <div className={styles.session_box} key={index}>
                            { !data.is_current_session &&
                            <div className={styles.session_revoke_btn}onClick={() => deleteMeSession(data.id, index)}>
                                <span>Revoke</span>
                                {/* <svg className={styles.close_session_cross} viewBox="2 2 20 20" onClick={() => deleteMeSession(data.id, index)}>
                                    <title>Close Session</title>
                                    <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
                                </svg> */}
                            </div>
                            }
                            { data.is_current_session &&
                            <div className={styles.profile_item} style={{fontWeight: 'bolder'}}>
                                <span>Current Session</span>
                            </div>
                            }
                            <div className={styles.profile_item}>
                                <span>Browser</span>
                                <span>{data.browser}</span>
                            </div>
                            <div className={styles.profile_item}>
                                <span>OS</span>
                                <span>{data.os}</span>
                            </div>
                            <div className={styles.profile_item}>
                                <span>Device</span>
                                <span>{data.device}</span>
                            </div>
                            <div className={styles.profile_item}>
                                <span>IP</span>
                                <span>{data.ip}</span>
                            </div>
                            <div className={styles.profile_item}>
                                <span>First Login</span>
                                <span>{formatDate(data.created_at)}</span>
                            </div>
                            <div className={styles.profile_item}>
                                <span>Last Access</span>
                                <span>{formatDate(data.last_use_at)}</span>
                            </div>
                        </div>
                        )
                    })}
                    </>
                    }
                    </div>
                    <div className={styles.session_btn} onClick={() => {clearMeSession()}}> <span>Clean All Sessions</span> </div>
                </div>
            </div>
            }

            <div className={styles.main_page}>
                { me.is_superuser &&
                <>
                    {props.adminShowDashboard && <AdminDashboard me={me} setActiveItem={setActiveItem}/>}
                    {props.adminShowDevices && <AdminDevicesPage me={me} setActiveItem={setActiveItem} />}
                    {props.showDeviceDetail && <DeviceDetail me={me} setActiveItem={setActiveItem}/>}
                    {props.adminShowUsers && <AdminUserPage me={me} setActiveItem={setActiveItem}/>}
                    {props.showUserDetail && <UserDetailPage me={me} setActiveItem={setActiveItem}/>}
                    {props.showUserDetailLogs && <UserLogsPage me={me} setActiveItem={setActiveItem}/>}
                    {props.adminShowCompanies && <AdminCompanyPage setActiveItem={setActiveItem} />}
                    {props.adminShowFiles && <AdminFilePage setActiveItem={setActiveItem}/>}
                    {props.adminShowCredentials && <AdminCredentialPage setActiveItem={setActiveItem}/>}
                    {props.showModelVersionNote && <ReleaseNotePage setActiveItem={setActiveItem}/>}
                    {props.showModelVersion && <VersionPage setActiveItem={setActiveItem}/>}
                    {props.showPersonalSetting && <ProfilePage me={me} meSessions={meSessions} clearMeSession={clearMeSession} deleteMeSession={deleteMeSession} setActiveItem={setActiveItem}/>}
                    {props.showPersonalLog && <UserLogsPage me={me} setActiveItem={setActiveItem}/>}
                </> }
                { !me.is_superuser &&
                <>
                    {props.showDashboard && <AdminDashboard me={me} />}
                    {props.showDevices && <AdminDevicesPage me={me}/>}
                    {/* {props.showDevices && <DevicePage me={me}/>} */}
                    {props.showUsers && <AdminUserPage me={me}/>}
                    {props.showDeviceDetail && <DeviceDetail me={me} setActiveItem={setActiveItem}/>}
                    {props.showPersonalSetting && <ProfilePage me={me} meSessions={meSessions} clearMeSession={clearMeSession} deleteMeSession={deleteMeSession} setActiveItem={setActiveItem}/>}
                    {/* {props.showUsers && <UserPage me={me}/>} */}
                    {props.showPersonalLog && <UserLogsPage me={me} setActiveItem={setActiveItem}/>}
                </> }
            </div>
        </div>
    )
}
export default MainPage