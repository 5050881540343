import { useEffect, useState } from "react"
import { 
        apiUrl,
        apiAdminGetDevice,
        apiAdminGetCompanies,
        apiAdminModifyDevice,
        apiPowerOnDevice,
        apiPowerOffDevice,
        apiRebootDevice,
        apiGetDeviceControlSchedule,
        apiCreateDeviceControlSchedule,
        apiDeleteDeviceControlSchedule,
        apiUpgradeBmcLite,
        apiGetDevice,
    } from "../../api"
import { Modal, notification, DatePicker, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import HistoryPage from '../HistoryPage';
import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import webSocket from 'socket.io-client';

import ReactECharts  from "echarts-for-react";
import styles from './DeviceDetailPage.module.scss';

const { confirm } = Modal;

// let wsDevData;

const DeviceDetailPage = (props) => {

    const accessToken = JSON.parse(localStorage.getItem('access_token'));
    const [deviceData, setDeviceData] = useState({});
    const [monitorData, setMonitorData] = useState({});
    const [companyList, setCompanyList] = useState([]);
    const [scheduleData, setScheduleData] = useState([]);
    const [openScheduleSetting, setOpenScheduleSetting] = useState(false);
    const [editData, setEditData] = useState({});
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [execution, setExecution] = useState('');
    const [powerStatus, setPowerStatus] = useState(Boolean);
    const [cpu, setCpu] = useState(0);
    const [mem, setMem] = useState(0);
    const [selectedTask, setSelectedTask] = useState('power_on');
    const [recurrent, setRecurrent] = useState('daily');
    const [createScheduleTime, setCreateScheduleTime] = useState('');
    const [brokerConnected, setBrokerConnected] = useState(Boolean);

    const [isHistory, setIsHistory] = useState(false);

    const [ws, setWs] = useState(null);

    let param = useParams();
    let superUser = localStorage.getItem('super');
    let deviceID = Object.keys(param).length ? param.id : null;

    const showNotification = (type, title, content) => {
        notification.open({
            type: `${type}`,
            message: `${title}`,
            description: `${content}`,
            className: 'bmc__notification'
        });
    };
    const range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };
    // const disabledDateTime = () => ({
        // disabledHours: () => range(0, 24),
        // disabledMinutes: () => range(30, 60),
        // disabledSeconds: () => [55, 56],
    // });
        // socket = webSocket('http://192.168.5.214:8000/bmc-lites',{
        // socket = webSocket(`${apiUrl}/bmc-lites`, {
        //     auth: {
        //         token: accessToken,
        //     },
        // })

    // const socket = webSocket(`${apiUrl}/bmc-lites`, {auth: {token: accessToken}});
    
    

    const connectWebSocket = async () => {
        // console.log('開啟')
        setWs(webSocket(`${apiUrl}/bmc-lites`, {auth: {token: accessToken}}))
        // setWs(socket);
    };

    // const wsDataUpdate = (data) => {
    //     const index = wsDevData.findIndex((dev) => dev.uuid === data.uuid)
    //     wsDevData[index] = {...wsDevData[index], ...data } 
    //     setDeviceData([...wsDevData])
    // }

    const initWebSocket = (uuid) => {
        // console.log(uuid)
        // console.log(ws)
        // socket.on('connect', () => {
        //     socket.emit('subscribe', [uuid]);
        //     console.log('connect!!')
        // })
        ws.on('connect', () => {
            ws.emit('subscribe', [uuid]);
            console.log('connect')
        })

        ws.on('connect_error', (error) => {
            // console.log(error)
        })

        ws.on('monitor', (data) => {
            console.log(data)
            let temp = {
                monitor: data.monitor
            }
            setMonitorData(temp);
        });

        ws.on('connection', (data) => {
            // wsDataUpdate(data)
            // console.log('connection', data);
            if(!data.is_broker_connected) {
                setDeviceData({});
            } else {
                getDevice();
            }
        })

        ws.on('monitor_indication', (data) => {
            // console.log('monitor_indication', data);
            if(data.monitor.is_power_on) {
                setPowerStatus(true)
            } else {
                setPowerStatus(false)
            };
        });

        ws.on('update_status', (data) => {
            // console.log('update', data)
            setExecution(data.update_status);
            if(data.update_status == 'Update Completed') {
                setTimeout(() => {
                    setIsFilterOpen(false);
                }, 1000);
                
            }
        })

        ws.on('info', (data) => {
            // wsDataUpdate(data)
            // console.log('info', data);
            setCpu(data.cpu_usage);
            setMem(data.mem_usage);
            
        })
        // ws.on('disconnect', (reason) => {
        //     console.log(reason)
        //     console.log('Disconnected');
        //     if (reason === "io server disconnect") {
        //         // the disconnection was initiated by the server, you need to reconnect manually
        //         console.log('Try to reconnect');
        //         ws.connect();
        //     }
        //     // else the socket will automatically try to reconnect
        // });
    // socket.connect(uuid)

        // socket.on('monitor', (data) => {
        //     const index = wsDevData.findIndex((dev) => dev.uuid === data.uuid)
        //     data.monitor.is_power_on = wsDevData[index].monitor.is_power_on
        //     wsDataUpdate(data)
        //     // console.log('monitor', data);
        // });

        // socket.on('update_status', (data) => {
        //     wsDataUpdate(data)
        //     // console.log('update_status', data);
        // });

        // socket.on('monitor_indication', (data) => {
        //     wsDataUpdate(data)
        //     // console.log('monitor_indication', data);
        // });

        // socket.on('connection', (data) => {
        //     wsDataUpdate(data)
        //     // console.log('connection', data);
        // })

        // socket.on('info', (data) => {
        //     wsDataUpdate(data)
        //     // console.log('info', data);
        // })

        // socket.on('disconnect', () => {
        //     // console.log('disconnect');
        // })
    }


 


























    // const socket = webSocket(`${apiUrl}/bmc-lites`, {auth: {token: accessToken}});

    // const socketOn = (uuid) => {
    //     console.log('call socket on');
    //     socket.on('connect', () => {
    //         socket.emit('subscribe', [uuid]);
    //         console.log('Socket Connected.')
    //     })
    //     socket.on('connect_error', (error) => {
    //         console.log(error.message)
    //     })
    //     socket.on('monitor', (data) => {
    //         let temp = {
    //             monitor: data.monitor
    //         }
    //         setCpu(data.cpu_usage);
    //         setMem(data.mem_usage)
    //         setMonitorData(temp);
    //         // console.log(data)
    //     })
    //     socket.on('info', (data) => {
    //         // console.log(data)
    //         setCpu(data.cpu_usage);
    //         setMem(data.mem_usage)
    //     })
    //     socket.on('monitor_indication', (data) => {
    //         console.log(data)
    //     })
    // }

    // const socketOff = () => {
    //     console.log('call disco')
    //     socket.on('disconnect', () => {
    //         console.log('Socket Disconnected.')
    //     })
    // }

    let cpu_temp_value, sys_temp_value;
    if(deviceData.monitor && Object.keys(deviceData.monitor).length) {
        if(Array.isArray(monitorData.monitor.temperature) && !monitorData.monitor.temperature.length) {
            cpu_temp_value = 0;
            sys_temp_value = 0;
        } else {
            cpu_temp_value = monitorData.monitor.temperature[0].value;
            sys_temp_value = monitorData.monitor.temperature[1].value;
        }
    } else {
        cpu_temp_value = 0;
        sys_temp_value = 0;
    };

    // const [isConnected, setIsConnected] = useState(socket.connected);
    // useEffect(() => {
    //     function onConnect() {
    //         setIsConnected(true);
    //     }

    //     function onDisconnect() {
    //         setIsConnected(false);
    //     }

    //     // function onFooEvent(value) {
    //     //     setFooEvents(previous => [...previous, value]);
    //     // }

    //     socket.on('connect', onConnect);
    //     socket.on('disconnect', onDisconnect);
    //     // socket.on('foo', onFooEvent);

    //     return () => {
    //         socket.off('connect', onConnect);
    //         socket.off('disconnect', onDisconnect);
    //         // socket.off('foo', onFooEvent);
    //     };
    // }, []);

    // let socket;
    // const connectWebSocket = async () => {
    //     // socket = webSocket('http://192.168.5.214:8000/bmc-lites',{
    //     socket = webSocket(`${apiUrl}/bmc-lites`, {auth: {token: accessToken}})
    //     console.log(socket)
    // }

    // const initWebSocket = (uuid) => {
    //     socket.on('connect', () => {
    //         socket.emit('subscribe', uuid);
    //         console.log('connect')
    //     })

    //     socket.on('monitor', (data) => {
    //         // const index = wsDevData.findIndex((dev) => dev.uuid === data.uuid)
    //         // data.monitor.is_power_on = wsDevData[index].monitor.is_power_on
    //         // wsDataUpdate(data)
    //         console.log('monitor', data);
    //     });

    //     socket.on('update_status', (data) => {
    //         // wsDataUpdate(data)
    //         console.log('update_status', data);
    //     });

    //     socket.on('monitor_indication', (data) => {
    //         // wsDataUpdate(data)
    //         console.log('monitor_indication', data);
    //     });

    //     socket.on('connection', (data) => {
    //         // wsDataUpdate(data)
    //         console.log('connection', data);
    //     })

    //     socket.on('info', (data) => {
    //         // wsDataUpdate(data)
    //         console.log('info', data);
    //     })

    //     socket.on('disconnect', () => {
    //         console.log('disconnect');
    //     })
    // }

    // useEffect(() => {
    //     console.log(socket)
    //     // if (socket) {
    //     //     initWebSocket([deviceID])
    //     // }
    // },[socket])


    useEffect(() => {
        if(props.setActiveItem) props.setActiveItem('devices')
    }, [props.setActiveItem]);


    const normalUserGetDevice = async () => {
        await apiGetDevice({headers:{'Authorization': `Bearer ${accessToken}`}}, deviceID)
        .then(res => {
            // console.log(res.data)
            if(!ws) connectWebSocket();
            setDeviceData(res.data);
            setEditData(res.data);
            // console.log(res)
            if (res && res.data) {
                // if (res.data.monitor.is_power_on) {
                    setCpu(res.data.cpu_usage);
                    setMem(res.data.mem_usage);
                // } else {
                    // setCpu(0);
                    // setMem(0);
                // };
                setMonitorData({
                    cpu_usage: res.data.cpu_usage,
                    mem_usage: res.data.mem_usage,
                    monitor: res.data.monitor
                });
            };
        })
        .catch(err => {
            console.log(err.response)
            showNotification('error', 'Error!', `${err.response.data.detail}`)
        })
    }
    const getDevice = async () => {
            await apiAdminGetDevice({headers:{'Authorization': `Bearer ${accessToken}`}}, deviceID)
            .then(res => {
                // console.log(res.data)
                if(!ws) connectWebSocket();
                setDeviceData(res.data);
                setEditData(res.data);
                if (res && res.data) {
                    // if (res.data.monitor.is_power_on) {
                        setCpu(res.data.cpu_usage);
                        setMem(res.data.mem_usage);
                    // } else {
                        // setCpu(0);
                        // setMem(0);
                    // };
                    setMonitorData({
                        cpu_usage: res.data.cpu_usage,
                        mem_usage: res.data.mem_usage,
                        monitor: res.data.monitor
                    });
                };
            })
            .catch(err => {
                console.log(err.response)
                showNotification('error', 'Error!', `${err.response.data.detail}`)
            })
    };

    const getCompanyList = async () => {
        if(superUser) {
            await apiAdminGetCompanies({'Authorization': `Bearer ${accessToken}`})
            .then(res => {
                setCompanyList(res.data.data);
            })
            .catch(err => {
                showNotification('error', 'Error!', `${err.response.data.detail}`)
            })
        } else {
            return;
        }
    };

    const normalUserGetScheduleList = async () => {
        await apiGetDeviceControlSchedule({headers:{'Authorization': `Bearer ${accessToken}`}}, deviceID)
        .then(res => {
            // console.log(res)
            setScheduleData(res.data);
        })
        .catch(err => {
            // showNotification('error', 'Error!', `${err.response.data.detail}`)
        })
    };

    const getScheduleList = async () => {
        if(superUser) {
            await apiGetDeviceControlSchedule({headers:{'Authorization': `Bearer ${accessToken}`}}, deviceID)
            .then(res => {
                // console.log(res)
                setScheduleData(res.data);
            })
            .catch(err => {
                // showNotification('error', 'Error!', `${err.response.data.detail}`)
            })
        } else {
            return;
        };
    };

    const firmwareUpdate = async () => {
        await apiUpgradeBmcLite({headers:{'Authorization': `Bearer ${accessToken}`}}, deviceID)
        .then(res => {
            // getDevice();
            // setIsFilterOpen(false);
            // setExecution('');
            // showNotification('success', 'Success!', 'Successfully.')
        })
        .catch(err => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
        })
    };

    const deleteScheduleList = async (uuid, index) => {
        await apiDeleteDeviceControlSchedule({'Authorization': `Bearer ${accessToken}`}, uuid, index)
        .then(res => {
            getScheduleList();
            showNotification('success', 'Success!', 'Selected scheduled task has been successfully deleted.')
        })
        .catch(err => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
        })
    };

    const editDeviceDetail = async (uuid, body) => {
        await apiAdminModifyDevice({'Authorization': `Bearer ${accessToken}`}, body, uuid)
        .then(res => {
            getDevice();
            setIsEditOpen(!isEditOpen);
            showNotification('success', 'Success!', 'Edition has been successfully done.')
        })
        .catch(err => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
            setIsEditOpen(!isEditOpen);
        })
    };

    // apiCreateDeviceControlSchedule
    const createDeviceSchedule = async (uuid, body) => {
        console.log(body)
        await apiCreateDeviceControlSchedule({'Authorization': `Bearer ${accessToken}`}, body, uuid)
        .then(res => {
            console.log(res)
            getScheduleList();
            showNotification('success', 'Success!', 'Successfully create a scheduled task.')
        })
        .catch(err => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
        })
    };

    const handleDeviceEditOnChange = (e) => {
        let newDeviceData = {...editData, [e.target.name]: e.target.value};
        setEditData(newDeviceData);
    };

    const submitEdition = () => {
        if(isEditOpen) {
            let postBody = {
                name: editData.name,
                description: editData.description,
                company_id: editData.company_id
            };
            editDeviceDetail(deviceID, postBody);
        } else {
            setIsEditOpen(!isEditOpen);
        };
    };

    useEffect (() => {
        // console.log(props.me)
        if(props.me && props.me.is_superuser == false) {
            normalUserGetDevice();
            normalUserGetScheduleList()
        }
        if(props.me && props.me.is_superuser  == true) {
            getDevice();
            getCompanyList();
            getScheduleList();
        }
        
        if(!ws) connectWebSocket();
        // if(!socket) connectWebSocket(); 
        // console.log(socket)
    }, [deviceID]);

    useEffect(() => {
        if(deviceID && ws) {
            initWebSocket(deviceID);
        };
    }, [deviceID, ws]);

    useEffect(() => {
        if(deviceData) {
            setBrokerConnected(deviceData.is_broker_connected);
        };
        if(deviceData && Object.keys(deviceData).length) {
            let monitor = deviceData.monitor;
            setPowerStatus(monitor.is_power_on);
        };
    }, [deviceData])

    // useEffect(() => {
    //     console.log('----')
    //     if (socket) {
    //         initWebSocket(deviceID)
    //         console.log(socket)
    //     }
    // },[socket]);

    // useEffect (() => {
        
    //     if(Object.keys(deviceData).length !== 0) {
    //         if(deviceData.monitor.is_power_on) initWebSocket(deviceID);
    //         // socketOn(deviceID);
    //     }
    // }, [deviceData]);

    const cpu_temp_chart = {
        series: [
            {
            type: 'gauge',
            center: ['50%', '90%'],
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            splitNumber: 5,
            itemStyle: {
                color: '#485f6e'
            },
            progress: {
                show: true,
                width: 5
            },
            pointer: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    width: 10
                }
            },
            axisTick: {
                distance: -45,
                splitNumber: 5,
                lineStyle: {
                    width: 1,
                    color: '#485f6e'
                }
            },
            splitLine: {
                distance: -40,
                length: 15,
                lineStyle: {
                    width: 2,
                    color: '#485f6e'
                }
            },
            axisLabel: {
                distance: -40,
                color: '#485f6e',
                fontSize: 14
            },
            anchor: {
                show: false
            },
            title: {
                show: false
            },
            detail: {
                valueAnimation: true,
                width: '60%',
                lineHeight: 40,
                borderRadius: 8,
                offsetCenter: [0, '-15%'],
                fontSize: 25,
                fontWeight: 'bolder',
                formatter: '{value} °C',
                color: 'inherit'
            },
            data: [{
                value: cpu_temp_value
                    // value: deviceData.monitor ? deviceData.monitor.temperature.length ? deviceData.monitor.temperature[0].value : 0 : 0
                }]
            },
            {
            type: 'gauge',
            center: ['50%', '90%'],
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            itemStyle: {
                color: '#485f6e'
            },
            progress: {
                show: true,
                width: 1
            },
            pointer: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                show: false
            },
            detail: {
                show: false
            },
            data: [{
                value: cpu_temp_value
                    // value: deviceData.monitor ? deviceData.monitor.temperature.length ? deviceData.monitor.temperature[0].value : 0 : 0
                }]
            }
        ]
    }

    const sys_temp_chart = {
        series: [
            {
            type: 'gauge',
            center: ['50%', '90%'],
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            splitNumber: 5,
            itemStyle: {
                color: '#486e4a'
            },
            progress: {
                show: true,
                width: 5
            },
            pointer: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    width: 10
                }
            },
            axisTick: {
                distance: -45,
                splitNumber: 5,
                lineStyle: {
                    width: 1,
                    color: '#486e4a'
                }
            },
            splitLine: {
                distance: -40,
                length: 15,
                lineStyle: {
                    width: 2,
                    color: '#486e4a'
                }
            },
            axisLabel: {
                distance: -40,
                color: '#486e4a',
                fontSize: 14
            },
            anchor: {
                show: false
            },
            title: {
                show: false
            },
            detail: {
                valueAnimation: true,
                width: '60%',
                lineHeight: 40,
                borderRadius: 8,
                offsetCenter: [0, '-15%'],
                fontSize: 25,
                fontWeight: 'bolder',
                formatter: '{value} °C',
                color: 'inherit'
            },
            data: [
                {
                    value: sys_temp_value
                    // value: deviceData.monitor ? deviceData.monitor.temperature.length ? deviceData.monitor.temperature[1].value : 0 : 0
                }
            ]
            },
            {
            type: 'gauge',
            center: ['50%', '90%'],
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            itemStyle: {
                color: '#486e4a'
            },
            progress: {
                show: true,
                width: 1
            },
            pointer: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                show: false
            },
            detail: {
                show: false
            },
            data: [{value: sys_temp_value}]
            }
        ]
    }

    const powerOnDevice = async (uuid) => {
        await apiPowerOnDevice({headers:{'Authorization': `Bearer ${accessToken}`}}, uuid)
        .then(res => {
            setIsFilterOpen(false);
            setExecution('');
            showNotification('success', 'Success!', `${res.data.msg}`)
            // socketOn();
        })
        .catch(err => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
            setIsFilterOpen(false);
            setExecution('');
        })
    };
    const powerOffDevice = async (uuid) => {
        await apiPowerOffDevice({headers:{'Authorization': `Bearer ${accessToken}`}}, uuid)
        .then(res => {
            setIsFilterOpen(false);
            setExecution('');
            showNotification('success', 'Success!', `${res.data.msg}`)
            getDevice()
            // socketOff();
            // setCpu(0);
            // setMem(0);
            // setMonitorData({});
        })
        .catch(err => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
            setIsFilterOpen(false);
            setExecution('');
            
        })
    };

    const rebootDevice = async (uuid) => {
        // console.log(uuid)
        await apiRebootDevice({headers:{'Authorization': `Bearer ${accessToken}`}}, uuid)
        .then(res => {
            setIsFilterOpen(false);
            setExecution('');
            showNotification('success', 'Success!', `${res.data.msg}`)
            getDevice();
            // socketOn();
        })
        .catch(err => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
            setIsFilterOpen(false);
            setExecution('');
        })
    };

    const okToSetPower = (value, intention, id) => {
        setIsFilterOpen(true);
        setExecution(`Please wait for ${intention}`);
        if(value) {
            powerOnDevice(id)
        } else {
            powerOffDevice(id);
        }
    };

    const okToReboot = (id) => {
        setIsFilterOpen(true);
        setExecution('Please wait for Rebooting.');
        rebootDevice(id)
    };

    const showScheduleSetting = (id) => {
        setIsFilterOpen(true);
        setOpenScheduleSetting(true);
    };

    const handleUpdating = (id) => {
        setIsFilterOpen(true);
        setExecution('Updating');
        firmwareUpdate(id);
    }

    const handleDatePicker = (e) => {
        console.log(e)
        let yy,mm,dd,h,m,s;
        yy = e.$y;
        mm = e.$M + 1;
        dd = e.$D;
        h = e.$H;
        m = e.$m;
        s = e.$s;
        if(mm < 10) mm = '0' + mm
        if(dd < 10) dd = '0' + dd
        if(h < 10) h = '0' + h
        if(m < 10) m = '0' + m
        if(s < 10) s = '0' + s
        let time = `${yy}-${mm}-${dd} ${h}:${m}:${s}`
        setCreateScheduleTime(time);
    };



    const saveScheduleSetting = () => {
        let postBody = {
            scheduled_date: createScheduleTime,
            task: selectedTask,
            recurrent: recurrent
        };
        createDeviceSchedule(deviceID, postBody)
    }
    const showUpdateConfirm = (id) => {
        confirm({
            title: 'Warming!',
            icon: <ExclamationCircleFilled />,
            content: `Would you like to update?`,
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleUpdating(id)
            }
        });
    };
    const showPowerOffConfirm = (id) => {
        let intention;
        let value;
        if(deviceData && deviceData.monitor !== undefined && deviceData.monitor.is_power_on) {
            value = false
            intention = 'Power Off'
        } else {
            value = true
            intention = 'Power On'
        }
        confirm({
            title: 'Warming!',
            icon: <ExclamationCircleFilled />,
            content: `Are you sure to ${intention}?`,
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                okToSetPower(value, intention, id)
            }
        });
    };
    const showRebootConfirm = (id) => {
        confirm({
            title: 'Warming!',
            icon: <ExclamationCircleFilled />,
            content: `Are you sure to reboot device?`,
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                // console.log(id, index);
                okToReboot(id)
            }
        });
    };
    const showDeleteScheduleConfirm = (id) => {
        confirm({
            title: 'Warming!',
            icon: <ExclamationCircleFilled />,
            content: `Are you sure to delete this schedule?`,
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteScheduleList(deviceID, id)
            }
        });
    };

    // console.log(deviceID)

    return (
        <div className={styles.device_detail_page}>
            {isHistory && 
                <HistoryPage device_data ={deviceData} back={setIsHistory}/>
            }
            {isFilterOpen && 
                <div className={styles.device_detail_filter}>
                    {!openScheduleSetting && 
                        <div className={styles.device_power_execution}>
                            <span>{execution}</span>
                        </div>
                    }
                    {openScheduleSetting && 
                        <div className={styles.device_schedule_setting_block}>
                            <div className={styles.device_schedule_list}>
                                {scheduleData && scheduleData.map((s, i) => {
                                    return(
                                    <div className={styles.device_schedule_detail} key={i}>
                                        <div className={styles.device_schedule_detail_delete} onClick={(e) => {e.stopPropagation(); showDeleteScheduleConfirm(s.index)}}>
                                            <span>X</span>
                                        </div>
                                        <div className={styles.device_schedule_detail_row}>
                                            <span>{s.scheduled_date}</span>
                                        </div>
                                        <div className={styles.device_schedule_detail_row}>
                                            <span>Task:</span>
                                            <span>{s.task == 'power_on' ? 'Power On' : s.task == 'power_off' ? 'Power Off' : 'Power Reboot'}</span>
                                        </div>
                                        <div className={styles.device_schedule_detail_row}>
                                            <span>Recurrent:</span>
                                            <span>{s.recurrent == 'daily' ? 'Daily' : s.recurrent == 'monthly' ? 'Monthly' : 'Once'}</span>
                                        </div>
                                    </div>
                                    )
                                    
                                })}
                            </div>
                            <div className={styles.device_schedule_setting}>
                                <div className={styles.device_schedule_setting_date}>
                                    <div className={styles.device_schedule_setting_picker}>
                                        <Space direction="vertical">
                                            <DatePicker
                                                className={styles.schedule_picker}
                                                format="YYYY-MM-DD HH:mm:ss"
                                                disabledDate={disabledDate}
                                                // disabledTime={disabledDateTime}
                                                showNow={false}
                                                onOk={(e) => {handleDatePicker(e)}}
                                                showTime={{
                                                    defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                                                }}
                                            />
                                        </Space>
                                    </div>
                                    <div className={styles.device_schedule_setting_column}>
                                        <div className={selectedTask == 'power_on' ? styles.device_schedule_setting_btn_active : styles.device_schedule_setting_btn}
                                            onClick={(e) => {e.stopPropagation(); setSelectedTask('power_on')}}
                                        >
                                            <span>Power On</span>
                                        </div>
                                        <div className={selectedTask == 'power_off' ? styles.device_schedule_setting_btn_active : styles.device_schedule_setting_btn}
                                            onClick={(e) => {e.stopPropagation(); setSelectedTask('power_off')}}
                                        >
                                            <span>Power Off</span>
                                        </div>
                                        <div className={selectedTask == 'power_reboot' ? styles.device_schedule_setting_btn_active : styles.device_schedule_setting_btn}
                                            onClick={(e) => {e.stopPropagation(); setSelectedTask('power_reboot')}}
                                        >
                                            <span>Reboot</span>
                                        </div>
                                        <div className={recurrent == 'monthly' ? styles.device_schedule_setting_btn_active : styles.device_schedule_setting_btn}
                                            onClick={(e) => {e.stopPropagation(); setRecurrent('monthly')}}
                                        >
                                            <span>Monthly</span>
                                        </div>
                                        <div className={recurrent == 'daily' ? styles.device_schedule_setting_btn_active : styles.device_schedule_setting_btn}
                                            onClick={(e) => {e.stopPropagation(); setRecurrent('daily')}}
                                        >
                                            <span>Daily</span>
                                        </div>
                                        <div className={recurrent == 'never' ? styles.device_schedule_setting_btn_active : styles.device_schedule_setting_btn}
                                            onClick={(e) => {e.stopPropagation(); setRecurrent('never')}}
                                        >
                                            <span>Once</span>
                                    </div>
                                </div>
                                </div>
                                <div className={styles.device_schedule_setting_action}>
                                    <div className={styles.device_schedule_setting_cancel_btn} onClick={(e) => {e.stopPropagation(); setOpenScheduleSetting(false); setIsFilterOpen(false)}}>
                                        <span>Cancel</span>
                                    </div>
                                    <div className={styles.device_schedule_setting_save_btn} onClick={(e) => {e.stopPropagation(); saveScheduleSetting();}}>
                                        <span>Save</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    }
                </div>
            }
            <div className={styles.device__detail__header__title__wrap}>
            <div className={styles.device__detail__header__basic__info}>
                <div className={styles.device__detail__title__active}><span>Device</span></div>
                <div className={styles.device__detail__title}><span>{deviceData ? deviceID : 'N/A'}</span></div>
                {/* {deviceData && deviceData.monitor !== undefined && deviceData.monitor.is_power_on ?
                    <span className={styles.online_status}></span> 
                    :<span className={styles.offline_status}></span>
                }
                {deviceData && deviceData.monitor == undefined  && 
                    <span className={styles.offline_status}></span>
                } */}
                {brokerConnected ? <span className={styles.online_status}></span>  : <span className={styles.offline_status}></span>}
                
            </div>
            <div className={styles.device__detail__header__actions}>
                {deviceData.is_update_available && 
                    <div className={styles.device__detail__header__update__btn} onClick={(e) => {e.stopPropagation(); showUpdateConfirm(deviceID.uuid);}}>
                        <span>Update</span>
                    </div>
                }
                {props.me && props.me.is_superuser && 
                <>
                    <div className={styles.device__detail__header__edit__btn} onClick={(e) => {e.stopPropagation(); submitEdition();}}>
                        <span>{isEditOpen ? 'Save' : 'Edit'}</span>
                    </div>
                    <div className={styles.device__detail__header__power__off__btn} onClick={(e) => {e.stopPropagation(); showPowerOffConfirm(deviceData.uuid);}}>
                        <span>{powerStatus ? 'Power off' : 'Power on'}</span>
                    </div>
                    <div className={styles.device__detail__header__reboot__btn} onClick={(e) => {e.stopPropagation(); showRebootConfirm(deviceData.uuid);}}>
                        <span>Reboot</span>
                    </div>
                    <div className={styles.device__detail__header__schedule__btn} onClick={(e) => {e.stopPropagation(); showScheduleSetting(deviceData.uuid);}}>
                        <span>Set schedule</span>
                    </div>
                    <div className={styles.device__detail__header__history__btn} onClick={(e) => {e.stopPropagation(); setIsHistory(true)}}>
                        <span>View history</span>
                    </div>
                </>
                }
                {props.me && !props.me.is_superuser && props.me.permissions.includes("bml:w") &&
                <>
                    <div className={styles.device__detail__header__edit__btn} onClick={(e) => {e.stopPropagation(); submitEdition();}}>
                        <span>{isEditOpen ? 'Save' : 'Edit'}</span>
                    </div>
                    <div className={styles.device__detail__header__history__btn} onClick={(e) => {e.stopPropagation(); setIsHistory(true)}}>
                        <span>View history</span>
                    </div>
                </>
                }
                {props.me && !props.me.is_superuser && Boolean(props.me.permissions.includes("bml:x")) &&
                <>
                    <div className={styles.device__detail__header__power__off__btn} onClick={(e) => {e.stopPropagation(); showPowerOffConfirm(deviceData.uuid);}}>
                        <span>{powerStatus ? 'Power off' : 'Power on'}</span>
                    </div>
                    <div className={styles.device__detail__header__reboot__btn} onClick={(e) => {e.stopPropagation(); showRebootConfirm(deviceData.uuid);}}>
                        <span>Reboot</span>
                    </div>
                    <div className={styles.device__detail__header__schedule__btn} onClick={(e) => {e.stopPropagation(); showScheduleSetting(deviceData.uuid);}}>
                        <span>Set schedule</span>
                    </div>
                </>
                }
                {/* {props.me && !props.me.is_superuser && !Boolean(props.me.permissions.includes("bml:w")) &&
                <>
                    <div className={styles.device__detail__header__edit__btn} onClick={(e) => {e.stopPropagation(); submitEdition();}}>
                        <span>{isEditOpen ? 'Save' : 'Edit'}</span>
                    </div>
                    <div className={styles.device__detail__header__power__off__btn} onClick={(e) => {e.stopPropagation(); showPowerOffConfirm(deviceData.uuid);}}>
                        <span>{powerStatus ? 'Power off' : 'Power on'}</span>
                    </div>
                    <div className={styles.device__detail__header__reboot__btn} onClick={(e) => {e.stopPropagation(); showRebootConfirm(deviceData.uuid);}}>
                        <span>Reboot</span>
                    </div>
                    <div className={styles.device__detail__header__schedule__btn} onClick={(e) => {e.stopPropagation(); showScheduleSetting(deviceData.uuid);}}>
                        <span>Set schedule</span>
                    </div>
                </>
                } */}
                {props.me && !props.me.is_superuser && !Boolean(props.me.permissions.includes("bml:w")) && !Boolean(props.me.permissions.includes("bml:x")) && Boolean(props.me.permissions.includes("bml:r")) &&
                <>
                    <div className={styles.device__detail__header__history__btn} onClick={(e) => {e.stopPropagation(); setIsHistory(true)}}>
                        <span>View history</span>
                    </div>
                </>
                }
            </div>
            </div>
            <div className={styles.device__detail__content}>
                <div className={styles.device__detail__row}>
                    <div className={styles.device__detail__content__item}>
                        <div className={styles.device__detail__content__item__title}>
                            <span>Served on</span>
                        </div>
                        <div className={styles.device__detail__content__item__body}>
                            {isEditOpen ? 
                                <input type="text" name="name" value={editData.name} onChange={handleDeviceEditOnChange}/> 
                                : 
                                <span>{deviceData.name ? deviceData.name : 'N/A'}</span>
                            }
                        </div>
                    </div>
                    <div className={styles.device__detail__content__item}>
                        <div className={styles.device__detail__content__item__title}>
                            <span>Description</span>
                        </div>
                        <div className={styles.device__detail__content__item__body}>
                            {isEditOpen ? 
                                <input type="text" name="description" value={editData.description ? editData.description : ""} onChange={handleDeviceEditOnChange}/> 
                                : 
                                <span>{deviceData.description ? deviceData.description : 'N/A'}</span>
                            }
                        </div>
                    </div>
                    <div className={styles.device__detail__content__item}>
                        <div className={styles.device__detail__content__item__title}>
                            <span>Company</span>
                        </div>
                        <div className={styles.device__detail__content__item__body}>
                            {isEditOpen ? 
                                <select name="company_id" onChange={handleDeviceEditOnChange}>
                                    <option value={editData.company_id}>{editData.company_name}</option>
                                    { companyList && companyList.map((c, i) => {
                                    return <option value={c.id} key={i}>{c.name}</option>
                                })}
                                </select>
                                // <input type="text" value={deviceData.company_name ? deviceData.company_name : ""} /> 
                                : 
                                <span>{deviceData.company_name ? deviceData.company_name : 'N/A'}</span>
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.device__detail__row}>
                    <div className={styles.device__detail__content__item}>
                        <div className={styles.device__detail__content__item__title}>
                            <span>Model</span>
                        </div>
                        <div className={styles.device__detail__content__item__body}>
                            <span>
                                {deviceData.bmc_lite_model_name ? deviceData.bmc_lite_model_name + ' (ver ' + deviceData.version + ')' : 'N/A'}
                            </span>
                        </div>
                    </div>
                    <div className={styles.device__detail__content__item}>
                        <div className={styles.device__detail__content__item__title}>
                            <span>Credential</span>
                        </div>
                        <div className={styles.device__detail__content__item__body__credential}>
                            <span>{deviceData.credential_name ? deviceData.credential_name : 'N/A'}</span>
                            <span>{deviceData.expired_at ? 'Expired at:' + deviceData.expired_at : 'No data'}</span>
                        </div>
                        {/* {data.is_update_available && 
                            <div className={styles.device__detail__content__item__body__model__update}>
                                <span>Update</span>
                            </div>
                        } */}
                    </div>
                    <div className={styles.device__detail__content__item}>
                        <div className={styles.device__detail__content__item__title}>
                            <span>Connection</span>
                        </div>
                        <div className={styles.device__detail__content__item__body__connection}>
                            <div className={styles.device__detail__content__connection__name}>
                                <span>IP:</span>
                                <span>DNS:</span>
                                <span>Gateway:</span>
                                <span>Broker Connection:</span>
                            </div>
                            <div className={styles.device__detail__content__connection__value}>
                                <span>{deviceData.ip ? deviceData.ip : 'N/A'}</span>
                                <span>{deviceData.dns ? deviceData.dns : 'N/A'}</span>
                                <span>{deviceData.gateway ? deviceData.gateway : 'N/A'}</span>
                                <span>{deviceData.is_broker_connected ? 'Connected' : 'No connection'}</span>
                            </div>
                        </div>
                    </div>
                    {/* <div className={styles.device__detail__content__btn__group}>
                        <div className={styles.device__detail__content__btns}>
                            <div className={styles.device__detail__content__permission_btn}>
                                <span>Permission</span>
                            </div>
                            <div className={styles.device__detail__content__log_btn}>
                                <span>Session</span>
                            </div>
                            <div className={styles.device__detail__content__log_btn}>
                                <span>Log</span>
                            </div>
                        </div>
                        <div className={styles.device__detail__content__btns}>
                            {isEditOpen ? 
                                <>
                                    <div className={styles.device__detail__content__cancel_btn} onClick={(e) => {e.stopPropagation(); setIsEditOpen(false)}}>
                                        <span>Cancel</span>
                                    </div>
                                    <div className={styles.device__detail__content__save_btn} onClick={(e) => {e.stopPropagation(); setIsEditOpen(!isEditOpen)}}>
                                        <span>Save</span>
                                    </div>
                                </>
                                :
                                <>
                                    <div className={styles.device__detail__content__delete_btn}>
                                        <span>Delete User</span>
                                    </div>
                                    <div className={styles.device__detail__content__edit_btn} onClick={(e) => {e.stopPropagation(); setIsEditOpen(!isEditOpen)}}>
                                        <span>Edit User</span>
                                    </div>
                                </>
                            }
                        </div>
                    </div> */}
                </div>
                <div className={styles.device__detail__row}>
                    <div className={styles.device__detail__content__item__temp__vol}>
                        <div className={styles.device__detail__content__item__body__temp__wrap}>
                            <div className={styles.device__detail__content__item__body__temp__title}>
                                <span>CPU Temperature</span>
                            </div>
                            {/* {deviceData && deviceData.monitor && deviceData.monitor.temperature && deviceData.monitor.temperature.length &&  */}
                                <div className={styles.device__detail__content__temp}>
                                    <ReactECharts
                                        option={cpu_temp_chart}
                                        style={{height: '100%', width: '100%'}}
                                    />
                                </div>
                            {/* } */}
                        </div>
                        <div className={styles.device__detail__content__item__body__temp__wrap}>
                            <div className={styles.device__detail__content__item__body__temp__title}>
                                <span>System Temperature</span>
                            </div>
                            {/* {deviceData && deviceData.monitor && deviceData.monitor.temperature && deviceData.monitor.temperature.length &&  */}
                                <div className={styles.device__detail__content__temp}>
                                    <ReactECharts
                                        option={sys_temp_chart}
                                        style={{height: '100%', width: '100%'}}
                                    />
                                </div>
                            {/* } */}
                        </div>
                        <div className={styles.device__detail__vol__wrap}>
                            <div className={styles.device__detail__vol__usage__block__title}>
                                <span>Voltage</span>
                            </div>
                            <div className={styles.device__detail__vol__usage__block__vol__content}>
                                <div className={styles.device__detail__content__vol}>
                                    <span>CPU Vcore:</span>
                                    <span>3.3V:</span>
                                    <span>VSB5V:</span>
                                    <span>VSB3.3V:</span>
                                    <span>VBAT:</span>
                                    <span>VDIMM:</span>
                                </div>
                                <div className={styles.device__detail__content__vol}>
                                {deviceData && deviceData.monitor == undefined && 
                                <>
                                    <span>No Data</span>
                                    <span>No Data</span>
                                    <span>No Data</span>
                                    <span>No Data</span>
                                    <span>No Data</span>
                                </>
                                }
                                {deviceData && deviceData.monitor !== undefined && (Object.keys(deviceData.monitor).length == 0) &&
                                <>
                                    <span>No Data</span>
                                    <span>No Data</span>
                                    <span>No Data</span>
                                    <span>No Data</span>
                                    <span>No Data</span>
                                </>
                                }
                                {deviceData && deviceData.monitor !== undefined && deviceData.monitor.voltage && 
                                <>
                                    <span>{monitorData.monitor ? monitorData.monitor.voltage.length ? monitorData.monitor.voltage[0].value : 'No Data' : 'No Data'}</span>
                                    <span>{monitorData.monitor ? monitorData.monitor.voltage.length ? monitorData.monitor.voltage[1].value : 'No Data' : 'No Data'}</span>
                                    <span>{monitorData.monitor ? monitorData.monitor.voltage.length ? monitorData.monitor.voltage[2].value : 'No Data' : 'No Data'}</span>
                                    <span>{monitorData.monitor ? monitorData.monitor.voltage.length ? monitorData.monitor.voltage[3].value : 'No Data' : 'No Data'}</span>
                                    <span>{monitorData.monitor ? monitorData.monitor.voltage.length ? monitorData.monitor.voltage[4].value : 'No Data' : 'No Data'}</span>
                                    <span>{monitorData.monitor ? monitorData.monitor.voltage.length ? monitorData.monitor.voltage[5].value : 'No Data' : 'No Data'}</span>
                                </>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.device__detail__content__item__usage}>
                        <div className={styles.device__detail__vol__usage__block}>
                            
                                <div className={styles.device__detail__vol__usage__block__title}>
                                    <span>CPU Usage</span>
                                </div>
                                <div className={styles.device__detail__vol__usage__block__usage__content}>
                                    <div className={styles.device__detail__usage__outer__cpu}>
                                        <div className={styles.device__detail__usage__inner}>
                                            <span>{cpu ? cpu + '%' : '0%'}</span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className={styles.device__detail__vol__usage__block}>
                            
                                <div className={styles.device__detail__vol__usage__block__title}>
                                    <span>Memory Usage</span>
                                </div>
                                <div className={styles.device__detail__vol__usage__block__usage__content}>
                                    <div className={styles.device__detail__usage__outer__mem}>
                                        <div className={styles.device__detail__usage__inner}>
                                            <span>{mem ? mem  + '%' : '0%'}</span>
                                        </div>
                                    </div>
                                </div>
                            
                        </div>
                        {/* <div className={styles.device__detail__content__item__title}>
                            <span>Voltage</span>
                        </div>
                        <div className={styles.device__detail__content__item__body__vol__wrap}>
                            <div className={styles.device__detail__content__vol}>
                            <span>CPU Vcore:</span>
                            <span>3.3V:</span>
                            <span>VSB5V:</span>
                            <span>VSB3.3V:</span>
                            <span>VBAT:</span>
                            <span>VDIMM:</span>
                            </div>
                            <div className={styles.device__detail__content__vol}>
                            <span>{data.monitor.voltage[0].value}</span>
                            <span>{data.monitor.voltage[1].value}</span>
                            <span>{data.monitor.voltage[2].value}</span>
                            <span>{data.monitor.voltage[3].value}</span>
                            <span>{data.monitor.voltage[4].value}</span>
                            <span>{data.monitor.voltage[5].value}</span>
                            </div>
                        </div> */}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DeviceDetailPage;