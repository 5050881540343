import { useState, useEffect } from "react";
import { SearchBar } from '../SearchBar';
import { apiAdminGetDeviceFiles, apiAdminUploadDeviceFiles, apiAdminDeleteDeviceFiles } from '../../api';
import { PageLoader } from "../Loader";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from './AdminFile.module.scss';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, notification } from 'antd';
const { confirm } = Modal;

const VersionPage = (props) => {

    let scroll_height = "77vh"

    let addFile,
    hasMoreScroll = true,
    delayTime = 0,
    newModelData
    

    let selectModel = JSON.parse(localStorage.getItem('select-model'));
    let fileParams = {
        keyword: null,
        bmc_lite_model_id: selectModel.id,
        sort_by: "created_at_desc",
        skip: 0,
        limit: 1000
    };
    const accessToken = JSON.parse(localStorage.getItem('access_token'));

    const [ isOnClick, setIsOnClick ] = useState({click: "", index: ""})
    const [ isAddFile, setIsAddFile ] = useState(false)
    const [ bmcLiteFilesData, setBmcLiteFilesData ] = useState([])
    const [ backupData, setBackupData ] = useState("")
    const [ searchWords, setSearchWords ] = useState()

    const showNotification = (type, title, content) => {
        notification.open({
            type: `${type}`,
            message: `${title}`,
            description: `${content}`,
            className: 'bmc__notification'
        });
    };

    const getAllDeviceFiles = async (keywords) => {
        
        await apiAdminGetDeviceFiles({'Authorization': `Bearer ${accessToken}`}, fileParams)
        .then(res => {
            setBmcLiteFilesData(res.data.data)
            hasMoreScroll = false
        }).catch(err => {
            setBmcLiteFilesData([])
        })
        delayTime = 0
    }

    const deleteFileOnClick = async (id, index) => {
        await apiAdminDeleteDeviceFiles({headers: {'Authorization': `Bearer ${accessToken}`}}, id)
        .then(res => {
            bmcLiteFilesData.splice(index, 1)
            setIsOnClick({click: "", index: ""})
            getAllDeviceFiles()
        })
        .catch(err => {
            showNotification('error', 'Error!', `${err.response.data.detail}`)
        })
    }

    const fileChosen = (e) => {
        addFile = {...addFile, file: e.target.files[0]}
        document.getElementById("file_chosen").textContent = e.target.files[0].name
    };

    const handleAllFilesOnClick = () => {
        document.getElementById("search_input").value = ""
        setIsAddFile(false)
        if (backupData) {
            setBmcLiteFilesData(backupData)
        }
    }

    const handleAddFileOnChange = (e) => {
        addFile = {...addFile, [e.target.name]: e.target.value}
    };
    
    const handleSearchFilesOnChange = (e) => {
        delayTime = 500
        const value = e.target.value
        setSearchWords(value)
        if (!value) {
            fileParams.keyword = null
            getAllDeviceFiles()
        }
        setIsOnClick({click: "", index: ""})
    }
    
    const initAddFile = () => {
        addFile = {
            bmc_lite_model_id : fileParams.bmc_lite_model_id,
            is_latest: false
        }
        setIsOnClick({click: "add", index: ""})
        setIsAddFile(!isAddFile)
    }

    const uploadFileOnClick = () => {
        setIsAddFile(false)
        setIsOnClick({click: "", index: ""})
        bmcLiteFilesData.splice(0, 0, {id: "uploading" ,name: "Uploading...", created_at: "---------- --:--:--", is_latest: false, uploading: true})
        setBmcLiteFilesData([...bmcLiteFilesData])
        apiAdminUploadDeviceFiles({
            headers:{
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data'
            }},
            addFile
        ).then(res => {
            getAllDeviceFiles()
            setIsAddFile(false)
            setIsOnClick({click: "", index: ""})
        }).catch(err => {
            // console.log('err', err);
            setIsOnClick({click: "", index: ""})
            getAllDeviceFiles()
        })
    }

    const formatDate = (date) => {
        return new Date(date).toLocaleString('sv')
    }

    const showVersionDeleteConfirm = (data, id, index) => {
        confirm({
            title: 'Warming!',
            icon: <ExclamationCircleFilled />,
            content: `Are you sure delete this version, v${data.name}?`,
            centered: true,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
                deleteFileOnClick(data.id, index);
            }
        });
    };

    useEffect(() => {
        getAllDeviceFiles();
        props.setActiveItem('files');
    }, [])

    return (
        <div className={styles.file_page}>
            <SearchBar 
                name="Files"
                all={handleAllFilesOnClick}
                search={handleSearchFilesOnChange}
                add={initAddFile}
            />
            <div className={styles.file_header}>
                <div className={styles.file__header__item}>
                    <span>Version aaaaa</span>
                </div>
                <div className={styles.file__header__item}>
                    <span>Create Time</span>
                </div>
                <div className={styles.file__header__item}></div>
                <div className={styles.file__header__item__action}>
                    <span>Action</span>
                </div>
            </div>
            { isOnClick.click === "add" && 
                <div className={styles.file__data__add__wrap} >
                    {/* {setHeight("54vh")} */}
                    <div className={styles.file__data__upload__block}>
                        <label htmlFor="add_file_name">Version</label>
                        <input id="add_file_name" name="name" autoComplete="off" placeholder="Version" onChange={handleAddFileOnChange}/>
                        <label htmlFor="add_file_is_latest">Latest Version</label>
                        <select id="add_file_is_latest" name="is_latest" defaultValue="false" onChange={handleAddFileOnChange}>
                            <option value="true">Yes</option> 
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className={styles.file__data__upload__block}>
                        <div className={styles.file__data__upload}>
                            <label>Upload File</label>
                            <input type="file" id="file_uploader" name="file" onChange={fileChosen} hidden/>
                            <label className={styles.file_uploader} htmlFor="file_uploader">Choose File</label>
                            <label id="file_chosen" htmlFor="file_uploader">No File Chosen</label>
                        </div>
                    </div>
                    <div className={styles.file__data__upload__btn__group}>
                        <div className={styles.file__add__cancel__btn} onClick={() => setIsOnClick({click: "", index: ""})}>
                            <span>Cancel</span>
                        </div>
                        <div className={styles.file__add__create__btn} onClick={() =>  uploadFileOnClick()}>
                            <span>Upload</span>
                        </div>
                    </div>
                </div>
            }
            { bmcLiteFilesData && 
            <div className={styles.file_content}>
                <InfiniteScroll
                    style={{"overflowY":"overlay", "overflowX":"hidden"}}
                    dataLength={bmcLiteFilesData.length} 
                    hasMore={hasMoreScroll}
                    next={undefined}
                    loader={<PageLoader />}
                    height={scroll_height}
                >
                    <div className={styles.files__group}>
                        { bmcLiteFilesData.map((data, index) => {
                            return (
                                <div className={styles.file_data} key={data.id}>
                                    <div className={styles.file__content__item}>
                                        <span>{data.name}</span>
                                    </div>
                                    <div className={styles.file__content__item}>
                                        <span>{formatDate(data.created_at)}</span>
                                    </div>
                                    <div className={styles.file__content__item}>
                                        {data.is_latest ? <div className={styles.latest_status}><span>Latest</span></div> : ""}
                                    </div>
                                    { isOnClick.index !== index && !data.uploading &&
                                    <div className={styles.file__content__item__action}>
                                        <div className={styles.file__models__edit__btn}
                                            onClick={(e) => {e.stopPropagation(); localStorage.setItem('vid', data.id)}}
                                        >
                                            <Link to={`/admin/oob-models/${selectModel.name}/${data.name}`}>
                                                <span>Detail</span>
                                            </Link>
                                        </div>
                                        <div className={styles.file__models__delete__btn}
                                            onClick={(e) => {e.stopPropagation(); showVersionDeleteConfirm(data, data.id, index);}}
                                        >
                                            <span>Delete</span>
                                        </div>
                                    </div>
                                    }                
                                    {data.uploading &&
                                    <span>
                                        <div className={styles.loader_upload}></div>
                                    </span>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </InfiniteScroll>
            </div>
            }
        </div>
    )
}

export default VersionPage;