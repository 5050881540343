import { useEffect, useState } from 'react';
import { ModifyButton, NoButton, YesButton } from '../ActionButton';
import styles from './ReleaseNotePage.module.scss'
import Markdown from 'react-markdown'
import { apiAdminModifyDeviceFiles, apiAdminGetVersionDetail } from '../../api';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, notification } from 'antd';
const { confirm } = Modal;

let oldFileData = {
    description: null
}
//{file_data, back, showLatestConfirm}
const ReleaseNotePage = (props) => {


    const accessToken = JSON.parse(localStorage.getItem('access_token'))

    let selectModel = JSON.parse(localStorage.getItem('select-model'));
    let selectVersion = JSON.parse(localStorage.getItem('vid'));
    let newFileData;
    const [ isOnClick, setIsOnClick ] = useState("")
    const [ fileData, setFileData ] = useState({})
    const [isLatest, setIsLatest] = useState(false);

    const showNotification = (type, title, content) => {
        notification.open({
            type: `${type}`,
            message: `${title}`,
            description: `${content}`,
            className: 'bmc__notification'
        });
    };

    const getVersionDetail = async () => {
        await apiAdminGetVersionDetail({headers:{'Authorization': `Bearer ${accessToken}`}}, selectVersion)
        .then((res) => {
            setFileData(res.data);
        })
        .catch((err) => {

        });
    };

    const handleLatestOnChange = (e) => {
        newFileData = {is_latest: true}
    }

    const handleModifyOnClick = () => {
        setIsOnClick("edit")
    }

    const modifyFile = async (id) => {
        await apiAdminModifyDeviceFiles({headers:{'Authorization': `Bearer ${accessToken}`}}, fileData, id)
        .then(res => {
            setFileData({...fileData})
            setIsOnClick("")
            showNotification('success', 'Success!', 'Edition has been successfully done.')
        }).catch(err => {

        })
    }
    const modifyFileOnClick = (id) => {
        apiAdminModifyDeviceFiles({headers:{'Authorization': `Bearer ${accessToken}`}}, newFileData, id)
        .then(res => {
            setIsOnClick({click: "", index: ""})
            getVersionDetail();
            showNotification('success', 'Success!', 'Set latest successfully.')
        })
        .catch(err => {
            console.log(err.response)
            setIsOnClick({click: "", index: ""})
        })
    }


    useEffect(() => {
        getVersionDetail();
        props.setActiveItem('files');
    }, []);

    const showLatestConfirm = (data, id, index) => {
        confirm({
            title: 'Warming!',
            icon: <ExclamationCircleFilled />,
            content: `Would you like to set this version, v${data.name}, as the Latest?`,
            centered: true,
            okText: 'Yes',
            okType: 'primary',
            cancelText: 'Cancel',
            onOk() {
                handleLatestOnChange();
                modifyFileOnClick(data.id);
            }
        });
    };

    return (
    <div className={styles.release_note_page}>
        {/* <svg className={styles.release_note_close} viewBox="2 2 20 20"
            onClick={() => {back({isOnClick: "", index: ""});}} >
            <title>Back</title>
            <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
        </svg> */}
        <div className={styles.release_note_header_title_wrap}>
            <div className={styles.release_note_title_active}><span>Release Note</span></div>
            <div className={styles.release_note_title}><span>{selectModel.name}</span></div>
        </div>
        <div className={styles.release_header}>
            <div className={styles.release_header_item}>
                <span>Version</span>
            </div>
            <div className={styles.release_header_item}>
                <span>Created Time</span>
            </div>
            <div className={styles.release_header_item}>
                <span>Latest</span>
            </div>
            <div className={styles.release_header_item}>
                <span>Action</span> 
            </div>
        </div>
        <div className={styles.release_content}>
            <div className={styles.file_data}>
                <div className={styles.release_content_item}>
                    <span>{fileData.name}</span>
                </div>
                <div className={styles.release_content_item}>
                    <span>{fileData.created_at}</span>
                </div>
                <div className={styles.release_content_item}>
                    {fileData.is_latest ? <span className={styles.release_latest_status}>Latest</span> : ""}
                </div>
                <div className={styles.release_content_item}>
                    { isOnClick === "edit" ?
                        <div className={styles.release__action__btn__group}>
                            <div className={styles.release__cancel__btn}
                                onClick={(e) => {e.stopPropagation(); setFileData({...fileData, description: oldFileData.description}); setIsOnClick("")}}
                            >
                                <span>Cancel</span>
                            </div>
                            <div className={styles.release__save__btn}
                                onClick={(e) => {e.stopPropagation(); modifyFile(fileData.id);}}
                            >
                                <span>Save</span>
                            </div>
                        </div>
                        :
                        <div className={styles.release__action__btn__group}>
                            <div className={styles.release__latest__btn}
                                onClick={(e) => {e.stopPropagation(); showLatestConfirm(fileData);}}
                            >
                                <span>Set as Latest</span>
                            </div>
                            <div className={styles.release__edit__btn}
                                onClick={(e) => {e.stopPropagation(); handleModifyOnClick(); oldFileData.description = fileData.description}}
                            >
                                <span>Edit</span>
                            </div>
                        </div>
                    }
                </div>
                

                    {/* { isOnClick === "edit" ?
                    <>
                    <YesButton onClick={() => modifyFile(fileData.id)}/>
                    <NoButton onClick={() => {setFileData({...fileData, description: oldFileData.description}); setIsOnClick("")}}/>
                    {actionMsg}
                    </>
                    :
                    <ModifyButton onClick={() => {handleModifyOnClick(); oldFileData.description = fileData.description}}/>
                    } */}

            </div>
            <div className={styles.release_note_container}>
                { isOnClick === "edit" ? 
                <>
                <div className={styles.release_note_textarea} >
                    <textarea id="edit_release_note" defaultValue={fileData.description} onChange={e => {setFileData({...fileData, description: e.target.value})}} />
                </div>
                <div className={styles.release_note_preview}><span>Preview</span></div>
                <div style={{overflowY: 'auto', flex: '0.5'}}>
                    <Markdown >{fileData.description}</Markdown>
                </div>
                </>
                :
                <div style={{overflowY: 'auto'}}>
                    <Markdown >{fileData.description}</Markdown>
                </div>
                }
            </div>
        </div>
    </div>
    );
};

export default ReleaseNotePage;